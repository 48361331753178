import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { laptopSize, desktopSize, mobileSize } from '../../util/variables';
import { Wrapper, AnimatedLinkAnchor, AnimatedDiv } from '../ui';
import theme from './../../util/theme';
import advancementInitiativeImg from '../../../static/img/Advancement_Initiative_Logo_Black.png';

const Footer: React.FC = props => {
  return (
    <FooterContainer>
      <Wrapper>
        <GridDiv>
          <FooterContent>
            <AnimatedDiv delay="100">
              <FooterTitle>GET IN TOUCH</FooterTitle>
            </AnimatedDiv>
            <AnimatedDiv delay="300">
              <Footerh3>
                Let’s work together.{' '}
                <span style={{ display: 'inline-block' }}>
                  {' '}
                  <AnimatedLinkAnchor
                    to={!!props.email ? props.email : 'mailto:partners@base10.vc'}
                    fontSize="36px"
                    underlineHeight="5px"
                  >
                    Contact us.
                  </AnimatedLinkAnchor>{' '}
                </span>
              </Footerh3>
            </AnimatedDiv>
          </FooterContent>
          {!!props.email && (
            <div>
              <AIimg src={advancementInitiativeImg} />
            </div>
          )}
        </GridDiv>
      </Wrapper>
    </FooterContainer>
  );
};

const AIimg = styled.img`
  width: 480px;
  padding-right: 150px;
  @media ${mobileSize} {
    width: 50vw;
    padding-top: 150px;
    padding-right: 0px;
  }
`;

const GridDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${mobileSize} {
    flex-direction: column-reverse;
  }
`;

const FooterContainer = styled.div`
  background-color: ${theme.WHITE_COLOR};
  display: block;
  width: 100%;
`;

const FooterContent = styled.div`
  float: left;
  margin-left: 125px;
  padding-top: 180px;
  padding-bottom: 180px;
  @media ${mobileSize} {
    float: left;
    margin-left: 20px;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-right: 20px;
  }
`;

const FooterTitle = styled.h2`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 400;
  color: rgba(26, 26, 26, 0.6);
  text-transform: uppercase;
  @media ${mobileSize} {
    letter-spacing: 1px;
  }
`;

const Footerh3 = styled.h3`
  margin-top: 15px;
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 300;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: -0.8px;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    font-size: 30px;
    line-height: 33.75px;
    margin-top: 11px;
    letter-spacing: -0.75px;
    a {
      font-size: 30px;
      line-height: 33.75px;
      letter-spacing: -0.75px;
    }
  }
`;

export { Footer };
