import * as React from 'react';
import styled from 'styled-components';
import { AnimatedDiv } from '../../ui';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const OurTeamImg: React.FC = () => {
  const data = useStaticQuery(graphql`
    query TeamImageQuery {
      file(relativePath: { eq: "img/homepage/base10-investment-team.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <AnimatedDiv sal="slide-up">
      <OurTeamImageContainer>
        <Img fluid={data.file.childImageSharp.fluid} />
      </OurTeamImageContainer>
    </AnimatedDiv>
  );
};

const OurTeamImageContainer = styled.div`
  display: block;
  width: 100vw;
  height: 100%;
`;

export { OurTeamImg };
