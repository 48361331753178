import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from '../../../util/theme';
import mapImage from '../../../content/img/map/shopify-map.png';
import { MapTooltip } from '../../ui';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

interface MapProps {
  mapCompanyImages: any;
}

const StaticCompaniesMapSegment: React.FC<MapProps> = React.memo(({ mapCompanyImages }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "img/map/shopify-map.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  // console.log('map data: ', data);
  return (
    <MapContainer>
      <Img fluid={data.file.childImageSharp.fluid} style={{ opacity: 0.4, zIndex: 0 }} />
      {/* <MapImage src={mapImage} /> */}
      <CanadaToolTip>
        <MapTooltip numberText={3}>
          <WrapImages>
            {mapCompanyImages.canada.map(company => (
              <CompanyImage
                key={company}
                width="50px"
                src={require(`../../../content/img/companies/${company}.png`)}
              />
            ))}
          </WrapImages>
        </MapTooltip>
      </CanadaToolTip>
      <AmericaToolTip>
        <MapTooltip numberText={41}>
          <WrapImages>
            {mapCompanyImages.america.map(company => (
              <CompanyImage
                key={company}
                width="50px"
                src={require(`../../../content/img/companies/${company}.png`)}
              />
            ))}
          </WrapImages>
        </MapTooltip>
      </AmericaToolTip>
      <EUToolTip>
        <MapTooltip numberText={17}>
          <WrapImages>
            {mapCompanyImages.europe.map(company => (
              <CompanyImage
                key={company}
                width="50px"
                src={require(`../../../content/img/companies/${company}.png`)}
              />
            ))}
          </WrapImages>
        </MapTooltip>
      </EUToolTip>
    </MapContainer>
  );
});

const WrapImages = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
`;

const CompanyImage = styled.img`
  max-height: 16px;
  max-width: 50px;
`;

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
`;

const MapImage = styled.img`
  z-index: 0;
  opacity: 0.4;
`;

const CanadaToolTip = styled.div`
  position: absolute;
  min-height: 50px;
  min-width: 100px;
  background: white;
  z-index: 1;
  top: 5%;
  left: 17%;
  box-shadow: 0 3px 6px 0 rgba(69, 174, 235, 0.4);
  :hover {
    box-shadow: 0 3px 6px 2px rgba(69, 174, 235, 1);
    z-index: 4;
  }
`;

const AmericaToolTip = styled.div`
  position: absolute;
  min-height: 50px;
  min-width: 100px;
  background: white;
  z-index: 2;
  top: 45%;
  left: 17%;
  box-shadow: 0 3px 6px 0 rgba(69, 174, 235, 0.4);
  :hover {
    box-shadow: 0 3px 6px 2px rgba(69, 174, 235, 1);
    z-index: 4;
  }
`;
const EUToolTip = styled.div`
  position: absolute;
  min-height: 50px;
  min-width: 100px;
  background: white;
  z-index: 3;
  top: 35%;
  right: 20%;
  box-shadow: 0 3px 6px 0 rgba(69, 174, 235, 0.4);
  :hover {
    box-shadow: 0 3px 6px 2px rgba(69, 174, 235, 1);
    z-index: 4;
  }
`;

export { StaticCompaniesMapSegment };
