import * as React from 'react';
import { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { laptopSize, desktopSize, mobileSize } from '../../../util/variables';
import theme from '../../../util/theme';
import stubData from './stubData';
import { valuesIn } from 'lodash';
import { AnimatedLink, AnimatedLinkAnchor, AppText, AnimatedDiv } from '../../ui/';
import { prismicClient, Prismic, RichText } from '../../../services';
import moment from 'moment';

const OurPressSegment: React.FC = () => {
  const [articles, setArticles] = useState<any>(null);
  const [filterCategory, setFilterCategory] = useState('All');

  useEffect(() => {
    const fetchData = async () => {
      const response = await prismicClient.query(
        Prismic.Predicates.at('document.type', 'press-article'),
        { pageSize: 9999 },
      );
      console.log('prismic response: ', response);
      if (response && response.results.length) {
        setArticles(
          response.results.sort((a, b) => {
            const aDate = new Date(a.data.date);
            const bDate = new Date(b.data.date);
            return bDate.getTime() - aDate.getTime();
          }),
        );
      }
    };

    fetchData();
  }, []);

  const handleCategoryChange = (filterVal: string) => setFilterCategory(filterVal);

  const filteredPress = articles?.filter(item =>
    filterCategory === 'All' ? true : item.data.filter === filterCategory,
  );

  return (
    <>
      <OurPressContainer>
        <OurPressWrapper>
          <MainTextContainer>
            <AnimatedDiv delay="100">
              <OurPressh2>Content & Press</OurPressh2>
            </AnimatedDiv>
            <AnimatedDiv delay="300">
              <OurPressh1>
                We use research-driven insights to set our portfolio companies up for success
              </OurPressh1>
            </AnimatedDiv>
          </MainTextContainer>
          <AnimatedDiv delay="500">
            <FilterOptions>
              <FilterOption onClick={() => handleCategoryChange('All')}>
                <AnimatedLink to="/content/" fontSize="21px" underlineHeight="2px" color="white">
                  All
                </AnimatedLink>
              </FilterOption>
              <FilterOption onClick={() => handleCategoryChange('Insights')}>
                <AnimatedLink to="/content/" fontSize="21px" underlineHeight="2px" color="white">
                  Insights
                </AnimatedLink>
              </FilterOption>
              <FilterOption onClick={() => handleCategoryChange('Base10')}>
                <AnimatedLink to="/content/" fontSize="21px" underlineHeight="2px" color="white">
                  Base10
                </AnimatedLink>
              </FilterOption>
              <FilterOption onClick={() => handleCategoryChange('Portfolio Companies')}>
                <AnimatedLink to="/content/" fontSize="21px" underlineHeight="2px" color="white">
                  Portfolio Companies
                </AnimatedLink>
              </FilterOption>
              {/* <FilterOption onClick={() => handleCategoryChange('Forbes')}>
                <AnimatedLink to="/content/" fontSize="21px" underlineHeight="2px" color="white">
                  Forbes
                </AnimatedLink>
              </FilterOption> */}
            </FilterOptions>
          </AnimatedDiv>
        </OurPressWrapper>
      </OurPressContainer>
      <OurPressListWrapper>
        {filteredPress ? (
          filteredPress.map(articles => (
            <PressItem key={articles.data.id}>
              <a href={articles.data.url_link.url}>
                <Pressimg
                  src={articles.data.article_thumbnail.url}
                  alt={articles.data.article_thumbnail.alt}
                />
                <Pressh4>
                  {moment(articles.data.date).format('MMMM YYYY')} / {articles.data.source}
                </Pressh4>
                <Pressh3>{RichText.asText(articles.data.title)}</Pressh3>
                <Pressp>
                  {RichText.asText(articles.data.description)}
                  &nbsp;
                  <AnimatedLinkAnchor
                    fontSize="20px"
                    paddingBottom="1px"
                    to={articles.data.url_link.url}
                  >
                    Read More
                  </AnimatedLinkAnchor>
                </Pressp>
              </a>
            </PressItem>
          ))
        ) : (
          <Pressp>Loading content...</Pressp>
        )}
      </OurPressListWrapper>
    </>
  );
};

const Pressp = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  padding: 0;
  margin: 0;
  font-weight: 300;
  max-width: 358px;
  margin-top: 13px;
  min-width: 200px;
  line-height: 26px;
  font-size: 18px;
  a {
    text-decoration: none;
    color: ${theme.BLACK_COLOR};
    display: inline-block;
    font-weight: 300;
    line-height: 26px;
    font-size: 18px;
  }
  a:hover {
    text-decoration: none;
  }
  @media ${mobileSize} {
    max-width: 100%;
    min-width: 100%;
    font-size: 14px;
    line-height: 21px;
    margin-top: 3px;
    a {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

const Pressh3 = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  padding: 0;
  margin: 0;
  font-weight: 300;
  max-width: 426px;
  min-width: 200px;
  font-size: 28px;
  line-height: 36px;
  margin-top: 17px;
  @media ${mobileSize} {
    max-width: 100%;
    min-width: 100%;
    font-size: 24px;
    line-height: 34px;
    margin-top: 7px;
  }
`;

const Pressh4 = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  padding: 0;
  margin: 0;
  font-weight: 300;
  min-width: 200px;
  font-size: 18px;
  opacity: 0.4;
  line-height: 26px;
  margin-top: 24px;
  @media ${mobileSize} {
    max-width: 100%;
    min-width: 100%;
    font-size: 14px;
    line-height: 21px;
    margin-top: 14px;
  }
`;

const Pressimg = styled.img`
  width: 100%;
  padding: 0;
  margin: 0;
`;

const OurPressListWrapper = styled.div`
  background: transparent;
  padding: 30px;
  max-width: 1160px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: auto;
  grid-gap: 30px;
  @media ${mobileSize} {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
`;

const PressItem = styled.div`
  max-width: 550px;
  min-width: 200px;
  /* margin: 40px; */
  margin-bottom: 50px;
  font-family: ${theme.PRIMARY_FONT};
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    max-width: 90vw;
    min-width: 90vw;
  }
`;

const FilterOptions = styled.div`
  display: inline-block;
  font-family: ${theme.PRIMARY_FONT};
  background: ${theme.WHITE_COLOR};
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: ${theme.NEW_BLACK_COLOR};
  margin-bottom: 135px;
  margin-left: 20px;
  margin-right: 10px;
  @media ${mobileSize} {
    margin-bottom: 40px;
  }
`;

const FilterOption = styled.div`
  display: inline-block;
  padding-right: 30px;
  position: relative;
  overflow: visible;
  a {
    color: ${theme.NEW_BLACK_COLOR};
    text-decoration: none;
    font-size: 36px;
  }
  .active.active {
    &:before {
      font-family: ${theme.PRIMARY_FONT};
      content: '.';
      font-size: 30px;
      display: inline-block;
      position: absolute;
      bottom: -16px;
      left: 0;
      text-align: center;
      width: 100%;
      color: ${theme.PRIMARY_COLOR};
    }
  }
  @media ${mobileSize} {
    padding-top: 15px;
    padding-left: 10px;
    a {
      font-size: 18px;
    }
  }
`;

const SelectFilterList = styled.select`
  display: none;
  @media ${mobileSize} {
    height: 80px;
    font-family: ${theme.PRIMARY_FONT};
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    margin: auto auto;
    letter-spacing: normal;
    text-align-last: center;
    color: ${theme.WHITE_COLOR};
    display: block;
    width: 100%;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    option {
      color: ${theme.WHITE_COLOR};
      background: ${theme.BLACK_COLOR};
      margin: 20px;
    }
  }
`;

const OurPressContainer = styled.div`
  padding-top: 120px;
  background-color: ${theme.WHITE_COLOR};
  display: block;
  width: 100%;
  ::after {
    content: '';
    position: absolute;
    top: 550px;
    height: 135px;
    width: 100%;
    background-color: ${theme.WHITE_COLOR};
    z-index: -1;
  }
  @media ${mobileSize} {
    min-height: auto;
    ::after {
      display: none;
    }
  }
`;

const OurPressWrapper = styled.div`
  background-color: ${theme.WHITE_COLOR};
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 1000px;
  margin: auto;
`;

const MainTextContainer = styled.div`
  width: 100hw;
  margin: auto;
  padding: 25px;
  padding-top: 120px;
  @media ${mobileSize} {
    padding-top: 20px;
  }
`;

const OurPressh1 = styled.h1`
  color: ${theme.NEW_BLACK_COLOR};
  display: inline-block;
  font-family: ${theme.PRIMARY_FONT};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  font-size: 36px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  margin-top: 10px;
  margin-bottom: 10px;
  @media ${mobileSize} {
    font-size: 30px;
    line-height: 1.125;
    letter-spacing: -0.75px;
  }
`;

const OurPressh2 = styled.h2`
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  line-height: 1.16667;
  letter-spacing: 0.125rem;
  margin-top: 0.9375rem;

  width: fit-content;
  height: 14px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
`;

export { OurPressSegment };
