import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { TextCycler, AppTitle, AppText, Wrapper, AnimatedLinkAnchor, AnimatedDiv } from '../../ui';
import { Scrambler, Cycler } from 'react-text-scrambler';
import { Link } from 'gatsby';
import theme from '../../../util/theme';
import { mobileSize } from '../../../util/variables';
import img1 from '../../../content/img/homepage/bg-economy.jpg';
import img2 from '../../../content/img/homepage/bg-real-estate.jpg';
import img3 from '../../../content/img/homepage/bg-construction.jpg';
import img4 from '../../../content/img/homepage/bg-transportation.jpg';
import img5 from '../../../content/img/homepage/bg-agriculture.jpg';
import img6 from '../../../content/img/homepage/bg-all-real-economy.jpg';

const strings = [
  'the Real Economy',
  'Real Estate',
  'Construction',
  'Transportation',
  'Agriculture',
  'all of us',
];

// const images = [
//   'https://archive.base10.vc/wp-content/uploads/2018/08/bg-economy.jpg',
//   'https://archive.base10.vc/wp-content/uploads/2018/08/bg-real-estate.jpg',
//   'https://archive.base10.vc/wp-content/uploads/2018/08/bg-construction.jpg',
//   'https://archive.base10.vc/wp-content/uploads/2018/08/bg-transportation.jpg',
//   'https://archive.base10.vc/wp-content/uploads/2018/08/bg-agriculture.jpg',
//   'https://archive.base10.vc/wp-content/uploads/2018/08/bg-all-real-economy.jpg',
// ];

const IndexHeroSegment: React.FC = React.memo(() => {
  const [arrayIndex, setArrayIndex] = React.useState(0);

  React.useEffect(() => {
    let nextIndex = arrayIndex;
    if (nextIndex >= 5) {
      nextIndex = 0;
    } else {
      nextIndex++;
    }

    const interval = setInterval(() => setArrayIndex(nextIndex), 4000);
    return () => {
      clearInterval(interval);
    };
  }, [arrayIndex]);

  return (
    <HeroContainer>
      <HeroImgContainer number={0} arrayIndex={arrayIndex}>
        <HeroImg src={img1} />
      </HeroImgContainer>
      <HeroImgContainer number={1} arrayIndex={arrayIndex}>
        <HeroImg src={img2} />
      </HeroImgContainer>
      <HeroImgContainer number={2} arrayIndex={arrayIndex}>
        <HeroImg src={img3} />
      </HeroImgContainer>
      <HeroImgContainer number={3} arrayIndex={arrayIndex}>
        <HeroImg src={img4} />
      </HeroImgContainer>
      <HeroImgContainer number={4} arrayIndex={arrayIndex}>
        <HeroImg src={img5} />
      </HeroImgContainer>
      <HeroImgContainer number={5} arrayIndex={arrayIndex}>
        <HeroImg src={img6} />
      </HeroImgContainer>
      <HeroWrapper>
        <HeroWrapperInner>
          <Wrapper>
            <HeroContent>
              <AppTitle>
                <AnimatedDiv delay="100">
                  <LargeHeading>
                    Automation for
                    <LargeHeading style={{ color: theme.PRIMARY_COLOR }}>
                      <Scrambler
                        renderIn={1500}
                        changeFrom={arrayIndex === 0 ? strings[5] : strings[arrayIndex - 1]}
                        text={strings[arrayIndex]}
                      />
                    </LargeHeading>
                  </LargeHeading>
                </AnimatedDiv>
                {/* <TextCycler strings={strings} /> */}
              </AppTitle>
              <AnimatedDiv delay="300">
                <Styledh2>Bringing together human & artificial intelligence.</Styledh2>
              </AnimatedDiv>
              <HeroLink>
                <AnimatedDiv delay="500">
                  <AnimatedLinkAnchor
                    to="/#our-mission"
                    color="#ffffff"
                    fontSize="20px"
                    underlineHeight="2px"
                  >
                    Learn More
                  </AnimatedLinkAnchor>
                </AnimatedDiv>
              </HeroLink>
            </HeroContent>
          </Wrapper>
        </HeroWrapperInner>
      </HeroWrapper>
    </HeroContainer>
  );
});

const HeroImg = styled.img`
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  height: auto;
  object-fit: cover;
`;

interface IndexProp {
  arrayIndex: number;
  number: number;
}

const HeroImgContainer = styled.div<IndexProp>`
  z-index: -2;
  opacity: ${props => (props.arrayIndex === props.number ? 0.5 : 0)};
  -webkit-transition: all 1500ms ease-in-out;
  -moz-transition: all 1500ms ease-in-out;
  -o-transition: all 1500ms ease-in-out;
  -ms-transition: all 1500ms ease-in-out;
  transition: all 1500ms ease-in-out;
`;

const SlideDiv = styled.div``;

const LargeHeading = styled(AppTitle)`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 700;
  font-size: 80px;
  letter-spacing: -2px;
  line-height: 80px;
  @media ${mobileSize} {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -1px;
  }
`;

const HeroContainer = styled.div`
  /* background: ${props => `url(${localImages[props.arrayIndex]}) no-repeat bottom center`}; */
  background-color: rgb(0, 0, 0);
  background-size: cover;
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100%;
  -webkit-transition: all 1500ms ease-in-out;
  -moz-transition: all 1500ms ease-in-out;
  -o-transition: all 1500ms ease-in-out;
  -ms-transition: all 1500ms ease-in-out;
  transition: all 1500ms ease-in-out;
`;

const HeroWrapper = styled.div`
  display: table;
  height: 100%;
  width: 100%;
`;

const HeroWrapperInner = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 100%;
`;

const Styledh2 = styled.h2`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 300;
  font-size: 28px;
  line-height: 1.28571;
  letter-spacing: 0;
  margin-top: 42px;
  color: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    font-size: 24px;
  }
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  width: calc(83.33333% - 3.4375rem);
  float: left;
  margin-left: 1.875rem;
  padding-top: 11.25rem;
  padding-bottom: 11.25rem;
`;

const HeroLink = styled.div`
  margin-top: 2.6875rem;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0;
  position: relative;
`;

export { IndexHeroSegment };
