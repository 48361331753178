import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { laptopSize, desktopSize, mobileSize, smallMobileSize } from '../../../util/variables';
import theme from '../../../util/theme';
import stubData from './stubData';
import { valuesIn } from 'lodash';
import { AnimatedLinkAnchor, AppText, AnimatedDiv } from '../../ui/';
import { useState } from 'react';
import { OurInsightsList } from './OurInsightsList';

const OurInsightsSegment: React.FC = () => {
  const [filterOption, setFilterOption] = useState('All');
  return (
    <>
      <OurInsightsContainer>
        <OurInsightsWrapper>
          <MainTextContainer>
            <AnimatedDiv delay="100">
              <OurInsightsh2>Our Insights</OurInsightsh2>
            </AnimatedDiv>
            <AnimatedDiv delay="300">
              <OurInsightsh1>Our thoughts on Automation for the Real Economy</OurInsightsh1>
            </AnimatedDiv>
            <AnimatedDiv delay="500">
              <OurInsightsp>
                We work with our partners to research each sector of the economy and try to
                understand how automation is affecting it. We believe in transparency and will make
                all our research available here.
              </OurInsightsp>
            </AnimatedDiv>
          </MainTextContainer>
          {/* <AnimatedDiv delay="700">
            <FilterOptions>
              <FilterLabel>Filter</FilterLabel>
              <FilterOption>
                <TextLink
                  state={{ filterOption: 'All' }}
                  className={filterOption === 'All' ? 'active' : ''}
                  onClick={() => setFilterOption('All')}
                >
                  All
                </TextLink>
              </FilterOption>
              <FilterOption>
                <TextLink
                  state={{ filterOption: 'Articles' }}
                  className={filterOption === 'Articles' ? 'active' : ''}
                  onClick={() => setFilterOption('Articles')}
                >
                  Articles
                </TextLink>
              </FilterOption>
              <FilterOption>
                <TextLink
                  state={{ filterOption: 'Prepared Minds' }}
                  className={filterOption === 'Prepared Minds' ? 'active' : ''}
                  onClick={() => setFilterOption('Prepared Minds')}
                >
                  Prepared Minds
                </TextLink>
              </FilterOption>
              <FilterOption>
                <Link
                  to="/content/"
                  state={{ filterOption: 'Press' }}
                  className={filterOption === 'Press' ? 'active' : ''}
                  onClick={() => setFilterOption('Press')}
                >
                  Press
                </Link>
              </FilterOption>
              <SelectFilterList
                name="insights"
                id=""
                onChange={event => setFilterOption(event.target.value)}
              >
                <option value="All">All Insights</option>
                <option value="Articles">Articles</option>
                <option value="Prepared Minds">Prepared Minds</option>
              </SelectFilterList>
            </FilterOptions>
          </AnimatedDiv> */}
        </OurInsightsWrapper>
        <BlackColorOverlap />
      </OurInsightsContainer>
      <OurInsightsListWrapper>
        <OurInsightsList filterOption={filterOption} />
      </OurInsightsListWrapper>
    </>
  );
};

const OurInsightsp = styled(AppText)`
  color: ${theme.NEW_BLACK_COLOR};
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: 300;
  line-height: 32px;
  opacity: 0.7;
  @media ${mobileSize} {
    margin-top: 25px;
    font-size: 18px;
    line-height: 24px;
  }
`;

const BlackColorOverlap = styled.div`
  content: '';
  min-height: 215px;
  width: 100hw;
  z-index: -5;
  background: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    display: none;
  }
`;

const SelectFilterList = styled.select`
  display: none;
  @media ${mobileSize} {
    height: 80px;
    font-family: ${theme.PRIMARY_FONT};
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    margin: auto auto;
    letter-spacing: normal;
    text-align-last: center;
    color: ${theme.WHITE_COLOR};
    display: block;
    width: calc(100vw - 50px);
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    option {
      color: ${theme.WHITE_COLOR};
      background: ${theme.BLACK_COLOR};
      margin: 20px;
    }
  }
`;

const OurInsightsListWrapper = styled.div`
  background: transparent;
  padding: 30px;
  max-width: 1300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  grid-gap: 30px;
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
  }
`;

const TextLink = styled(AppText)`
  color: ${theme.NEW_BLACK_COLOR};
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
`;

const FilterOption = styled.div`
  display: inline-block;
  margin: 15px;
  position: relative;
  overflow: visible;
  a {
    color: ${theme.NEW_BLACK_COLOR};
    text-decoration: none;
  }
  .active.active {
    &:before {
      font-family: ${theme.PRIMARY_FONT};
      content: '.';
      font-size: 30px;
      display: inline-block;
      position: absolute;
      bottom: -16px;
      left: 0;
      text-align: center;
      width: 100%;
      color: ${theme.PRIMARY_COLOR};
    }
  }
  @media ${mobileSize} {
    padding-top: 15px;
    display: none;
  }
`;

const FilterLabel = styled.div`
  display: inline-block;
  opacity: 0.7;
  margin-top: 100px;
  padding-right: 15px;
  @media ${mobileSize} {
    margin-top: 50px;
    margin-bottom: 10px;
  }
`;

const FilterOptions = styled.div`
  display: inline-block;
  font-family: ${theme.PRIMARY_FONT};
  background: ${theme.WHITE_COLOR};
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: ${theme.NEW_BLACK_COLOR};
`;

const OurInsightsContainer = styled.div`
  padding: 0;
  padding-top: 120px;
  margin: 0;
  background-color: ${theme.WHITE_COLOR};
  height: 550px;
  width: 100%;
  @media ${mobileSize} {
    height: auto;
  }
`;

const OurInsightsWrapper = styled.div`
  background-color: ${theme.WHITE_COLOR};
  max-width: 950px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding: 25px;
  margin: auto;
`;

const MainTextContainer = styled.div`
  width: 100%;
  margin: auto;
`;

const OurInsightsh1 = styled.h1`
  color: ${theme.NEW_BLACK_COLOR};
  font-family: ${theme.PRIMARY_FONT};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 36px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  margin-bottom: 40px;
  @media ${mobileSize} {
    font-size: 30px;
    letter-spacing: -0.75px;
    line-height: 33.75px;
    margin-bottom: 30px;
  }
`;

const OurInsightsh2 = styled.h2`
  font-family: ${theme.SECONDARY_FONT};
  text-transform: uppercase;
  opacity: 0.6;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: ${theme.NEW_BLACK_COLOR};
  margin-top: 100px;
  @media ${mobileSize} {
    letter-spacing: 1px;
  }
`;

export { OurInsightsSegment };
