interface IInsightsData {
  id: string;
  image: string;
  title: string;
  description: string;
  type: string;
  date: string;
  button: string;
  link: string;
}

const stubData: IInsightsData[] = [
  {
    id: '12-64672932',
    image:
      'https://res.cloudinary.com/base10/image/upload/v1627315126/general-research/https___bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com_public_images_0218d2b0-8a21-42ef-8769-bc25c5bdf5fc_1600x842_yem7vk.png',
    title: 'Summary of Base10 trend: Social Governance',
    description:
      'Interactive summary of Base10’s key insights and the companies identified from our research into Social Governance.',
    type: 'Research Summary',
    date: 'April 2021',
    button: 'Read More',
    link: 'https://base10.vc/social-governance/',
  },
  {
    id: '11-843725079',
    image:
      'https://res.cloudinary.com/base10/image/upload/v1627315126/general-research/https___bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com_public_images_0218d2b0-8a21-42ef-8769-bc25c5bdf5fc_1600x842_yem7vk.png',
    title:
      'Giving everyone a voice: How technology is helping stakeholders better manage social and governance issues',
    description:
      'Rexhi Dollaku discusses our research and insights into the social and governance technology trend.',
    type: 'Articles',
    date: 'April 2021',
    button: 'Read More',
    link: 'https://base10.substack.com/p/giving-everyone-a-voice',
  },
  {
    id: '10-3284823',
    image:
      'https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_700/v1616089332/https___bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com_public_images_5eeb14b3-15a4-4979-a2a8-1d774b8a8bd3_2821x1537_nh1inc.png',
    title: 'Summary of Base10 trend: Environment',
    description:
      'Interactive summary of Base10’s key insights and the companies identified from our research into the Environment.',
    type: 'Research Summary',
    date: 'March 2021',
    button: 'Read More',
    link: 'https://base10.vc/environment/',
  },
  {
    id: '9-8392833',
    image:
      'https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_700/v1616089332/https___bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com_public_images_5eeb14b3-15a4-4979-a2a8-1d774b8a8bd3_2821x1537_nh1inc.png',
    title: 'This Time Is Different: How Automation Can Win the Fight Against Climate Change',
    description:
      'Entrepreneurs and investors who are actively building tools to help companies better manage ESG programs will drive enduring, climate positive change.',
    type: 'Articles',
    date: 'March 2021',
    button: 'Read More',
    link: 'https://base10.substack.com/p/this-time-is-different',
  },
  {
    id: '8-7867654',
    image:
      'https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_700/v1612498024/mental-health_vsnjsl.jpg',
    title: 'Summary of Base10 trend: Mental Health Tools for the Healthcare Industry',
    description:
      'Interactive summary of Base10’s key insights and the companies identified from our research into Mental Health Tools for the Healthcare Industry.',
    type: 'Research Summary',
    date: 'February 2021',
    button: 'Read More',
    link: 'https://base10.vc/mental-health/',
  },
  {
    id: '7-4064354',
    image:
      'https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_700/v1612498024/mental-health_vsnjsl.jpg',
    title: 'Accessible, Affordable, Acceptable: How COVID-19 Has Shaped Mental Health Care',
    description:
      'COVID-19 has opened up untapped care channels for a variety of Americans seeking mental health treatment.',
    type: 'Articles',
    date: 'February 2021',
    button: 'Read More',
    link: 'https://base10.substack.com/p/how-covid-has-shaped-mental-health',
  },
  {
    id: '6-1627234',
    image:
      'https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_700/v1597426545/insight-shopify_dqmb7r.jpg',
    title: 'Summary of Base10 trend: Commerce Enablement Tools for the Retail Industry',
    description:
      'Interactive summary of Base10’s key insights and the companies identified from our research into Commerce Enablement Tools for the Retail Industry.',
    type: 'Research Summary',
    date: 'August 2020',
    button: 'Read More',
    link: 'https://research.base10.vc/ecommerce-shopify-saas/',
  },
  {
    id: '5-198273987',
    image:
      'https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_700/v1597426545/insight-shopify_dqmb7r.jpg',
    title: 'Faster Than Fast: SMB Retailers Move to Shopify',
    description:
      'Shopify’s third party app ecosystem enables retailers to move from offline to online, and many of these apps are growing faster than the platform itself.',
    type: 'Articles',
    date: 'August 2020',
    button: 'Read More',
    link: 'https://base10.substack.com/p/faster-than-fast-smb-retailers-move',
  },
  {
    id: '1-23974432',
    image:
      'https://res.cloudinary.com/base10/image/upload/v1597426545/insight-last-mile_uj2yzf.png',
    title: 'Last-Mile Transportation – Interactive article about e-scooters and micro-mobility',
    description:
      'Everything you ever wanted to know about electric scooters and micro-mobility solutions. The data indicates that society needs a viable last-mile alternative to the current transportation options that are congesting and polluting the world’s most populous cities.',
    type: 'Articles',
    date: 'February 2019',
    button: 'Read More',
    link: 'https://archive.base10.vc/insights/last-mile-transportation/',
  },
  {
    id: '2-6548351',
    image:
      'https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_700/v1597426545/insight-cover-introducing-base10_ybbtno.png',
    title: 'Base10: Automation for the Real Economy',
    description:
      "We believe that humans come first. We believe that automation is here, is real and can be a force for good. We believe that more than $4 trillion of the economy will be automated over the next decade, and for that to go well, we must support founders whose work encourages people to embrace change rather than fight against it. Let's face it: No one wants ...",
    type: 'Articles',
    date: 'September 2018',
    button: 'Read More',
    link: 'https://archive.base10.vc/insight/base10-automation-for-the-real-economy/',
  },
  {
    id: '3-4816351',
    image:
      'https://res.cloudinary.com/base10/image/upload/c_scale,w_700/v1597426545/insight-bg-construction_vuewrk.jpg',
    title: 'Construction Technology',
    description:
      'We believe the construction industry is in the early stages of widespread technological disruption. This report is a selective summary of what we have learned and includes highlights from several high impact conversations (out of dozens) Base10 has held with leading AI minds, founders with industry expertise, executives, and industry analysts.',
    type: 'Prepared Minds',
    date: 'July 2018',
    button: 'Download',
    link:
      'https://archive.base10.vc/wp-content/uploads/2018/09/Base10_Prepared-Mind-Construction-Technology_vF.pdf',
  },
  {
    id: '4-616548961',
    image:
      'https://res.cloudinary.com/base10/image/upload/c_scale,w_700/v1597426545/insight-bg-transportation_ma0jvk.jpg',
    title: 'Shipping and Logistics',
    description:
      'We believe the shipping and logistics industry is in the early stages of widespread automation. Base10’s Prepared Mind is the result of many months of work meeting with entrepreneurs, executives, and analysts in the industry and seeing firsthand how leading competitors operate.',
    type: 'Prepared Minds',
    date: 'April 2018',
    button: 'Download',
    link:
      'https://archive.base10.vc/wp-content/uploads/2019/01/Prepared-Mind-Shipping-Logistics-vF.pdf',
  },
];

export default stubData;
