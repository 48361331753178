import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import theme from '../../../util/theme';
import mapImage from '../../../content/img/map/shopify-map.png';
import { MapTooltip, AppText } from '../../ui';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { mobileSize } from '../../../util/variables';
import { useImageList } from '../../../content/img/research/imagesList';

interface MapProps {
  mapCompanyImages: any;
}

const FourPlacesMapComponent: React.FC<MapProps> = React.memo(({ mapCompanyImages }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "img/map/shopify-map.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  // console.log('map data: ', data);

  return (
    <MapContainer>
      <Img fluid={data.file.childImageSharp.fluid} style={{ opacity: 0.4, zIndex: 0 }} />
      {Boolean(mapCompanyImages.latam.length) && (
        <SAToolTip>
          <MapTooltip numberText={mapCompanyImages.latam.length}>
            <WrapImages>
              {mapCompanyImages.latam
                .filter(row => useImageList.includes(row))
                .slice(0, 3)
                .map(company => (
                  <CompanyImage
                    key={company}
                    width="50px"
                    src={require(`../../../content/img/research/logos/${company}.jpg`)}
                  />
                ))}
            </WrapImages>
          </MapTooltip>
        </SAToolTip>
      )}
      {Boolean(mapCompanyImages.uscan.length) && (
        <AmericaToolTip>
          <MapTooltip numberText={mapCompanyImages.uscan.length}>
            <WrapImages>
              {mapCompanyImages.uscan
                .filter(row => useImageList.includes(row))
                .slice(0, 3)
                .map(company => (
                  <CompanyImage
                    key={company}
                    width="60px"
                    // height={10}
                    src={require(`../../../content/img/research/logos/${company.replace(
                      '%',
                      '',
                    )}.jpg`)}
                  />
                ))}
            </WrapImages>
          </MapTooltip>
        </AmericaToolTip>
      )}
      {Boolean(mapCompanyImages.emea.length) && (
        <EUToolTip>
          <MapTooltip numberText={mapCompanyImages.emea.length}>
            <WrapImages>
              {mapCompanyImages.emea
                .filter(row => useImageList.includes(row))
                .slice(0, 3)
                .map(company => (
                  <CompanyImage
                    key={company}
                    width="50px"
                    src={require(`../../../content/img/research/logos/${company}.jpg`)}
                  />
                ))}
            </WrapImages>
          </MapTooltip>
        </EUToolTip>
      )}
      {Boolean(mapCompanyImages.apac.length) && (
        <AsiaToolTip>
          <MapTooltip arrowRight numberText={mapCompanyImages.apac.length}>
            <WrapImages>
              {mapCompanyImages.apac
                .filter(row => useImageList.includes(row))
                .slice(0, 3)
                .map(company => (
                  <CompanyImage
                    key={company}
                    width="50px"
                    src={require(`../../../content/img/research/logos/${company}.jpg`)}
                  />
                ))}
            </WrapImages>
          </MapTooltip>
        </AsiaToolTip>
      )}
    </MapContainer>
  );
});

const AltText = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  font-size: 8px;
  line-height: 1;
`;

const WrapImages = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
`;

const CompanyImage = styled.img`
  /* max-height: 35px; */
  /* max-width: 50px; */
`;

const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
`;

const MapImage = styled.img`
  z-index: 0;
  opacity: 0.4;
`;

const SAToolTip = styled.div`
  position: absolute;
  min-height: 50px;
  min-width: 100px;
  background: white;
  z-index: 1;
  bottom: 5%;
  left: 30%;
  box-shadow: 0 3px 6px 0 rgba(69, 174, 235, 0.4);
  :hover {
    box-shadow: 0 3px 6px 2px rgba(69, 174, 235, 1);
    z-index: 4;
  }
`;

const AmericaToolTip = styled.div`
  position: absolute;
  min-height: 50px;
  min-width: 100px;
  background: white;
  z-index: 2;
  top: 45%;
  left: 17%;
  box-shadow: 0 3px 6px 0 rgba(69, 174, 235, 0.4);
  :hover {
    box-shadow: 0 3px 6px 2px rgba(69, 174, 235, 1);
    z-index: 4;
  }
  @media ${mobileSize} {
    top: 5%;
  }
`;
const EUToolTip = styled.div`
  position: absolute;
  min-height: 50px;
  min-width: 100px;
  background: white;
  z-index: 3;
  top: 35%;
  right: 20%;
  box-shadow: 0 3px 6px 0 rgba(69, 174, 235, 0.4);
  :hover {
    box-shadow: 0 3px 6px 2px rgba(69, 174, 235, 1);
    z-index: 4;
  }
  @media ${mobileSize} {
    top: 5%;
  }
`;

const AsiaToolTip = styled.div`
  position: absolute;
  min-height: 50px;
  min-width: 100px;
  background: white;
  z-index: 3;
  bottom: 5%;
  right: 5%;
  box-shadow: 0 3px 6px 0 rgba(69, 174, 235, 0.4);
  :hover {
    box-shadow: 0 3px 6px 2px rgba(69, 174, 235, 1);
    z-index: 4;
  }
`;

export { FourPlacesMapComponent };
