import * as React from 'react';
import styled from 'styled-components';
import { AppTitle, AppText, AnimatedDiv } from '../../ui';
import { Link } from 'gatsby';
import theme from '../../../util/theme';
import { mobileSize, desktopSize } from '../../../util/variables';
import finance1 from '../../../content/img/homepage/NU.png';
import finance2 from '../../../content/img/homepage/Plaid.png';
import finance3 from '../../../content/img/homepage/Brex.png';
import operations1 from '../../../content/img/homepage/Figma.png';
import operations2 from '../../../content/img/homepage/Circle.png';
import operations3 from '../../../content/img/homepage/Notion.png';
import retail1 from '../../../content/img/homepage/attentive.png';
import retail2 from '../../../content/img/homepage/chili-piper.jpeg';
import retail3 from '../../../content/img/homepage/shelf.png';
import food1 from '../../../content/img/homepage/popmenu2.png';
import food2 from '../../../content/img/homepage/instacartnew.png';
import food3 from '../../../content/img/homepage/AllDay.jpg';

const NewSplashScreen: React.FC = React.memo(() => {
  const [arrayIndex, setArrayIndex] = React.useState(0);

  React.useEffect(() => {
    let nextIndex = arrayIndex;
    if (nextIndex >= 5) {
      nextIndex = 0;
    } else {
      nextIndex++;
    }

    const interval = setInterval(() => setArrayIndex(nextIndex), 4000);
    return () => {
      clearInterval(interval);
    };
  }, [arrayIndex]);

  return (
    <HeroContainer>
      <Wrapper>
        <FlexBetweenDiv>
          <LeftDiv>
            <AppTitle>
              <AnimatedDiv delay="100">
                <LargeHeading>
                  Investing in <span style={{ color: theme.PRIMARY_COLOR }}>Automation</span> for
                  the <span style={{ color: theme.PRIMARY_COLOR }}>Real Economy</span>
                </LargeHeading>
              </AnimatedDiv>
              {/* <TextCycler strings={strings} /> */}
            </AppTitle>
            <AnimatedDiv delay="200">
              <Styledh2>
                We are a technology investment firm investing in Founders who solve&nbsp;
                <span style={{ color: theme.PRIMARY_COLOR }}>problems for the 99%</span>.
              </Styledh2>
            </AnimatedDiv>
            <AnimatedDiv delay="300">
              <Styledh2>
                We donate 50% of profits from our largest investments to create{' '}
                <span style={{ color: theme.PRIMARY_COLOR }}>
                  scholarships at underfunded colleges
                </span>{' '}
                for the next generation of technology founders.
              </Styledh2>
            </AnimatedDiv>
          </LeftDiv>
          <RightDiv>
            <AnimatedDiv delay="400">
              <CompanyCategory>Finance</CompanyCategory>
              <ImagesContainer>
                <LinkToCompany to="/companies/?focus=nubank">
                  <CompanyImg src={finance1} alt="NuBank" />
                </LinkToCompany>

                <LinkToCompany to="/companies/?focus=plaid">
                  <CompanyImg src={finance2} alt="Plaid" />
                </LinkToCompany>

                <LinkToCompany to="/companies/?focus=brex">
                  <CompanyImg style={{ marginRight: '0px' }} src={finance3} alt="Brex" />
                </LinkToCompany>
              </ImagesContainer>
            </AnimatedDiv>
            <AnimatedDiv delay="500">
              <CompanyCategory>Food</CompanyCategory>
              <ImagesContainer>
                <LinkToCompany to="/companies/?focus=popmenu">
                  <CompanyImg
                    style={{ height: '46px', marginRight: '15px' }}
                    src={food1}
                    alt="Popmenu"
                  />
                </LinkToCompany>

                <LinkToCompany to="/companies/?focus=instacart">
                  <CompanyImg
                    style={{ height: '40px', marginRight: '5px' }}
                    src={food2}
                    alt="Instacart"
                  />
                </LinkToCompany>
                <LinkToCompany to="/companies/?focus=alldaykitchens">
                  <CompanyImg
                    style={{ height: '40px', marginRight: '0px' }}
                    src={food3}
                    alt="All Day Kitchens"
                  />
                </LinkToCompany>
              </ImagesContainer>
            </AnimatedDiv>
            <AnimatedDiv delay="600">
              <CompanyCategory>Retail</CompanyCategory>
              <ImagesContainer>
                <LinkToCompany to="/companies/?focus=attentive">
                  <CompanyImg style={{ height: 22 }} src={retail1} alt="Attentive" />
                </LinkToCompany>
                <LinkToCompany to="/companies/?focus=chilipiper">
                  <CompanyImg src={retail2} alt="Chili Piper" />
                </LinkToCompany>
                <LinkToCompany to="/companies/?focus=shelf">
                  <CompanyImg src={retail3} alt="Shelf" />
                </LinkToCompany>
              </ImagesContainer>
            </AnimatedDiv>
            <AnimatedDiv delay="700">
              <CompanyCategory>Operations</CompanyCategory>
              <ImagesContainer>
                <LinkToCompany to="/companies/?focus=figma">
                  <CompanyImg src={operations1} alt="Figma" />
                </LinkToCompany>
                <LinkToCompany to="/companies/?focus=notion">
                  <CompanyImg src={operations3} alt="Notion" />
                </LinkToCompany>
                <LinkToCompany to="/companies/?focus=circleci">
                  <CompanyImg
                    style={{ maxWidth: '120px', height: '26px', marginTop: '5px' }}
                    src={operations2}
                    alt="Circle CI"
                  />
                </LinkToCompany>
              </ImagesContainer>
            </AnimatedDiv>
          </RightDiv>
        </FlexBetweenDiv>
      </Wrapper>
    </HeroContainer>
  );
});

const LinkToCompany = styled(Link)``;

const Wrapper = styled.div`
  max-width: 1215px;
  margin-right: auto;
  margin-left: auto;
  @media ${mobileSize} {
    margin: 0px;
  }
`;

const CompanyImg = styled.img`
  height: 36px;
  cursor: pointer;
  margin-right: 24px;
  margin-bottom: 10px;
  @media ${mobileSize} {
    height: 32px;
    filter: none;
    margin-bottom: 20px;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 14px;
  @media ${mobileSize} {
    flex-wrap: wrap;
    height: auto;
    filter: none;
    margin-bottom: 20px;
  }
`;

const FlexBetweenDiv = styled.div`
  display: grid;
  width: 100%;
  max-width: calc(100vw - 100px);
  grid-template-columns: 588fr 386fr;
  grid-gap: 126px;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 50px;
  @media ${desktopSize} {
    grid-gap: 55px;
  }
  @media ${mobileSize} {
    max-width: calc(100vw);
    margin-top: 109px;
    padding: 0px 24px;
    display: flex;
    flex-direction: column;
    grid-gap: 55px;
  }
`;

const CompanyCategory = styled(AppText)`
  background: ${theme.GREY_300};
  border-radius: 6.2px;
  padding: 3px 8px;
  color: ${theme.GREY_800};
  margin: 0px;
  margin-bottom: 17px;
  width: fit-content;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.155571px;
`;

const LeftDiv = styled.div`
  width: 100%;
  max-width: 588px;
  @media ${mobileSize} {
    max-width: 100%;
  }
`;

const RightDiv = styled.div`
  width: 100%;
  max-width: 387px;
  @media ${mobileSize} {
    max-width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

interface IndexProp {
  arrayIndex: number;
  number: number;
}

const LargeHeading = styled(AppTitle)`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 700;
  font-size: 54px;
  letter-spacing: -2px;
  line-height: 70px;
  color: ${theme.NEW_BLACK_COLOR};
  margin-bottom: 48px;
  @media ${mobileSize} {
    font-size: 44px;
    line-height: 44px;
    letter-spacing: 0.2px;
  }
`;

const HeroContainer = styled.div`
  background-color: ${theme.WHITE_COLOR};
  background-size: cover;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  -webkit-transition: all 1500ms ease-in-out;
  -moz-transition: all 1500ms ease-in-out;
  -o-transition: all 1500ms ease-in-out;
  -ms-transition: all 1500ms ease-in-out;
  transition: all 1500ms ease-in-out;
`;

const Styledh2 = styled.h2`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  margin: 0px;
  margin-bottom: 16px;
  color: ${theme.NEW_BLACK_COLOR};
  @media ${mobileSize} {
    font-size: 16px;
    line-height: 23px;
  }
`;

export { NewSplashScreen };
