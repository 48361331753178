import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import Slider from 'react-slick';
import { Link } from 'gatsby';
import {
  laptopSize,
  desktopSize,
  mobileSize,
  salDuration,
  salTimingFunction,
} from '../../../util/variables';
import theme from '../../../util/theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { AnimatedLink, AnimatedDiv } from '../../ui';

const IndexCompanySegment: React.FC = () => {
  const [arrayIndex, setArrayIndex] = React.useState(3);

  React.useEffect(() => {
    let nextIndex = arrayIndex;
    if (nextIndex >= 8) {
      nextIndex = 0;
    } else {
      nextIndex++;
    }

    const interval = setInterval(() => setArrayIndex(nextIndex), 2500);
    return () => {
      clearInterval(interval);
    };
  }, [arrayIndex]);

  const settings = {
    dots: false,
    vertical: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2500,
    autoplaySpeed: 0,
    cssEase: 'linear',
  };
  return (
    <OurCompaniesContainer>
      <SlideShowContainer>
        <Slider {...settings}>
          <ImageContainer arrayIndex={arrayIndex} imageIndex={0}>
            <MovingImage src="https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_200/v1597698439/image1_fgibmf.png" />
          </ImageContainer>
          <ImageContainer arrayIndex={arrayIndex} imageIndex={1}>
            <MovingImage src="https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_200/v1597698439/image2_hfoavt.png" />
          </ImageContainer>
          <ImageContainer arrayIndex={arrayIndex} imageIndex={2}>
            <MovingImage src="https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_200/v1597698439/image3_rxfmqs.png" />
          </ImageContainer>
          <ImageContainer arrayIndex={arrayIndex} imageIndex={3}>
            <MovingImage src="https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_200/v1597698439/image4_ikigbh.png" />
          </ImageContainer>
          <ImageContainer arrayIndex={arrayIndex} imageIndex={4}>
            <MovingImage src="https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_200/v1597698439/image5_p5lpex.png" />
          </ImageContainer>
          <ImageContainer arrayIndex={arrayIndex} imageIndex={5}>
            <MovingImage src="https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_200/v1597698439/image6_faks4g.png" />
          </ImageContainer>
          <ImageContainer arrayIndex={arrayIndex} imageIndex={6}>
            <MovingImage src="https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_200/v1597698439/image7_jrwlms.png" />
          </ImageContainer>
          <ImageContainer arrayIndex={arrayIndex} imageIndex={7}>
            <MovingImage src="https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_200/v1597698439/image8_aevg4n.png" />
          </ImageContainer>
          <ImageContainer arrayIndex={arrayIndex} imageIndex={8}>
            <MovingImage src="https://res.cloudinary.com/base10/image/upload/c_scale,q_100,w_200/v1597698439/image9_rlr6lo.png" />
          </ImageContainer>
        </Slider>
      </SlideShowContainer>
      <OurCompaniesInfoContainer>
        <AnimatedDiv delay="100">
          <OurCompaniesh2>OUR COMPANIES</OurCompaniesh2>
        </AnimatedDiv>
        <AnimatedDiv delay="300">
          <OurCompaniesh3>Improving every industry.</OurCompaniesh3>
        </AnimatedDiv>
        <AnimatedDiv delay="500">
          <OurCompaniesDescription>
            Meet the entrepreneurs and their companies that are automating $4 trillion of the
            economy over the next decade!
          </OurCompaniesDescription>
        </AnimatedDiv>
        <AnimatedDiv delay="700">
          <CompanyLink>
            <AnimatedLink fontSize="20px" underlineHeight="2px" to="/companies/">
              Discover our Companies
            </AnimatedLink>
          </CompanyLink>
        </AnimatedDiv>
      </OurCompaniesInfoContainer>
    </OurCompaniesContainer>
  );
};

const OurCompaniesContainer = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  @media ${laptopSize} {
    display: block;
  }
`;

const slider = keyframes`
    100% {transform: translateY(-520px) };  
`;

const opacityAnimation = keyframes`
    0% {opacity: 0.5}
    33% {opacity: 0.9}
    50% {opacity: 1}
    66% {opacity: 0.9}
    100% {opacity: 0.5}
`;

// temp slideshow css
const SliderList = styled.ul`
  top: 1em;
  position: relative;
  box-sizing: border-box;
  animation: ${slider} 5s linear infinite;
  list-style-type: none;
  text-align: center;
  color: white;
`;

const SlideShowContainer = styled.div`
  width: 50vw;
  height: 50vw;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: ${theme.BLACK_COLOR};
  pointer-events: none;
  /* -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6)),
    linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6));
  -webkit-mask-size: 100% 50%;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: left top, left bottom;
  .slick-active {
    scale: 0.5;
  } */
  @media ${laptopSize} {
    width: 100%;
    height: 250px;
  }
`;

const slideshowMove = keyframes`
    100% {transform: translateY(-550px) };  
`;

const SlideShow = styled.div`
  /* height: 150px; */
  width: 100%;
  position: absolute;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 200px;
  left: 0;
  animation: ${slideshowMove} 6s linear infinite;
  animation-fill-mode: forwards;
`;

interface ImageProps {
  arrayIndex: number;
  imageIndex: number;
}

const ImageContainer = styled.div<ImageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 10vw;
  opacity: 0.5;
  animation: ${props => props.arrayIndex === props.imageIndex ? opacityAnimation : 'none'} 2500ms linear infinite;
  @media ${laptopSize} {
    width: 100%;
    height: 50px;
  }
`;
const MovingImage = styled.img`
  /* vertical-align: middle; */
  /* width: 100%; */
  /* margin-top: 20px;
  margin-bottom: 20px; */
  max-width: 200px;
  margin: auto;
  max-height: 8vw;
  /* background-color: black; */
  @media ${laptopSize} {
    max-width: 100px;
    max-height: 50px;
  }
`;

const OurCompaniesInfoContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding: 125px;
  background: ${theme.OFF_WHITE_BACKGROUND};
  width: 50vw;
  height: 50vw;
  @media ${desktopSize} {
    padding: 100px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media ${laptopSize} {
    width: calc(83.33333% - 3.4375rem);
    float: left;
    margin-left: 1.875rem;
    margin-left: calc(8.33333% - 2.03125rem + 3.75rem);
    padding-top: 100px;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 100px;
    /* min-height: 50vh; */
  }
  @media ${mobileSize} {
    /* width: calc(100% - 2.5rem);
    float: left;
    margin-left: 1.25rem;
    display: block;
    padding: 5rem 0; */
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
    padding: 80px 0px;
    height: auto;
    /* min-height: 80vh; */
  }
`;

const OurCompaniesh2 = styled.h2`
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 400;
  color: rgba(26, 26, 26, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  margin-top: 10px;
  @media ${mobileSize} {
    letter-spacing: 1px;
  }
`;

const OurCompaniesh3 = styled.h3`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 300;
  font-size: 36px;
  line-height: 1.38889;
  letter-spacing: -0.05rem;
  margin-top: 0.9375rem;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    font-size: 30px;
  }
`;

const OurCompaniesDescription = styled.div`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  margin-top: 29px;
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 300;
  color: ${theme.BLACK_COLOR};
  -webkit-font-smoothing: antialiased;
  @media ${mobileSize} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const CompanyLink = styled.div`
  margin-top: 43px;
  line-height: 1.6;
  letter-spacing: 0;
  position: relative;
`;

export { IndexCompanySegment };
