import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { laptopSize, desktopSize, mobileSize } from '../../../util/variables';
import { AnimatedLink, AnimatedDiv } from '../../ui';
import theme from '../../../util/theme';
import teamImage from '../../../content/img/homepage/base10-team-crop.jpg';

const IndexOurTeamSegment: React.FC = () => {
  return (
    <OurTeamContainer>
      <OurTeamImageContainer>
        <AnimatedDiv sal="slide-up">
          <OurTeamImage />
        </AnimatedDiv>
      </OurTeamImageContainer>

      <OurTeamInfoContainer>
        <AnimatedDiv delay="100">
          <OurTeamh2>OUR TEAM</OurTeamh2>
        </AnimatedDiv>
        <AnimatedDiv delay="300">
          <OurTeamh3>
            <p>
              By entrepreneurs,
              <br />
              for entrepreneurs.
            </p>
          </OurTeamh3>
        </AnimatedDiv>
        <AnimatedDiv delay="500">
          <OurTeamDescription>
            Every partner at Base10 is a founder. We are not just “Founder friendly”, we are part of
            your team and we will work for you non-stop to make your vision happen.
          </OurTeamDescription>
        </AnimatedDiv>
        <AnimatedDiv delay="700">
          <TeamLink>
            <AnimatedLink fontSize="20px" underlineHeight="2px" to="/team/">
              Meet our Team
            </AnimatedLink>
          </TeamLink>
        </AnimatedDiv>
      </OurTeamInfoContainer>
    </OurTeamContainer>
  );
};

const OurTeamContainer = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  /* height: 600px; */
  @media ${laptopSize} {
    display: block;
    width: 100%;
    :after {
      clear: both;
      content: '';
      display: block;
    }
  }
`;

const OurTeamImageContainer = styled.div`
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 50vw;
  height: 50vw;
  /* height: 100%; */
  transform: translateX(100%);
  @media ${mobileSize} {
    float: left;
    display: block;
    width: 100%;
    transform: none;
    max-height: 30vh;
    min-height: 15rem;
  }
  @media ${laptopSize} {
    display: block;
    transform: none;
    float: left;
    display: block;
    width: 100%;
    max-height: 30vh;
    min-height: 15rem;
  }
  @media ${desktopSize} {
    display: block;
    width: 100%;
  }
`;

const OurTeamImage = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  height: auto;
  background-color: transparent;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${teamImage});
  @media ${laptopSize} {
    background-position: center 25%;
  }
  @media ${mobileSize} {
  }
`;

const OurTeamInfoContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding: 12.5rem;
  background: ${theme.OFF_WHITE_BACKGROUND};
  transform: translateX(-100%);
  width: 50vw;
  height: 50vw;
  margin: auto;
  @media ${desktopSize} {
    padding: 100px;
    padding-top: 0px;
    padding-bottom: 0px;
    /* min-height: 50vh; */
  }
  @media ${laptopSize} {
    width: calc(83.33333% - 3.4375rem);
    float: left;
    margin-left: 1.875rem;
    margin-left: calc(8.33333% - 2.03125rem + 3.75rem);
    padding-right: 0;
    padding-left: 0;
    transform: none;
    height: auto;
    padding-bottom: 100px;
    padding-top: 100px;
  }
  @media ${mobileSize} {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
    padding: 80px 0px;
    height: auto;

    /* min-height: 80vh; */
  }
`;

const OurTeamh2 = styled.h2`
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 400;
  color: rgba(26, 26, 26, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  @media ${mobileSize} {
    letter-spacing: 1px;
  }
`;

const OurTeamh3 = styled.h3`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-weight: 300;
  font-size: 36px;
  line-height: 1.38889;
  letter-spacing: -0.05rem;
  margin-top: 0.9375rem;
  @media ${mobileSize} {
    font-size: 30px;
  }
`;

const OurTeamDescription = styled.div`
  margin-top: 41px;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: 0;
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 300;
  color: ${theme.BLACK_COLOR};
  -webkit-font-smoothing: antialiased;
  @media ${mobileSize} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const TeamLink = styled.div`
  margin-top: 2.6875rem;
  line-height: 1.6;
  letter-spacing: 0;
  position: relative;
`;

export { IndexOurTeamSegment };
