interface ICompaniesData {
  id: string;
  industry: string;
  image: string;
  description: string[];
}
const companiesData: ICompaniesData[] = [
  {
    id: 'The Pill Club',
    industry: 'Consumer Logistics',
    image: 'https://archive.base10.vc/wp-content/uploads/2020/06/Color-Logo.png',
    description: [
      'The Pill Club is a telemedicine company whose mission is to increase women’s access to reproductive health products by prescribing and delivering oral contraceptive, female condoms, and Plan B to women across the country.',
      '~100K women (roughly half of The Pill Club’s subscribers) have limited or no access to adequate reproductive resources in their area.',
      'Of the 66 million women of reproductive age living in the US, more than 20 million are in need of publicly funded contraception with 19.5 of those 20 million living in contraceptive deserts, or areas lacking reasonable access to a health center that offers the full range of contraceptive methods.',
    ],
  },
  {
    id: 'Grow',
    industry: 'Transportation',
    image: 'https://archive.base10.vc/wp-content/uploads/2020/06/cottage-technologies-logo.png',
    description: [
      'A primary mission of Grow is to reduce congestion and pollution in Latin America’s most overcrowded cities including Sao Paulo, Mexico City, Rio de Janeiro, Bogota, Buenos Aires, and Lima, among several others.',
      '81 million urban residents in Latin America are exposed to air pollutants exceeding WHO guidelines, but for every 10% reduction in traffic in these urban areas, millions of residents in Latin America are brought within these WHO guidelines. Grow can help accelerate this reduction.',
    ],
  },
  {
    id: 'Wonolo',
    industry: 'Commercial Logistics',
    image: 'https://archive.base10.vc/wp-content/uploads/2018/11/logo-faber-white.png',
    description: [
      'Wonolo’s mission is to increase employment opportunities for skilled and underpaid workers in sectors in and around commercial logistics, such as warehousing and commercial food preparation.',
      'Over 300k workers who are often unemployed or struggling to find additional work depend on Wonolo for income.',
      '2018 AAUW research shows that across the American economy, women are earning 80% of what men make. However, across Wonolo’s platform, the pay gap is only 2%; meaning women earn 98% of what men do.',
    ],
  },
  {
    id: 'Faber',
    industry: 'Construction',
    image: 'https://archive.base10.vc/wp-content/uploads/2020/06/White.png',
    description: [
      'Faber’s mission is to increase employment opportunities for skilled construction workers and training new workers to be construction employees.',
      ' Construction has the worst labor shortage of any physical labor dependent sector. For example, in the US and Canada, there are 400k job openings in the construction field.',
      '~600 workers rely on Faber for income, Faber helps these workers access workers compensation and insurance services, and Faber trains them on safety requirements on-site.',
    ],
  },
  {
    trend: 'Calii',
    industry: 'Food Logistics',
    image: 'https://archive.base10.vc/wp-content/uploads/2019/05/calii_white_logo.png',
    description: [
      'Calii is building a food network in Latin America that helps low income farmers and food producers make more money on their products and helps consumers save money on their food purchases. Today, middle men extract 90% of the value in Latin America’s food supply chain and Calii’s mission is to give this value back to producers and consumers.',
      '40M residents in Latin America are considered hungry by the UN, WHO, and UNICEF, and today Calii helps reduce food prices and increase access to food supply for thousands of residents in Latin America.',
    ],
  },
  {
    id: 'Mason',
    industry: 'Consumer Logistics',
    image: 'https://archive.base10.vc/wp-content/uploads/2020/06/White.png',
    description: [
      'Mason is one of the largest and fastest growing suppliers of smart hardware primarily to pharmaceutical clinical trials, enabling these clinical trial firms to ensure compliant capture and use of highly sensitive information.',
      'Through Mason, thousands of clinical trials, representing ~20k patients, have fundamentally better data protection (and stronger privacy protection) than they did previously.',
    ],
  },
  {
    id: 'Reflektive',
    industry: 'Human Resources',
    image: 'https://archive.base10.vc/wp-content/uploads/2020/06/White.png',
    description: [
      'Reflektive encourages employers to foster employee engagement through corporate social responsibility.',
      'Reflektive also uses software and analytics to foster inclusion by narrowing the gender pay gap, standardardizing performance reviews and encouraging the multi-generational workplace.',
      'Reflektive serves over 500 enterprise clients with over 300,000 employees and was ranked as the 13th Fastest Growing Company in North America on Deloitte’s 2018 Technology Fast 500.',
      'Reflektive is the first people management platform that helps employees and managers work better together fostering employee relations.',
    ],
  },
  {
    id: 'Shipwell',
    industry: 'Logistics',
    image: 'https://archive.base10.vc/wp-content/uploads/2020/06/White.png',
    description: [
      'Shipwell is a Transportation Management System platform that automates workflows and drives efficiency in supply chains. By utilizing Shipwell, companies can utilize practices to make a positive impact on the environment.',
      'The analytics provided by Shipwell unlock data to understand green initiatives. Each business can know the impact of their emissions and fuel usage to for environmental reporting.',
      'Companies rely on Shipwells services for EPA certification because of Shipwell’s ability to provide critical data around emissions.',
      'Significant carbon emissions in freight come from idling in a yard or dock.  Shipwell helps solve this by bridging the gap between shippers, 3PLs, and carriers with in-app messaging.',
      'Shipwell is one of the largest digitally connected fleets in the US connecting more than 2 million carriers, thousands of shippers, and hundreds of 3PLs.',
    ],
  },
  {
    id: 'WeTravel',
    industry: 'Transportation',
    image: 'https://archive.base10.vc/wp-content/uploads/2020/06/White.png',
    description: [
      'Today, WeTravel employs 15 Azerbaijani citizens and also helps administer the largest coding bootcamp in Azerbaijan, which teaches 150+ students per year how to code and helps find jobs at tech companies for all of them.',
      'Additionally, 3,000 tour operators receive payments (and subsequently income) through the WeTravel platform.',
    ],
  },
  {
    id: 'Maven',
    industry: 'Retail Trade',
    image: 'https://archive.base10.vc/wp-content/uploads/2020/06/White.png',
    description: [
      'Maven’s platform helps direct sellers manage their business and keep in touch with their customers.',
      '~41,000 sellers use Maven, often as their primary form of income.',
    ],
  },
];

export default companiesData;
