import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { laptopSize, desktopSize, mobileSize } from '../../../util/variables';
import theme from '../../../util/theme';
import { AnimatedLinkAnchor, AppText, AnimatedDiv } from '../../ui/';
import ReactTooltip from 'react-tooltip';

const VCDiversityPledge: React.FC = () => {
  return (
    <OurTermsWrapper>
      <ReactTooltip
        multiline={true}
        place="top"
        effect="solid"
        css={{
          maxWidth: 300,
          lineHeight: 1.4,
          textAlign: 'center',
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor="rgba(0,0,0,0.9)"
      />
      <TermsOfUseContainer>
        <AnimatedDiv delay="100">
          <Pledgeh2>VC Diversity Pledge</Pledgeh2>
          <Pledgeh2 style={{ color: 'rgba(26, 26, 26, 0.45)' }}>Last updated June 26, 2020</Pledgeh2>
        </AnimatedDiv>

        <AnimatedDiv delay="300">
          <Pledgep>
            Like a number of other VCs, Base10 recently made a statement supporting diversity, but
            we believe we must do more to affect real change. To start, we want to share more about
            what specific initiatives we are implementing.
          </Pledgep>

          <Pledgep>
            The initiatives below are a small step in a long journey. We are talking to and will
            follow the guidance of a number of remarkable organizations like{' '}
            <AnimatedLinkAnchor to="https://precursorvc.com/" paddingBottom="0px">
              Precursor Ventures
            </AnimatedLinkAnchor>
            ,{' '}
            <AnimatedLinkAnchor to="https://www.illumencapital.com/" paddingBottom="0px">
              Illumen Capital
            </AnimatedLinkAnchor>
            , and{' '}
            <AnimatedLinkAnchor to="https://plexocap.com/" paddingBottom="0px">
              Plexo Capital
            </AnimatedLinkAnchor>{' '}
            who started this journey years ago.
          </Pledgep>

          <Pledgeh1>
            1. Financial support, in perpetuity, to organizations fighting inequality and fostering
            diversity
          </Pledgeh1>

          <Pledgep>
            Base10 is committing, immediately and in perpetuity, 1% of all annual operating profit
            from our management company as well as 1% of carried interest in all Base10 funds to
            fund organizations fostering diversity. This is a very small first step that will grow
            and, we hope, become significant over time. For context and as a rough calculation: If
            every VC pledged just 1% of profit and 1% carried interest per year, we could
            collectively{' '}
            <SpanContainer data-tip="According to NVCA, there was $444B of AUM in U.S. venture capital at the end of 2019. If that $444B gave away 1% of management company profits (assuming 2.5% mgmt fee and 25% margins) and 1% of carry (assuming 3.0x average fund returns, 20% carry, 10 year life), then it would be $205M per year in donations.">
              raise $200M+ each year
            </SpanContainer>
            <SmallGrayText>
              {' '}
              (According to NVCA, there was $444B of AUM in U.S. venture capital at the end of 2019.
              If that $444B gave away 1% of management company profits (assuming 2.5% mgmt fee and
              25% margins) and 1% of carry (assuming 3.0x average fund returns, 20% carry, 10 year
              life), then it would be $205M per year in donations.)
            </SmallGrayText>
            .
          </Pledgep>

          <Pledgep>
            This money will go to{' '}
            <AnimatedLinkAnchor
              to="https://docs.google.com/spreadsheets/d/1QoxmDihX9OcfQ-mfBzN7FpgmM_TGc4vZb2_xfbj2u38/edit#gid=795719132"
              paddingBottom="0px"
            >
              organizations fighting for racial equality
            </AnimatedLinkAnchor>{' '}
            like NAACP or EJI and organizations{' '}
            <AnimatedLinkAnchor
              to="https://docs.google.com/spreadsheets/d/1SIg7S3gztIeEt7mV7H34yfaSkIFqCWJH4H79xPK4MrE/edit#gid=0"
              paddingBottom="0px"
            >
              fighting for better representation of minorities in tech
            </AnimatedLinkAnchor>{' '}
            and venture capital.{' '}
            <AnimatedLinkAnchor
              to="https://www.census.gov/newsroom/releases/archives/population/cb12-243.html"
              paddingBottom="0px"
            >
              50% of children born this year are minorities and by 2024, 50% of the U.S. population
              will be minorities.
            </AnimatedLinkAnchor>{' '}
            Not only is this the right thing to do, but VC needs to support minorities now so they
            are prepared to build the next generation of great startups in our industry.
          </Pledgep>

          <Pledgeh1>
            2. Investing in more minority founded and minority run businesses
          </Pledgeh1>

          <Pledgep>
            We invest in Automation for the Real Economy businesses that are solving problems that
            affect 99% of people in industries like logistics or construction. To find founders with
            a genuine interest in solving those problems, we need to meet more founders of diverse
            backgrounds. One step we are taking is to work more closely with the firms whose mission
            it is to back founders with diverse backgrounds so we can co-invest more often and
            actively share deals with them.
          </Pledgep>

          <Pledgep>
            Fortunately, there are many great firms focused on this mission and they are generous
            enough to spend time with us. These organizations include{' '}
            <AnimatedLinkAnchor to="https://645ventures.com/" paddingBottom="0px">
              645 Ventures
            </AnimatedLinkAnchor>
            ,{' '}
            <AnimatedLinkAnchor to="https://www.equal.vc/" paddingBottom="0px">
              Equal Ventures
            </AnimatedLinkAnchor>
            ,{' '}
            <AnimatedLinkAnchor to="https://kindredventures.com/" paddingBottom="0px">
              Kindred Ventures
            </AnimatedLinkAnchor>
            ,{' '}
            <AnimatedLinkAnchor to="https://precursorvc.com/" paddingBottom="0px">
              Precursor Ventures
            </AnimatedLinkAnchor>
            , and many others.
          </Pledgep>

          <Pledgeh1>
            3. Hiring and fostering a diverse team at all levels of the organization
          </Pledgeh1>

          <Pledgep>
            We are creating new processes to find and hire more minorities. One example is a summer
            internship program focused on attracting minority talent which we implemented in
            conjunction with the Black Venture Capital Consortium. Other organizations such as{' '}
            <AnimatedLinkAnchor to="http://www.code2040.org/" paddingBottom="0px">
              Code2040
            </AnimatedLinkAnchor>{' '}
            or{' '}
            <AnimatedLinkAnchor to="https://www.hbcu.vc/" paddingBottom="0px">
              HBCUvc
            </AnimatedLinkAnchor>{' '}
            are also focused on organizing similar programs.
          </Pledgep>

          <Pledgep>
            Training to recognize implicit bias. The regrettable lack of diversity in venture
            capital is not only about sourcing candidates but also about promotion and retention.
            Challenges in these areas are often a consequence of implicit bias. Base10 is working
            closely with our LP,{' '}
            <AnimatedLinkAnchor to="https://www.illumencapital.com/" paddingBottom="0px">
              Illumen Capital
            </AnimatedLinkAnchor>
            , to train all Base10 employees on how to reduce implicit biases.
          </Pledgep>

          <Pledgeh1>
            4. Making a time commitment to get to know underrepresented communities
          </Pledgeh1>

          <Pledgep>
            Spend more time with the minority community. In the past, we have spent time with
            organizations like{' '}
            <AnimatedLinkAnchor to="https://foundergym.com/" paddingBottom="0px">
              Founders Gym
            </AnimatedLinkAnchor>
            ,{' '}
            <AnimatedLinkAnchor to="https://ltxfest.com/" paddingBottom="0px">
              the LTX Fest
            </AnimatedLinkAnchor>
            ,{' '}
            <AnimatedLinkAnchor to="http://www.code2040.org/" paddingBottom="0px">
              Code2040
            </AnimatedLinkAnchor>
            ,{' '}
            <AnimatedLinkAnchor
              to="https://www.smash.org/about/mission-and-vision"
              paddingBottom="0px"
            >
              SMASH
            </AnimatedLinkAnchor>
            , and{' '}
            <AnimatedLinkAnchor to="https://blueprint.dormroomfund.com/" paddingBottom="0px">
              the BluePrint Project
            </AnimatedLinkAnchor>
            . These organizations do a great job of providing educational and inspirational
            opportunities for people of all backgrounds. We are committing to a minimum number of
            hours per quarter that every employee will devote to various organizations.
          </Pledgep>

          <Pledgeh3>Accountability: How do we keep ourselves honest?</Pledgeh3>

          <Pledgep>
            The Kapor Center reports{' '}
            <AnimatedLinkAnchor
              to="https://mk0kaporcenter5ld71a.kinstacdn.com/wp-content/uploads/2018/02/KC18001_report_v6-1.pdf"
              paddingBottom="0px"
            >
              83% of leaders in tech are white
            </AnimatedLinkAnchor>
            . Tech companies are becoming more transparent with their{' '}
            <AnimatedLinkAnchor to="https://www.diversityreports.org/" paddingBottom="0px">
              diversity statistics
            </AnimatedLinkAnchor>
            , and VC should do the same. This is going to be a long journey and we must rely on one
            another to remain accountable. As a firm, Base10 plans to create specific OKRs for the
            initiatives above and report on a yearly basis. We believe that, as an industry, only by
            measuring leading indicators will we achieve lasting results.
          </Pledgep>

          <br />

          <Pledgep>
            <i>Written by Base10 with input from many of our peers</i>
          </Pledgep>
        </AnimatedDiv>
      </TermsOfUseContainer>
    </OurTermsWrapper>
  );
};

const TooltipText = styled.span`
  visibility: hidden;
  opacity: 0;
  width: 400px;
  background-color: ${theme.BLACK_COLOR};
  color: ${theme.WHITE_COLOR};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  border-radius: 0px;
  padding: 25px 25px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -200px;
  transition: all 300ms ease-in-out;
  ::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: ${theme.BLACK_COLOR} transparent transparent transparent;
  }
`;

const SmallGrayText = styled.span`
  display: none;
  @media ${mobileSize} {
    color: rgba(26, 26, 26, 0.5);
    display: inline;
    font-size: 14px;
  }
`;

const Pledgeh1 = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.8px;
  line-height: 37px;
  margin-bottom: 23px;
  margin-top: 23px;
  -moz-osx-font-smoothing: grayscale;
  @media ${mobileSize} {
    font-size: 21px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 21px;
    margin-top: 21px;
  }
`;

const Pledgeh2 = styled(AppText)`
  color: rgba(26, 26, 26, 0.65);
  font-family: ${theme.SECONDARY_FONT};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 14px;
  margin-top: 0px;
  margin-bottom: 42px;
  opacity: 1;
  text-transform: uppercase;
  @media ${mobileSize} {
    margin-bottom: 30px;
  }
`;

const Pledgeh3 = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 26px;
  margin-bottom: 26px;
  @media ${mobileSize} {
    margin: 0px;
    font-size: 18px;
    line-height: 24px;
  }
`;

const Pledgep = styled(AppText)`
  color: rgba(26, 26, 26, 0.8);
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: 300;
  letter-spacing: normal;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 32px;
  a {
    font-size: 20px;
    color: ${theme.PRIMARY_COLOR};
  }
  @media ${mobileSize} {
    margin-top: 18px;
    margin-bottom: 18px;
    font-size: 18px;
    line-height: 24px;
    a {
      font-size: 18px;
    }
  }
`;

const SpanContainer = styled.span`
  position: relative;
  display: inline;
  border-bottom: 1px dotted ${theme.PRIMARY_COLOR};
  :hover {
    cursor: help;
  }
  @media ${mobileSize} {
    border-bottom: none;
    pointer-events: none;
    cursor: text;
    :hover {
      pointer-events: none;
      cursor: text;
    }
  }
`;

const TermsOfUseContainer = styled.div`
  max-width: 915px;
  @media ${mobileSize} {
    a {
      font-size: 18px;
    }
  }
`;

const OurTermsWrapper = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: 250px 100px 100px 100px;
  @media ${mobileSize} {
    padding: 200px 25px 80px 25px;
  }
`;

export { VCDiversityPledge };
