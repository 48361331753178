const mapCompanyImages = {
  canada: ['spocket', 'smile.io', 'rewind backups'],
  america: ['gorgias', 'attentive', 'recharge'],
  europe: ['omnisend', 'zenloop', 'nosto'],
};

interface IKeyInsightsList {
  id: number;
  ordinal: number;
  head: string;
  text: string;
}

const keyInsightsList: IKeyInsightsList[] = [
  {
    id: 1,
    ordinal: 1,
    head: 'eCommerce penetration has skyrocketed',
    text:
      'eCommerce share of retail spend has grown from 6% to 16% to now ~30% and has created billions in market cap for Amazon, Shopify, and the brands that sell on these platforms. This growth has also fueled a lot of internet advertising growth (particularly Facebook and Pinterest) as these new businesses connect with customers digitally.',
  },
  {
    id: 2,
    ordinal: 2,
    head: 'There are millions of eCommerce merchants now',
    text:
      'Shopify is now a $125B giant with over 1M merchants growing 119% YoY. Mom and pop upstart SMBs and even mature eCommerce retailers doing over $1B of revenue like Fashion Nova and Chubbies, need tools to manage their digital presence.',
  },
  {
    id: 3,
    ordinal: 3,
    head: 'eCommerce companies need purpose built software',
    text:
      'eCommerce companies specialize in product, customer acquisition, and brand. Most are not true technology companies unless they are infrastructure providers, and this creates a large opportunity for software vendors to sell automation tools to these businesses.',
  },
  {
    id: 4,
    ordinal: 4,
    head: 'Agencies are a unique distribution channel for eCommerce SaaS',
    text:
      'In addition to the eCommerce end customer, there is a large ecosystem of agencies and integration partners that do this work for eCommerce companies and have acted as a channel partner for companies like NS8 and others.',
  },
];

interface ICircleNumbers {
  id: number;
  title: string;
  number: number;
}

const circleNumbers: ICircleNumbers[] = [
  {
    id: 1,
    title: 'In Trend',
    number: 68,
  },
  {
    id: 2,
    title: 'Seed',
    number: 16,
  },
  {
    id: 3,
    title: 'Series A',
    number: 30,
  },
  {
    id: 4,
    title: 'Series B + Growth',
    number: 22,
  },
  {
    id: 5,
    title: '>500M in Value',
    number: 3,
  },
];

interface IResearchInvestors {
  id: number;
  investor_image: string;
  investor_name: string;
  investments: string[];
  avg_check: string; // changed to string for $ and M
  key_investors: string[];
}

const mostActiveInvestors: IResearchInvestors[] = [
  {
    id: 2,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Y Combinator',
    investments: ['OneSignal', 'Shogun', 'Tapcart', 'Sourcify', 'Proof'],
    avg_check: '$0-$50',
    key_investors: ['N/A'],
  },
  {
    id: 3,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Accomplice',
    investments: ['Klaviyo', 'Postscript', 'Nacelle', 'Peel Insights'],
    avg_check: '$0-$10',
    key_investors: ['TJ Mahony'],
  },
  {
    id: 4,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Base10',
    investments: ['Malomo', 'Wise'],
    avg_check: '$0-$10',
    key_investors: ['Chris Zeoli, Rexhep Dollaku'],
  },
  {
    id: 5,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Techstars',
    investments: ['Spocket', 'Tone SMS'],
    avg_check: '$0-$10',
    key_investors: ['N/A'],
  },
  {
    id: 13,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'SignalFire',
    investments: ['OneSignal', 'Tapcart'],
    avg_check: '$0-$30',
    key_investors: ['Chris Farmer'],
  },
  {
    id: 6,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Initialized Capital',
    investments: ['Shogun', 'Easypost'],
    avg_check: '$0-$10',
    key_investors: ['Garry Tan'],
  },
  {
    id: 7,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Y Combinator',
    investments: ['OneSignal', 'Shogun', 'Tapcart', 'Sourcify', 'Proof'],
    avg_check: '$0-$50',
    key_investors: ['N/A'],
  },
  {
    id: 8,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Harlem Capital',
    investments: ['Malomo'],
    avg_check: '$0-$10',
    key_investors: ['Henri Pierre-Jacques'],
  },
  {
    id: 9,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'IVP',
    investments: ['Attentive'],
    avg_check: '$10-$100',
    key_investors: ['Somesh Dash'],
  },
  {
    id: 10,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Sequoia',
    investments: ['Attentive'],
    avg_check: '$10-$500',
    key_investors: ['Pat Grady'],
  },
];

const topCompaniesInvestors: IResearchInvestors[] = [
  {
    id: 1,
    investor_image: 'https://archive.base10.vc/wp-content/uploads/2018/07/team-adeyemi.jpg',
    investor_name: 'Sequoia',
    investments: ['Attentive'],
    avg_check: '$10-$500',
    key_investors: ['Pat Grady'],
  },
  {
    id: 2,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'IVP',
    investments: ['Attentive'],
    avg_check: '$10-$100',
    key_investors: ['Somesh Dash'],
  },
  {
    id: 3,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Coatue',
    investments: ['Attentive'],
    avg_check: '$0-$500',
    key_investors: ['Thomas Laffont'],
  },
  {
    id: 4,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Flex Capital',
    investments: ['Gorgias'],
    avg_check: '$0-$30',
    key_investors: ['Auren Hoffman'],
  },
  {
    id: 5,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'SaaStr',
    investments: ['Gorgias'],
    avg_check: '$0-$10',
    key_investors: ['Jason Lemkin'],
  },
  {
    id: 6,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Accomplice',
    investments: ['Klaviyo', 'Postscript'],
    avg_check: '$0-$10',
    key_investors: ['TJ Mahony'],
  },
  {
    id: 7,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Y Combinator',
    investments: ['PostScript'],
    avg_check: '$0-$50',
    key_investors: ['N/A'],
  },
  {
    id: 8,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Summit Partners',
    investments: ['Klaviyo'],
    avg_check: '$0-$500',
    key_investors: ['Michael Medici'],
  },
  {
    id: 9,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'SignalFire',
    investments: ['Tapcart'],
    avg_check: '$0-$30',
    key_investors: ['Chris Farmer'],
  },
  {
    id: 10,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Floodgate',
    investments: ['Emotive'],
    avg_check: '$0-$10',
    key_investors: ['Mike Maples'],
  },
  {
    id: 11,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'TenOneTen',
    investments: ['Emotive'],
    avg_check: 'N/A',
    key_investors: ['N/A'],
  },
  {
    id: 12,
    investor_image:
      'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    investor_name: 'Mucker Capital',
    investments: ['Emotive'],
    avg_check: 'N/A',
    key_investors: ['N/A'],
  },
];

interface FounderWithLink {
  name: string;
  link?: string;
}

interface ICompaniesRow {
  id?: number;
  name: string;
  website: string;
  logo_url?: string;
  segment: string;
  founders: FounderWithLink[];
  stage: string;
  founded: string;
  hq_location: string;
  amount_raised: string;
  people: number | string;
  key_investors: string[];
  description: string;
}

interface IResearchCompanies {
  id: number;
  section_title: string;
  tooltip: string;
  rows: ICompaniesRow[];
}

const researchCompanies: IResearchCompanies[] = [
  {
    id: 1,
    section_title: 'Customer Support',
    tooltip: 'software for merchants to manage customer support inquiries',
    rows: [
      {
        id: 1,
        name: 'Gorgias',
        website: 'https://www.gorgias.com/',
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        segment: 'Customer support -- Service Desk',
        stage: 'Series B + Growth',
        founders: [
          { name: 'Alex Plugaru', link: 'https://www.linkedin.com/in/313373/' },
          { name: 'Romain Lapeyre Legend', link: 'https://www.linkedin.com/in/romainlapeyre/' },
        ],
        founded: '2015',
        hq_location: 'San Francisco, CA',
        amount_raised: '$18M',
        people: 61,
        key_investors: [
          'Flex Capital',
          'CRV',
          'Amplify Partners',
          'Alven',
          'FLEX Capital Management',
          'SaaStr Fund',
          'Kima Ventures',
          'Alive Ideas',
        ],
        description: 'Help desk for Shopify merchants',
      },
      {
        id: 2,
        name: 'Loox',
        website: 'https://loox.app/',
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        segment: 'Customer support -- reviews',
        stage: 'Series A',
        founders: [
          { name: 'Moran Benisty', link: 'https://www.linkedin.com/in/moran-benisty-67103334/' },
          { name: 'Yoni Elbaz', link: 'https://www.linkedin.com/in/yoni-elbaz-b078808/' },
        ],
        founded: '2015',
        hq_location: 'Tel Aviv, Israel',
        amount_raised: '$0M',
        people: 13,
        key_investors: ['None -- bootstrapped'],
        description: 'Customization and automated collection of customer reviews',
      },
      {
        id: 3,
        name: 'ZENLOOP',
        website: 'https://www.zenloop.com/',
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        segment: 'Customer support -- CX',
        stage: 'Series A',
        founders: [
          { name: 'Bjorn Kolbmuller', link: 'https://www.linkedin.com/in/kolbmueller/' },
          {
            name: 'Lukasz Lazewski',
            link: 'https://www.linkedin.com/in/lukasz-lazewski-40562718/',
          },
          {
            name: 'Paul Schwarzenholz',
            link: 'https://www.linkedin.com/in/paul-schwarzenholz-88a0485b/',
          },
        ],
        founded: '2016',
        hq_location: 'Berlin, Germany',
        amount_raised: '$5M',
        people: 45,
        key_investors: ['Nauta Capital', 'PIton Capital', 'Signals Venture Capital'],
        description: 'Experience management and NPS management',
      },
      {
        id: 4,
        name: 'Okendo',
        website: 'https://www.okendo.io/',
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        segment: 'Customer support -- reviews',
        stage: 'Seed',
        founders: [{ name: 'Matt Goodman', link: 'https://www.linkedin.com/in/matthew-goodman/' }],
        founded: '2016',
        hq_location: 'Sydney, Australia',
        amount_raised: '$0M',
        people: 22,
        key_investors: ['None -- bootstrapped'],
        description: 'Customization and automated collection of customer reviews',
      },
      {
        id: 5,
        name: 'Judge.me',
        website: 'https://judge.me/',
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        segment: 'Customer support -- reviews',
        stage: 'Series A',
        founders: [{ name: 'Peter-Jan Celis', link: 'https://www.linkedin.com/in/peterjancelis/' }],
        founded: '2015',
        hq_location: 'Ho Chi Minh City, Vietnam',
        amount_raised: '$0M',
        people: 17,
        key_investors: ['None -- bootstrapped'],
        description: 'Customization and automated collection of customer reviews',
      },
      {
        id: 6,
        name: 'Stamped.io',
        website: 'https://stamped.io/',
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        segment: 'Customer support -- reviews',
        stage: 'Seed',
        founders: [{ name: 'Tommy Ong', link: 'https://www.linkedin.com/in/tommy-ong-205a264a/' }],
        founded: '2016',
        hq_location: 'Singapore',
        amount_raised: '$0M',
        people: 4,
        key_investors: ['None -- bootstrapped'],
        description: 'Customization and automated collection of customer reviews',
      },
    ],
  },
  {
    id: 2,
    section_title: 'Mobile',
    tooltip: 'software to optimize customer engagement and store design on mobile devices',
    rows: [
      {
        id: 1,
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        name: 'Attentive',
        website: 'https://www.attentivemobile.com/',
        segment: 'Mobile -- SMS marketing',
        founders: [
          { name: 'Andrew Jones', link: 'https://www.linkedin.com/in/andrewgjones/' },
          { name: 'Brian Long', link: 'https://www.linkedin.com/in/brianclong/' },
          { name: 'Ethan Lo', link: 'https://www.linkedin.com/in/ethan-lo/' },
        ],
        stage: 'Series B + Growth',
        founded: '2016',
        hq_location: 'New York, NY',
        amount_raised: '$164M',
        people: 326,
        key_investors: [
          'Sequoia',
          'IVP',
          'Coatue',
          'Eniac',
          'Bain Capital Ventures',
          'Nextview Ventures',
          'Global Equities',
        ],
        description: 'SMS marketing for coupons, cart abandonment and exclusives',
      },
      {
        id: 2,
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        name: 'Postscript',
        website: 'https://www.postscript.io/',
        segment: 'Mobile -- SMS marketing',
        founders: [
          { name: 'Adam Turner', link: 'https://www.linkedin.com/in/adam-s-turner/' },
          { name: 'Alex Beller', link: 'https://www.linkedin.com/in/alex-beller-70452852/' },
          {
            name: 'Colin Turner',
            link:
              'https://www.linkedin.com/in/postscript-is-building-the-worlds-greatest-remote-org/',
          },
        ],
        stage: 'Series A',
        founded: '2018',
        hq_location: 'Los Angeles, CA',
        amount_raised: '$10M',
        people: 22,
        key_investors: ['Accomplice', 'Y Combinator', '1984 Ventures'],
        description: 'SMS marketing mainly targetted at mid-market Shopify',
      },
      {
        id: 3,
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        name: 'LiveRecover',
        website: 'https://liverecover.com/',
        segment: 'Mobile -- SMS marketing',
        founders: [
          { name: 'Dennis Hegstad', link: 'https://www.linkedin.com/in/dennis-hegstad-8b6131180/' },
        ],
        stage: 'Series A',
        founded: '2018',
        hq_location: 'Austin, TX',
        amount_raised: '$0M',
        people: 9,
        key_investors: ['N/A'],
        description: 'N/A',
      },
      {
        id: 4,
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        name: 'Emotive',
        website: 'https://emotive.io/',
        segment: 'Mobile -- SMS marketing',
        founders: [
          { name: 'Brian Zatulove', link: 'https://www.linkedin.com/in/brian-zatulove-3a16788a/' },
          { name: 'Zach Wise', link: 'https://www.linkedin.com/in/zachary-wise-b11210111/' },
        ],
        stage: 'Series A',
        founded: '2018',
        hq_location: 'Los Angeles, CA',
        amount_raised: '$8M',
        people: 27,
        key_investors: ['Mucker Capital', 'TenOneTen Ventures', 'Buffalo Ventures', 'Floodgate'],
        description: 'SMS marketing across Shopify, Magento, etc. and mid-market focused',
      },
      {
        id: 5,
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        name: 'Voyage SMS',
        website: 'https://www.voyagesms.com/',
        segment: 'Mobile -- SMS marketing',
        founders: [
          { name: 'Carter Gaffney', link: 'https://www.linkedin.com/in/cartergaffney/' },
          { name: 'Corey Epstein', link: 'https://www.linkedin.com/in/coreyepstein/' },
        ],
        stage: 'Seed',
        founded: '2019',
        hq_location: 'Los Angeles, CA',
        amount_raised: '$0.6M',
        people: 15,
        key_investors: ['Guild Capital'],
        description:
          'SMS marketing across Shopify, Magento, etc.; half inbound and half outbound focused on upper mid-market',
      },
      {
        id: 6,
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        name: 'SMSBump',
        website: 'https://smsbump.com/',
        segment: 'Mobile -- SMS marketing',
        founders: [
          { name: 'Mihail Stoychev', link: 'https://www.linkedin.com/in/mihailstoychev/' },
        ],
        stage: 'Series A',
        founded: '2018',
        hq_location: 'Boston, MA',
        amount_raised: '$0M',
        people: 16,
        key_investors: ['Yotpo', 'Eleven Ventures'],
        description: 'SMS for Shopify and GTM through app store (Acquired by Yotpo)',
      },
      {
        id: 7,
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        name: 'Tone SMS',
        website: 'https://tonemessaging.com/',
        segment: 'Mobile -- SMS marketing',
        founders: [{ name: 'Tivan Amour', link: 'https://www.linkedin.com/in/tivanamour/' }],
        stage: 'Series A',
        founded: '2017',
        hq_location: 'Boston, MA',
        amount_raised: '$2M',
        people: 15,
        key_investors: ['Bling Capital', 'Techstars'],
        description: 'SMS marketing mainly targetted at mid-market Shopify',
      },
      {
        id: 8,
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        name: 'OneSignal',
        website: 'https://onesignal.com/',
        segment: 'Mobile -- Push notifications',
        founders: [
          { name: 'George Deglin', link: 'https://www.linkedin.com/in/gdeglin/' },
          { name: 'Long Vo', link: 'https://www.linkedin.com/in/longvo/' },
        ],
        stage: 'Series B + Growth',
        founded: '2014',
        hq_location: 'San Francisco, CA',
        amount_raised: '$32M',
        people: 60,
        key_investors: ['SignalFire', 'Y Combinator', 'Rakuten Capital', 'Auren Hoffman'],
        description: 'Mobile + web push notifications and engagement platform',
      },
      {
        id: 9,
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        name: 'Pushnami',
        website: 'https://pushnami.com/',
        segment: 'Mobile -- Push notifications',
        founders: [
          { name: 'Emerson Smith', link: 'https://www.linkedin.com/in/emerson-smith-a562861/' },
        ],
        stage: 'Series A',
        founded: '2017',
        hq_location: 'Austin, TX',
        amount_raised: '$0M',
        people: 41,
        key_investors: ['None -- bootstrapped'],
        description: 'Mobile + web push provider that sells into adtech and ecommerce',
      },
      {
        id: 10,
        logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
        name: 'PushOwl',
        website: 'https://pushowl.com/',
        segment: 'Mobile -- Push notifications',
        founders: [{ name: 'Hashank Kumar', link: 'https://www.linkedin.com/in/shashankkr9/' }],
        stage: 'Seed',
        founded: '2016',
        hq_location: 'Bangalore, India',
        amount_raised: '$0M',
        people: 13,
        key_investors: ['None -- bootstrapped'],
        description: 'Mobile + web push for Shopify',
      },
    ],
  },
  {
    id: 3,
    section_title: 'Multi-channel Marketing',
    tooltip: 'email, mobile, social and other omnichannel marketing automation',
    rows: [
      {
        name: 'Klaviyo',
        website: 'https://www.klaviyo.com/',
        segment: 'Multi-channel marketing',
        founders: [
          { name: 'Andrew Bialecki', link: 'https://www.linkedin.com/in/bialecki/' },
          { name: 'Ed Hallen', link: 'https://www.linkedin.com/in/edhallen/' },
        ],
        stage: 'Series B + Growth',
        founded: '2012',
        hq_location: 'Boston, MA',
        amount_raised: '$159M',
        people: 422,
        key_investors: ['Accomplice', 'Astral Capital', 'Summit Partners'],
        description: 'Leading email marketing automation SaaS for ecommerce',
      },
      {
        name: 'Omnisend',
        website: 'https://www.omnisend.com/',
        segment: 'Multi-channel marketing',
        founders: [
          { name: 'Rytis Lauris', link: 'https://www.linkedin.com/in/rytisl/' },
          {
            name: 'Zygimantas Stauga',
            link: 'https://www.linkedin.com/in/%C5%BEygimantas-stauga-0309a959/',
          },
        ],
        stage: 'Series A',
        founded: '2014',
        hq_location: 'London, England',
        amount_raised: '$0M',
        people: 60,
        key_investors: ['None -- bootstrapped'],
        description: 'All-in-one marketing automation including email and SMS',
      },
      {
        name: 'Yotpo',
        website: 'https://www.yotpo.com/',
        segment: 'Multi-channel marketing',
        founders: [
          { name: 'Omri Cohen', link: 'https://www.linkedin.com/in/omric/' },
          { name: 'Tomer Tagrin', link: 'https://www.linkedin.com/in/tagrin/' },
        ],
        stage: 'Series B + Growth',
        founded: '2011',
        hq_location: 'New York, NY',
        amount_raised: '$101M',
        people: 441,
        key_investors: [
          'Vintage Investment Partners',
          'Blumberg Capital',
          'Market',
          'Vertext Ventrues',
          'Access Industries',
          'Rhodium',
          'Bessemer Venture Partners',
        ],
        description: 'Commerce marketing cloud for reviews, loyalty and referrals',
      },
      {
        name: 'Klevu',
        website: 'https://www.klevu.com/',
        segment: 'Multi-channel marketing',
        founders: [
          { name: 'Jyrki Kontio', link: 'https://www.linkedin.com/in/jyrkikontio/' },
          { name: 'Nilay Oza', link: 'https://www.linkedin.com/in/nilayoza/' },
          { name: 'Niraj Aswani', link: 'https://www.linkedin.com/in/nirajaswani/' },
        ],
        stage: 'Series B + Growth',
        founded: '2013',
        hq_location: 'Helsinki, Finland',
        amount_raised: '$0M',
        people: 'N/A',
        key_investors: ['Inventure', 'Evli Growth Partners', 'KoppiCatch'],
        description: 'N/A',
      },
      {
        name: 'Carthook',
        website: 'https://carthook.com/',
        segment: 'Multi-channel marketing',
        founders: [
          { name: 'Ben Fisher', link: 'https://www.linkedin.com/in/skinnyandbald/' },
          { name: 'Charles Gallant', link: 'https://www.linkedin.com/in/charlesgallant/' },
          { name: 'Jordan Gal', link: 'https://www.linkedin.com/in/jordangal/' },
        ],
        stage: 'Series A',
        founded: '2015',
        hq_location: 'Portland, OR',
        amount_raised: '$0M',
        people: 22,
        key_investors: ['Starve Ups'],
        description: 'Cart abandonment, and checkout optimization / payments software',
      },
      {
        name: 'CartKit',
        website: 'https://www.cartkit.com/',
        segment: 'Multi-channel marketing',
        founders: [{ name: 'Jason Bapna', link: 'https://www.linkedin.com/in/jasonbapna/' }],
        stage: 'Seed',
        founded: '2016',
        hq_location: 'Atlanta, GA',
        amount_raised: '$0M',
        people: 6,
        key_investors: ['None -- bootstrapped'],
        description: 'Cart abandonment with SMS and push',
      },
      {
        name: 'Retention Rocket',
        website: 'https://www.retentionrocket.com/',
        segment: 'Multi-channel marketing',
        founders: [{ name: 'Dylan Whitman', link: 'https://www.linkedin.com/in/dylanwhitman/' }],
        stage: 'Seed',
        founded: '',
        hq_location: 'San Diego, CA',
        amount_raised: '$0M',
        people: 'N/A',
        key_investors: ['N/A'],
        description: 'N/A',
      },
      {
        name: 'Emarsys',
        website: 'http://www.emarsys.com/',
        segment: 'Multi-channel marketing',
        founders: [
          { name: 'Daniel Harari', link: 'https://www.linkedin.com/in/danielharari/' },
          { name: 'Hagai Hartman', link: 'https://www.linkedin.com/in/hagai-hartman-086272/' },
          { name: 'Josef Ahorner' },
        ],
        stage: 'Series B + Growth',
        founded: '',
        hq_location: 'Vienna, Austria',
        amount_raised: '$0M',
        people: 'N/A',
        key_investors: ['Vector Capital'],
        description: 'N/A',
      },
      {
        name: 'Smartrmail',
        website: 'https://www.smartrmail.com/',
        segment: 'Multi-channel marketing',
        founders: [
          { name: 'George Hartley', link: 'https://www.linkedin.com/in/gthartley/' },
          {
            name: 'Philip Slusarski',
            link: 'https://www.linkedin.com/in/philip-slusarski-74795018/',
          },
        ],
        stage: 'Seed',
        founded: '2017',
        hq_location: 'Melbourne, Australia',
        amount_raised: '$1M',
        people: 13,
        key_investors: ['Blackbird Ventures'],
        description: 'Email marketing automation SaaS, competes with Klaviyo',
      },
    ],
  },
  {
    id: 4,
    section_title: 'Store design',
    tooltip:
      'software for merchants to customize and to optimize store experience with a focus on omnichannel',
    rows: [
      {
        name: 'Shogun',
        website: 'https://getshogun.com/frontend',
        segment: 'Store design',
        founders: [
          { name: 'Damien Kan', link: 'https://www.linkedin.com/in/damien-kan-92ab0016/' },
          { name: 'Finbarr Taylor', link: 'https://www.linkedin.com/in/finbarrtaylor/' },
          { name: 'Nick Raushenbush', link: 'https://www.linkedin.com/in/nickraushenbush/' },
        ],
        stage: 'Series B + Growth',
        founded: '2015',
        hq_location: 'San Francisco, CA',
        amount_raised: '$12M',
        people: 142,
        key_investors: [
          'Initialized Capital',
          'Y Combinator',
          'VMG Partners',
          'Pioneer Fund',
          'FundersClub',
        ],
        description: 'Drag and drop website builder for ecommerce platforms',
      },
      {
        name: 'Tapcart',
        website: 'https://tapcart.com/',
        segment: 'Store design',
        founders: [
          { name: 'Eric Netsch', link: 'https://www.linkedin.com/in/ericnetsch/' },
          { name: 'Sina Mobasser', link: 'https://www.linkedin.com/in/mobass/' },
        ],
        stage: 'Series A',
        founded: '2017',
        hq_location: 'Los Angeles, CA',
        amount_raised: '$14M',
        people: 35,
        key_investors: ['Greycroft', 'SignalFire', 'Amplify.LA', 'Act One Ventures', 'Luma Launch'],
        description: 'Mobile app builder and app marketplace for mobile commerce on Shopify',
      },
      {
        name: 'Privy',
        website: 'https://dashboard.privy.com/users/sign_up',
        segment: 'Pop ups SMS and email',
        founders: [{ name: 'Ben Jabbawy', link: 'https://www.linkedin.com/in/benjaminjabbawy/' }],
        stage: 'Series B + Growth',
        founded: '2012',
        hq_location: 'Boston, MA',
        amount_raised: '$7M',
        people: 77,
        key_investors: ['Accomplice', 'Tuhaye Venture Partners', 'Mike Volpe'],
        description:
          'Ecommerce marketing automation including email, pop ups and promotions like abandoned cart',
      },
      {
        name: 'Expivi',
        website: 'https://www.expivi.com/en-US',
        segment: 'Store design',
        founders: [
          {
            name: 'Babak Mohammad Mirzaie',
            link: 'https://www.linkedin.com/in/babak-m-mirzaie-a6b3a549/',
          },
          { name: 'Jur Rademakers' },
          { name: 'Siamak Mohammad Mirzaie' },
        ],
        stage: 'Series A',
        founded: '2017',
        hq_location: 'Eindhoven, The Netherlands',
        amount_raised: '$0M',
        people: 29,
        key_investors: ['None -- bootstrapped'],
        description:
          'Visual eCommerce platform that allows for product customization like 3D, AR and others',
      },
    ],
  },
  {
    id: 5,
    section_title: 'Business Analytics and Data',
    tooltip: 'software for merchants to centralize and visualize customer interaction data',
    rows: [
      {
        name: 'Yaguara',
        website: 'https://www.yaguara.co/',
        segment: 'Business Analytics and Data',
        founders: [
          { name: 'Chad Nickell', link: 'https://www.linkedin.com/in/chadnickell/' },
          { name: 'Greg Parker', link: 'https://www.linkedin.com/in/gregbparker/' },
          {
            name: 'Jonathan Smalley',
            link: 'https://www.linkedin.com/in/jonathan-smalley-25349634/',
          },
          {
            name: 'Patrick Williamson',
            link: 'https://www.linkedin.com/in/patrick-williamson-41248b16/',
          },
        ],
        stage: 'Series A',
        founded: '2016',
        hq_location: 'Denver, CO',
        amount_raised: '$8M',
        people: 18,
        key_investors: [
          'Foundation Capital',
          'Allbirds',
          'Warby Parker',
          'Intercept Ventures',
          'Boomtown Accelerators',
          'Zelkova Ventures',
          'Rainfall Ventures',
          "Harry's",
          'Gradient Ventures',
        ],
        description: 'Data control center to aggregate data and manage business',
      },
      {
        name: 'Daasity',
        website: 'https://www.daasity.com/',
        segment: 'Business Analytics and Data',
        founders: [
          { name: 'Dan LeBlanc', link: 'https://www.linkedin.com/in/ddleblanc/' },
          { name: 'Sean Corson', link: 'https://www.linkedin.com/in/seancorson/' },
        ],
        stage: 'Series A',
        founded: '2017',
        hq_location: 'San Diego, CA',
        amount_raised: '$2M',
        people: 30,
        key_investors: [
          'Cove Fund',
          'Mooring Ventures',
          '1855 Capital Partners',
          'Okapi Venture Capital',
          'Serra Ventures',
        ],
        description: 'Analytics platform for D2C brands to do ETL and visualization',
      },
      {
        name: 'Peel Insights',
        website: 'https://www.peelinsights.com/',
        segment: 'Business Analytics and Data',
        founders: [
          { name: 'Nicolas Grasset', link: 'https://www.linkedin.com/in/nicolasgrasset/' },
          { name: 'Phillipe Casorla', link: 'https://www.linkedin.com/in/casorla/' },
          { name: 'Yasmin Nozari', link: 'https://www.linkedin.com/in/yasminnozari/' },
        ],
        stage: 'Seed',
        founded: '2019',
        hq_location: 'New York, NY',
        amount_raised: '$1M',
        people: 3,
        key_investors: ['Accomplice', 'Eniac'],
        description: 'Data warehouse, ETL and visualization for Shopify',
      },
      {
        name: 'Glew.io',
        website: 'https://glew.io/',
        segment: 'Business Analytics and Data',
        founders: [
          { name: 'Michael Vadini', link: 'https://www.linkedin.com/in/michael-vadini-8b94a110/' },
        ],
        stage: 'Series A',
        founded: '2015',
        hq_location: 'Charlotte, NC',
        amount_raised: '$0M',
        people: 33,
        key_investors: ['None -- bootstrapped'],
        description: 'Business intelligence designed for eCommerce',
      },
      {
        name: 'SoundCommerce',
        website: 'https://soundcommerce.com/',
        segment: 'Business Analytics and Data',
        founders: [
          { name: 'Eric Best', link: 'https://www.linkedin.com/in/ericbest/' },
          { name: 'Jared Stiff', link: 'https://www.linkedin.com/in/jaredstiff/' },
        ],
        stage: 'Series A',
        founded: '2018',
        hq_location: 'Seattle, WA',
        amount_raised: '$7M',
        people: 20,
        key_investors: [
          'Defy.vc',
          'Voyager Capital',
          'Alliance of Angels',
          'Stage Venture Partners',
        ],
        description: 'Mid-market to enterprise data platform',
      },
      {
        name: 'Xentral',
        website: 'https://xentral.com/',
        segment: 'Business Analytics and Data',
        founders: [
          { name: 'Claudia Sauter', link: 'https://www.linkedin.com/in/claudia-sauter-071242177/' },
          {
            name: 'Benedikt Sauter',
            link: 'https://www.linkedin.com/in/benedikt-sauter-b2a7b170/',
          },
        ],
        stage: 'Seed',
        founded: '2018',
        hq_location: 'Augsburg, Germany',
        amount_raised: '$0M',
        people: 27,
        key_investors: ['None -- bootstrapped'],
        description: 'ERP for eCommerce businesses',
      },
      {
        name: 'Rewind backups',
        website: 'https://rewind.io/',
        segment: 'Business Analytics and Data',
        founders: [{ name: 'Mike Potter', link: 'https://www.linkedin.com/in/mikepotter/' }],
        stage: 'Series A',
        founded: '2015',
        hq_location: 'Ottawa, Canada',
        amount_raised: '$0M',
        people: 33,
        key_investors: ['None -- bootstrapped'],
        description: 'Data backup for eCommerce and apps',
      },
    ],
  },
  {
    id: 6,
    section_title: 'Sourcing & procurement',
    tooltip: 'marketplaces and software for merchants to source products across geographies',
    rows: [
      {
        name: 'Gembah',
        website: 'https://gembah.com/',
        segment: 'Sourcing and Procurement',
        founders: [
          { name: 'Zack Leonard', link: 'https://www.linkedin.com/in/zack-leonard-a2741938/' },
        ],
        stage: 'Seed',
        founded: '2018',
        hq_location: 'Austin, TX',
        amount_raised: '$3.3M',
        people: 19,
        key_investors: ['Silverton Partners', 'ATX Venture Partners', 'Capital Factory'],
        description: 'Product design and sourcing supplier network',
      },
      {
        name: 'Sourcify',
        website: 'https://www.sourcify.com/',
        segment: 'Sourcing and Procurement',
        founders: [{ name: 'Nathan Resnick', link: 'https://www.linkedin.com/in/nathanresnick/' }],
        stage: 'Seed',
        founded: '2017',
        hq_location: 'San Diego, CA',
        amount_raised: '$0M',
        people: 'N/A',
        key_investors: [
          'Invariantes Fund',
          'FoundersX Ventures',
          '1984 Ventures',
          'Tech Coast Angels',
          'VentureSouq',
          'Y Combinator',
          'Right Side Capital Management',
          'EvoNexus',
        ],
        description: 'N/A',
      },
      {
        name: 'ZigZag',
        website: 'https://www.zigzag.global/',
        segment: 'Sourcing and Procurement',
        founders: [
          { name: 'Al Gerrie', link: 'https://www.linkedin.com/in/algerrie/' },
          { name: 'Laurence Guy', link: 'https://www.linkedin.com/in/laurence-guy-19675bb/' },
        ],
        stage: 'Seed',
        founded: '2014',
        hq_location: 'London, England',
        amount_raised: '$0M',
        people: 'N/A',
        key_investors: [
          'CrowdCube',
          'Dolphin Finance Corporation',
          'Ascension Ventures',
          'Circularity Capital',
          'Plug and Play',
          'Maersk Growth',
        ],
        description: 'N/A',
      },
    ],
  },
  {
    id: 7,
    section_title: 'Website optimization + personalization',
    tooltip: 'software for merchants to optimize and to customize customer experience',
    rows: [
      {
        name: 'Nosto',
        website: 'https://www.nosto.com/',
        segment: 'Website optimization + personalization',
        founders: [
          { name: 'Antti Poyhonen', link: 'https://www.linkedin.com/in/anttiapoyhonen/' },
          { name: 'Jani Luostarinen', link: 'https://www.linkedin.com/in/janiluostarinen/' },
          { name: 'Juha Valvanne', link: 'https://www.linkedin.com/in/juhavalvanne/' },
        ],
        stage: 'Series B + Growth',
        founded: '2013',
        hq_location: 'Helsinki, Finland',
        amount_raised: '$15M',
        people: 131,
        key_investors: ['Wellington Partners', 'Tekes'],
        description: 'Personalization on-site for segmentation and insights',
      },
      {
        name: 'Justuno',
        website: 'https://www.justuno.com/',
        segment: 'Website optimization + personalization',
        founders: [
          { name: 'Erik Christiansen', link: 'https://www.linkedin.com/in/erikc/' },
          { name: 'Travis Logan', link: 'https://www.linkedin.com/in/travis-logan-a2a93b3/' },
        ],
        stage: 'Series A',
        founded: '2011',
        hq_location: 'San Francisco, CA',
        amount_raised: '$0M',
        people: 43,
        key_investors: ['None -- bootstrapped'],
        description: 'Conversion suite with on-site personalization and pop ups',
      },
      {
        name: 'Intellimize',
        website: 'https://www.intellimize.com/',
        segment: 'Website optimization + personalization',
        founders: [
          { name: 'Brian Webb', link: 'https://www.linkedin.com/in/bwebb/' },
          { name: 'Guy Yalif', link: 'https://www.linkedin.com/in/gyalif/' },
          { name: 'Jin Lim', link: 'https://www.linkedin.com/in/jinclim/' },
        ],
        stage: 'Series A',
        founded: '2016',
        hq_location: 'San Mateo, CA',
        amount_raised: '$10M',
        people: 33,
        key_investors: ['Amplify Partners', 'Homebrew', 'Precursor'],
        description: 'Automatically optimizes websites for customer acquisition',
      },
      {
        name: 'Proof',
        website: 'https://useproof.com/',
        segment: 'Website optimization + personalization',
        founders: [
          { name: 'Chris Hull', link: 'https://www.linkedin.com/in/chrishull7/' },
          { name: 'Dave Rogenmoser', link: 'https://www.linkedin.com/in/daverogenmoser/' },
          { name: 'John Philip Morgan', link: 'https://www.linkedin.com/in/jpamorgan/' },
        ],
        stage: 'Series A',
        founded: '2017',
        hq_location: 'Austin, TX',
        amount_raised: '$2M',
        people: 21,
        key_investors: ['Y Combinator', 'Liquid2'],
        description: 'Relevant personalized websites for eCommerce',
      },
      {
        name: 'Yottaa',
        website: 'https://www.yottaa.com/',
        segment: 'Website optimization + personalization',
        founders: [{ name: 'Bob Buffone', link: 'https://www.linkedin.com/in/bbuffone/' }],
        stage: 'Series B + Growth',
        founded: '2010',
        hq_location: 'Boston, MA',
        amount_raised: '$64M',
        people: 83,
        key_investors: ['Intel Capital', 'GC', 'Stata'],
        description: 'Improve speed and reliability for eCommerce and optimize performance',
      },
      {
        name: 'Findify',
        website: 'https://www.findify.io/',
        segment: 'Website optimization + personalization',
        founders: [
          { name: 'Jaclyne Clarke', link: 'https://www.linkedin.com/in/jaclyneclarke/' },
          { name: 'Meni Morim', link: 'https://www.linkedin.com/in/mmorim/' },
          { name: 'Thibaut Patel', link: 'https://www.linkedin.com/in/thibautpatel/' },
          { name: 'Thomas Fattal', link: 'https://www.linkedin.com/in/thomasfattal/' },
        ],
        stage: 'Seed',
        founded: '2014',
        hq_location: 'Stockholm, Sweden',
        amount_raised: '$0M',
        people: 18,
        key_investors: ['None -- bootstrapped'],
        description: 'Sell more with personalization and ecommerce search + discovery',
      },
      {
        name: 'trbo',
        website: 'https://www.trbo.com/',
        segment: 'Website optimization + personalization',
        founders: [{ name: 'Felix Shirl', link: 'https://www.linkedin.com/in/fschirl/' }],
        stage: 'Series A',
        founded: '2016',
        hq_location: 'Munich, Germany',
        amount_raised: '$0M',
        people: 31,
        key_investors: ['None -- bootstrapped'],
        description: 'Dynamic personalization and A/B testing',
      },
      {
        name: 'Sleeknote',
        website: 'https://sleeknote.com/',
        segment: 'Website optimization + personalization',
        founders: [
          { name: 'Asger Eriksen', link: 'https://www.linkedin.com/in/asger-eriksen-95670479/' },
        ],
        stage: 'Seed',
        founded: '2014',
        hq_location: 'Aarhus, Denmark',
        amount_raised: '$1M',
        people: 33,
        key_investors: ['Friends and family'],
        description: 'Engage visitors with on-site messages',
      },
    ],
  },
  {
    id: 8,
    section_title: 'Loyalty and Referrals',
    tooltip:
      'software for merchants to drive repeat purchasing behavior with loyalty programs and coupons',
    rows: [
      {
        name: 'Loyaltylion',
        website: 'https://loyaltylion.com/',
        segment: 'Loyalty and Referrals',
        founders: [
          { name: 'Charlie Casey', link: 'https://www.linkedin.com/in/charliecasey/' },
          { name: 'Dave Clark', link: 'https://www.linkedin.com/in/clarkdavedotnet/' },
        ],
        stage: 'Series A',
        founded: '2012',
        hq_location: 'London, England',
        amount_raised: '$0M',
        people: 43,
        key_investors: ['None -- bootstrapped'],
        description: 'Leading loyalty and customer engagement tool',
      },
      {
        name: 'Smile.io',
        website: 'https://smile.io/',
        segment: 'Loyalty and Referrals',
        founders: [
          { name: 'Bill Curtis', link: 'https://www.linkedin.com/in/billcurt/' },
          { name: 'Jay El-Kaake', link: 'https://www.linkedin.com/in/jayelkaake/' },
          { name: 'Mike Rossie', link: 'https://www.linkedin.com/in/mikerossiii/' },
          { name: 'Mohsen Hadianfard', link: 'https://www.linkedin.com/in/mhadianfard/' },
          { name: 'Steve Deckert', link: 'https://www.linkedin.com/in/steve-deckert-913a2a31/' },
        ],
        stage: 'Series A',
        founded: '2012',
        hq_location: 'Waterloo, Canada',
        amount_raised: '$0M',
        people: 56,
        key_investors: ['None -- bootstrapped'],
        description: 'Loyalty programs to encourage repeat customer behavior',
      },
      {
        name: 'Rise.ai',
        website: 'https://rise.ai/',
        segment: 'Loyalty and Referrals',
        founders: [
          { name: 'Yair Miron', link: 'https://www.linkedin.com/in/yair-miron-32aa162a/' },
        ],
        stage: 'Seed',
        founded: '2015',
        hq_location: 'Tel Aviv, Israel',
        amount_raised: '$0M',
        people: 18,
        key_investors: ['None -- bootstrapped'],
        description: 'Gift card and store credit cards for online brands',
      },
    ],
  },
  {
    id: 9,
    section_title: 'Payments and fintech',
    tooltip:
      'fraud prevention, subscription and other financial products for merchants to provide more flexible financial offerings for customers',
    rows: [
      {
        name: 'Ns8',
        website: 'https://www.ns8.com/en-us',
        segment: 'Payments and Fintech',
        founders: [
          { name: 'Adam Rogas', link: 'https://www.linkedin.com/in/adamrogas/' },
          { name: 'David Hansen', link: 'https://www.linkedin.com/in/dhansen-in/' },
          { name: 'Eric Kay', link: 'https://www.linkedin.com/in/erickaylaw/' },
          { name: 'Paul Korol' },
        ],
        stage: 'Series B + Growth',
        founded: '2016',
        hq_location: 'Las Vegas, NV',
        amount_raised: '$158M',
        people: 218,
        key_investors: ['Lightspeed', 'Edison Partners', 'Axa', 'Sorenson'],
        description: 'Payments and fraud prevention SaaS for eCommerce',
      },
      {
        name: 'Recharge',
        website: 'https://rechargepayments.com/about',
        segment: 'Payments and Fintech',
        founders: [{ name: "Oisin O'Connor", link: 'https://www.linkedin.com/in/oisino/' }],
        stage: 'Series B + Growth',
        founded: '2015',
        hq_location: 'Santa Monica, CA',
        amount_raised: '$0M',
        people: 171,
        key_investors: ['None -- bootstrapped'],
        description: 'Subscription payments service for Shopify',
      },
      {
        name: 'Chargebee',
        website: 'https://www.chargebee.com/',
        segment: 'Payments and Fintech',
        founders: [
          { name: 'KP Saravanan', link: 'https://www.linkedin.com/in/saravanan-kp-09792838/' },
          { name: 'Krish Subramanian', link: 'https://www.linkedin.com/in/krishs/' },
        ],
        stage: 'Series B + Growth',
        founded: '2011',
        hq_location: 'San Francisco, CA',
        amount_raised: '$38M',
        people: 408,
        key_investors: ['Insight', 'Tiger', 'Accel', 'Steadview'],
        description: 'Subscription payments service',
      },
      {
        name: 'Bolt',
        website: 'https://www.bolt.com/',
        segment: 'Payments and Fintech',
        founders: [
          { name: 'Eric Feldman', link: 'https://www.linkedin.com/in/ericfeldman44/' },
          { name: 'Ryan Breslow', link: 'https://www.linkedin.com/in/rbres/' },
        ],
        stage: 'Series B + Growth',
        founded: '2014',
        hq_location: 'San Francisco, CA',
        amount_raised: '$140M',
        people: 197,
        key_investors: ['Long Venture Partners', 'Activant', 'WestCap'],
        description: 'developer of an online payment platform used to drive eCommerce',
      },
      {
        name: 'Namogoo',
        website: 'https://www.namogoo.com/',
        segment: 'Payments and Fintech',
        founders: [
          { name: 'Chemi Katz', link: 'https://www.linkedin.com/in/chemikatz/' },
          { name: 'Ohad Greenshphan', link: 'https://www.linkedin.com/in/ohadgreenshpan/' },
        ],
        stage: 'Series B + Growth',
        founded: '2019',
        hq_location: 'Herzliya, Israel',
        amount_raised: '$69M',
        people: 108,
        key_investors: ['Oak HC/FT', 'Hanaco', 'GreatPoint Ventures'],
        description: 'SaaS to prevent customer hijacking in eComm',
      },
    ],
  },
  {
    id: 10,
    section_title: 'Insurance',
    tooltip:
      'financial products that insure package delivery and other supply chain aspects so customers have Amazon like guarantees',
    rows: [
      {
        name: 'Route',
        website: 'https://go.route.com/demo',
        segment: 'Insurance',
        founders: [
          { name: 'Evan Walker', link: 'https://www.linkedin.com/in/evanwalker/' },
          { name: 'Mike Moreno', link: 'https://www.linkedin.com/in/mike-moreno-55732742/' },
        ],
        stage: 'Series B + Growth',
        founded: '2018',
        hq_location: 'Lehi, UT',
        amount_raised: '$12M',
        people: 228,
        key_investors: ['Album VC', 'Pattern'],
        description: 'Insurance for package delivery for eComm',
      },
      {
        name: 'Extend',
        website: 'https://extend.com/',
        segment: 'Insurance',
        founders: [
          { name: 'Joe Moss', link: 'https://www.linkedin.com/in/joegmoss/' },
          { name: 'Michael Darmousseh', link: 'https://www.linkedin.com/in/michaeldarmousseh/' },
          { name: 'Rohan Shah', link: 'https://www.linkedin.com/in/rohanshah8/' },
          { name: 'Woodrow Levin', link: 'https://www.linkedin.com/in/woodrowlevin/' },
        ],
        stage: 'Series B + Growth',
        founded: '2019',
        hq_location: 'San Francisco, CA',
        amount_raised: '$17M',
        people: 65,
        key_investors: ['GreatPoint Ventures', 'Michael Arrieta'],
        description: 'Extended warranties for eCommerce',
      },
      {
        name: 'Clyde',
        website: 'https://www.joinclyde.com/',
        segment: 'Insurance',
        founders: [
          { name: 'Brandon Gell', link: 'https://www.linkedin.com/in/brandongell/' },
          { name: 'Josh Scaglione', link: 'https://www.linkedin.com/in/josh-scaglione-18885a7a/' },
          { name: 'Nick Scaglione', link: 'https://www.linkedin.com/in/nickscaglione1/' },
        ],
        stage: 'Series A',
        founded: '2017',
        hq_location: 'New York, NY',
        amount_raised: '$17M',
        people: 27,
        key_investors: ['Spark Capital', 'Red Sea Ventures', 'RRE'],
        description: 'SMB insurance for merchants',
      },
    ],
  },
  {
    id: 11,
    section_title: 'Supply Chain',
    tooltip:
      'software to better connect customers to local carriers and to track their orders throughout supply chain',
    rows: [
      {
        name: 'ZigZag',
        website: 'https://www.zigzag.global/',
        segment: 'Supply chain',
        founders: [
          { name: 'Al Gerrie', link: 'https://www.linkedin.com/in/algerrie/' },
          { name: 'Laurence Guy', link: 'https://www.linkedin.com/in/laurence-guy-19675bb/' },
        ],
        stage: 'Series A',
        founded: '2014',
        hq_location: 'London, England',
        amount_raised: '$1M',
        people: 54,
        key_investors: ['Maersk Growth', 'Circularity Capital'],
        description: 'Helps retailers manage returns',
      },
      {
        name: 'Loop Returns',
        website: 'https://loopreturns.com/',
        segment: 'Supply chain',
        founders: [
          { name: 'Brandon Schmidt', link: 'https://www.linkedin.com/in/brandon-schmidt-7579663/' },
          { name: 'Chris Pinchot', link: 'https://www.linkedin.com/in/chris-pinchot-72586163/' },
          { name: 'Jonathan Poma', link: 'https://www.linkedin.com/in/pomajp/' },
        ],
        stage: 'Series B + Growth',
        founded: '2017',
        hq_location: 'Great Lakes, MI',
        amount_raised: '$11M',
        people: 50,
        key_investors: ['Firstmark', 'Lerer Hippeau', 'Ridge Ventures'],
        description: 'Helps retailers manage returns',
      },
      {
        name: 'SendCloud',
        website: 'https://www.sendcloud.com/',
        segment: 'Supply chain',
        founders: [
          { name: 'Bas Smeulders', link: 'https://www.linkedin.com/in/bassmeulders/' },
          { name: 'Rob van den Heuvel', link: 'https://www.linkedin.com/in/robvandenheuvel92/' },
        ],
        stage: 'Series B + Growth',
        founded: '2012',
        hq_location: 'The Netherlands',
        amount_raised: '$8M',
        people: 190,
        key_investors: ['Bom Bradant', 'HenQ', 'TIIN'],
        description: 'Shipping as an API',
      },
      {
        name: 'EasyPost',
        website: 'https://www.easypost.com/',
        segment: 'Supply chain',
        founders: [
          { name: 'Jarrett Streebin', link: 'https://www.linkedin.com/in/jstreebin/' },
          { name: 'Jon Calhoun' },
        ],
        stage: 'Series B + Growth',
        founded: '2012',
        hq_location: 'San Francisco, CA',
        amount_raised: '$12M',
        people: 190,
        key_investors: ['Initialized Capital', 'Y Combinator'],
        description: 'Shipping as an API',
      },
      {
        name: 'Anvyl',
        website: 'https://anvyl.com/',
        segment: 'Supply chain',
        founders: [{ name: 'Rodney Manzo', link: 'https://www.linkedin.com/in/rodneymanzo/' }],
        stage: 'Series A',
        founded: '2017',
        hq_location: 'New York, NY',
        amount_raised: '$11M',
        people: 50,
        key_investors: ['Redpoint', 'First Round', 'Grand Central Tech'],
        description: 'Supply chain tracking for eCommerce',
      },
      {
        name: 'Spocket',
        website: 'https://www.spocket.co/',
        segment: 'Supply chain',
        founders: [
          { name: 'Saba Mohebpour', link: 'https://www.linkedin.com/in/saba-mohebpour/' },
          { name: 'Tom Hansen', link: 'https://www.linkedin.com/in/tom-hansen-0b998ab2/' },
        ],
        stage: 'Series A',
        founded: '2017',
        hq_location: 'Vancouver, Canada',
        amount_raised: '$2M',
        people: 35,
        key_investors: ['Panache', 'Techstars'],
        description: 'Dropshipping marketplace',
      },
      {
        name: 'Oberlo',
        website: 'https://www.oberlo.com/',
        segment: 'Supply chain',
        founders: [
          { name: 'Andrius Slimas', link: 'https://www.linkedin.com/in/slimas/' },
          {
            name: 'Donatas Pranckenas',
            link: 'https://www.linkedin.com/in/donatas-pranckenas-322683139/',
          },
          {
            name: 'Justas Galaburda',
            link: 'https://www.linkedin.com/in/justas-galaburda-82882056/',
          },
          { name: 'Marius Grauzinis' },
          { name: 'Tomas Slimas' },
        ],
        stage: 'Series A',
        founded: '2015',
        hq_location: 'Lithuania',
        amount_raised: '$0M',
        people: 57,
        key_investors: ['None -- bootstrapped'],
        description: 'Dropshipping marketplace',
      },
      {
        name: 'Fenix Commerce',
        website: 'https://fenixcommerce.com/',
        segment: 'Supply chain',
        founders: [
          { name: 'Akhilesh Srivastava', link: 'https://www.linkedin.com/in/akhilesh-srivastava/' },
        ],
        stage: 'Series A',
        founded: '2017',
        hq_location: 'San Jose, CA',
        amount_raised: '$0M',
        people: 19,
        key_investors: ['None -- bootstrapped'],
        description: 'White-label supply chain tracking for eCommerce merchants',
      },
      {
        name: 'Malomo',
        website: 'https://gomalomo.com/',
        segment: 'Supply chain',
        founders: [
          { name: 'Yaw Aning', link: 'https://www.linkedin.com/in/yawaning/' },
          { name: 'Anthony Smith', link: 'https://www.linkedin.com/in/anthonyistotallyrad/' },
        ],
        stage: 'Seed',
        founded: '2018',
        hq_location: 'London, England',
        amount_raised: '$3M',
        people: 13,
        key_investors: ['Base10', 'Harlem', 'Hyde Park', 'High Alpha'],
        description: 'White-label supply chain tracking for eCommerce merchants',
      },
      {
        name: 'Happy Returns',
        website: 'https://www.happyreturns.com/',
        segment: 'Supply chain',
        founders: [
          { name: 'David Sobie', link: 'https://www.linkedin.com/in/david-sobie-a37ab/' },
          { name: 'Mark Geller', link: 'https://www.linkedin.com/in/markgeller/' },
        ],
        stage: 'Series B + Growth',
        founded: '2015',
        hq_location: 'Los Angeles, CA',
        amount_raised: '$25M',
        people: 68,
        key_investors: ['Paypal', 'USVP', 'Upfront'],
        description: 'Returns solution for eCommerce and omnichannel retailers',
      },
    ],
  },
];

const totalNumberOfCompanies = 68;

const amountOfFunding = [
  {
    year: 2014,
    amount: 24.5,
  },
  {
    year: 2015,
    amount: 62.9,
  },
  {
    year: 2016,
    amount: 63.6,
  },
  {
    year: 2017,
    amount: 125.7,
  },
  {
    year: 2018,
    amount: 98.6,
  },
  {
    year: 2019,
    amount: 467.8,
  },
  {
    year: 2020,
    amount: 381.9,
  },
];

const cumulativeRaised = [
  {
    year: 2014,
    amount: 24.5,
  },
  {
    year: 2015,
    amount: 87.3,
  },
  {
    year: 2016,
    amount: 150.9,
  },
  {
    year: 2017,
    amount: 276.6,
  },
  {
    year: 2018,
    amount: 375.2,
  },
  {
    year: 2019,
    amount: 843,
  },
  {
    year: 2020,
    amount: 1224.9,
  },
];

const numberOfUnicorns = [
  {
    year: 2014,
    number: 0,
    companies: [],
  },
  {
    year: 2015,
    number: 0,
    companies: [],
  },
  {
    year: 2016,
    number: 0,
    companies: [],
  },
  {
    year: 2017,
    number: 0,
    companies: [],
  },
  {
    year: 2018,
    number: 0,
    companies: [],
  },
  {
    year: 2019,
    number: 1,
    companies: ['Klaviyo'],
    label: '1*',
  },
  {
    year: 2020,
    number: 3,
    companies: ['Klaviyo', 'Attentive', 'NS8'],
    label: '3*',
  },
];

interface IMapItem {
  id: number;
  name: string;
  logo_url: string;
  lat: number;
  long: number;
}

const companiesMap: IMapItem[] = [
  {
    id: 1,
    name: 'Shipwell',
    logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Color-Logo.png',
    lat: 43.720296,
    long: -96.679688,
  },
  {
    id: 2,
    name: 'Made',
    logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Made-Renovation-Logo-.png',
    lat: 25.973845,
    long: -99.84375,
  },
  {
    id: 3,
    name: 'Roadsync',
    logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/Color-Logo.png',
    lat: -1.915658,
    long: -66.445313,
  },
  {
    id: 4,
    name: 'Pill',
    logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/cottage-technologies-logo.png',
    lat: 17.375788,
    long: 9.84375,
  },
  {
    id: 5,
    name: 'Virtual Kitchen',
    logo_url: 'https://archive.base10.vc/wp-content/uploads/2020/06/cottage-technologies-logo.png',
    lat: 53.862892,
    long: 34.804688,
  },
];

export {
  mapCompanyImages,
  keyInsightsList,
  circleNumbers,
  mostActiveInvestors,
  topCompaniesInvestors,
  researchCompanies,
  totalNumberOfCompanies,
  amountOfFunding,
  numberOfUnicorns,
  companiesMap,
  cumulativeRaised,
};
