import * as React from 'react';
import styled from 'styled-components';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import theme from '../../../util/theme';
import {
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryLine,
  VictoryLabel,
  VictoryAxis,
  VictoryArea,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryScatter,
  VictoryGroup,
} from 'victory';
import { StaticCompaniesMapSegment } from './StaticCompaniesMap';
import { UnicornsToolTip } from './UnicornsToolTip';
import { AppText } from '../../ui';
import { laptopSize, mobileSize, desktopSize } from '../../../util/variables';
import { mapCompanyImages } from './insightsData';
import { FourPlacesMapComponent } from '../research3/FourPlacesMap';
import { rgba } from 'polished';
import backgroundImg from '../../../content/img/research/opportunity-background.png';
import newBackgroundImg from '../../../content/img/research/Background.png';
import ReactTooltip from 'react-tooltip';
import { Link } from 'gatsby';

interface IGraphsComponentProps {
  data: any[];
}

const OpportunityGraph: React.FunctionComponent<IGraphsComponentProps> = React.memo(({ data }) => {
  const returnColorFromValues = (x: number, y: number) => {
    if (x < 0.5) {
      return theme.GREY_500;
    } else if (y <= 0.5) {
      return '#BDE2F8';
    } else {
      // return '#1792DA';
      return '#61BAEE';
      // } else {
      //   return theme.GREY_500;
    }
  };

  return (
    <FullContainer>
      <ReactTooltip
        multiline
        // place="top"
        effect="solid"
        css={{
          maxWidth: 300,
          lineHeight: 1.4,
          textAlign: 'center',
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor="rgba(0, 0, 0, 0.9)"
      />
      {/* <FlexBetweenDivXTop> */}
      <GraphTitle>
        Real Economy Opportunity Score
        {/* <SpanContainer
          style={{ marginTop: '4px' }}
          data-tip="Measures a trend’s potential for venture investment relative to the other Real Economy trends identified by Base10. Trends that are early in their development and experiencing accelerating growth will score higher."
        >
          <AiOutlineInfoCircle color={theme.NEW_BLACK_COLOR} fontSize="16px" />
        </SpanContainer> */}
      </GraphTitle>
      <GraphSubtitle>
        Real economy opportunity score assesses a trend’s potential to produce unique venture
        investments by comparing a trend’s hiring and funding speed with it’s maturity.{' '}
        {/* <span style={{ fontWeight: 500 }}> */}
        <b>
          The below matrix charts the top quartile - 25% - of trends based on real economy score
        </b>
        {/* </span> */}
      </GraphSubtitle>
      {/* </FlexBetweenDivXTop> */}
      {/* <FlexBetweenDivXTop>
        <YAxisLabel style={{ color: '#96D1F4' }}>Moderate</YAxisLabel>
        <YAxisLabel style={{ color: '#45AEEB' }}>Good</YAxisLabel>
        <YAxisLabel style={{ color: '#116A9E' }}>Best</YAxisLabel>
      </FlexBetweenDivXTop> */}
      <Container>
        {!!data.length && (
          <>
            <YAxisDiv>
              <AxisLabelText>
                Faster Hiring & Funding{' '}
                <SpanContainer
                  style={{ marginTop: '2px' }}
                  data-tip="Measures a trend’s rate of growth relative to other Real Economy trends identified by Base10. The faster startups in the trend hire new employees or raise new capital, the higher this metric will be."
                >
                  <AiOutlineInfoCircle color={theme.NEW_BLACK_COLOR} fontSize="13px" />
                </SpanContainer>
              </AxisLabelText>
              {/* <FlexBetweenDiv>
                <YAxisLabel>Fast</YAxisLabel>
                <YAxisLabel>Moderate</YAxisLabel>
                <YAxisLabel>Slow</YAxisLabel>
              </FlexBetweenDiv> */}

              <ArrowDivTop />
            </YAxisDiv>
            <div>
              <BackgroundImage>
                {/* <PaddingPoints> */}
                <RelDiv>
                  {data.map((item: any) => (
                    <Link key={item.dive_slug} to={`/research/${item.dive_slug}`}>
                      <DataPoint
                        data-tip={
                          item.dive_name === 'Global Fintech: Africa'
                            ? 'Global Fintech'
                            : `${item.dive_name}`
                        }
                        color={returnColorFromValues(
                          1 - item.maturity_benchmark,
                          item.dive_hiring_and_funding_growth_score,
                        )}
                        left={Number(item.maturity_benchmark) * 100}
                        bottom={Number(item.dive_hiring_and_funding_growth_score) * 100}
                      />
                    </Link>
                  ))}
                </RelDiv>
                {/* </PaddingPoints> */}
              </BackgroundImage>
              {/* <FlexBetweenDivX>
                <YAxisLabel>Growth</YAxisLabel>
                <YAxisLabel>Mid</YAxisLabel>
                <YAxisLabel>Early</YAxisLabel>
              </FlexBetweenDivX> */}
              <NewXAxisDiv>
                <ArrowDivRight />
                <AxisLabelText>
                  More Early Stage Opportunities{' '}
                  <SpanContainer
                    style={{ marginTop: '1px' }}
                    data-tip="Measures a trend’s development stage relative to other Real Economy trends identified by Base10. This metric incorporates funding stage, number of exits, capital per company, and company size to determine the trend’s overall maturity."
                  >
                    <AiOutlineInfoCircle color={theme.NEW_BLACK_COLOR} fontSize="13px" />
                  </SpanContainer>
                </AxisLabelText>
              </NewXAxisDiv>
            </div>
          </>
        )}
      </Container>
    </FullContainer>
  );
});

const RelDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const NewXAxisDiv = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 100px;
  grid-gap: 30px;
  align-items: center;
`;

const ArrowDivRight = styled.div`
  height: 4px;
  width: 100%;
  background-color: ${theme.GREY_500};
  position: relative;
  ::after {
    position: absolute;
    display: block;
    content: '';
    right: -6px;
    bottom: -6px;
    width: 0px;
    height: 0px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 12px solid ${theme.GREY_500};
  }
`;

const ArrowDivTop = styled(ArrowDivRight)`
  margin-top: 20px;
  width: 4px;
  height: 100%;
  ::after {
    right: -6px;
    top: -16px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 12px solid ${theme.GREY_500};
  }
`;

const GraphSubtitle = styled(AppText)`
  color: ${theme.GREY_700};
  font-size: 17px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  margin-bottom: 40px;
  @media ${mobileSize} {
    font-size: 13px;
  }
`;

const GraphTitle = styled(AppText)`
  /* font-size: 22px;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: ${theme.NEW_BLACK_COLOR};
  text-align: center;
  margin-top: 32px;
  font-weight: 400;
  width: 100%;
  @media ${mobileSize} {
    font-size: 16px;
    line-height: 1.5;
  } */
  font-size: 28px;
  line-height: 39px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: ${theme.NEW_BLACK_COLOR};
  /* width: max-content; */
  margin-top: 64px;
  margin-bottom: 8px;
  /* white-space: nowrap; */
  @media ${mobileSize} {
    font-size: 20px;
    line-height: 30px;
    margin-right: 12px;
  }
`;

const FullContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexBetweenDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 360px;
  justify-content: space-between;
  align-items: flex-end;
  padding: 24px;
  @media ${laptopSize} {
    height: 60vw;
  }
  @media ${mobileSize} {
    display: none;
  }
`;

const FlexBetweenDivX = styled.div`
  display: flex;
  max-width: 100%;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 0px;
  margin: 0px;
  @media ${mobileSize} {
    padding: 10px;
    padding-bottom: 0px;
  }
`;

const FlexBetweenDivXTop = styled(FlexBetweenDivX)`
  margin-left: 200px;
  @media ${mobileSize} {
    margin-left: 60px;
  }
`;

const YAxisDiv = styled.div`
  display: grid;
  grid-template-rows: 30px 1fr;
  justify-items: center;
  grid-gap: 10px;
`;

const YAxisLabel = styled(AppText)`
  font-size: 16px;
  line-height: 24px;
  color: ${theme.GREY_700};
  @media ${mobileSize} {
    font-size: 10px;
    line-height: 15px;
  }
`;

const AxisLabelText = styled(AppText)`
  color: ${theme.NEW_BLACK_COLOR};
  font-size: 12px;
  line-height: 1.5;
  font-weight: 600;
  width: 100%;
  text-align: center;
  @media ${mobileSize} {
    font-size: 10px;
    /* line-height: 18px; */
  }
`;

interface DataPointProps {
  bottom: number;
  left: number;
  color: string;
}

const DataPoint = styled.div<DataPointProps>`
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 1px solid ${theme.BLACK_COLOR};
  bottom: calc(${props => props.bottom}% - 12px);
  right: calc(${props => props.left}% - 12px);
  background: ${props => props.color};
  cursor: pointer;
  @media ${mobileSize} {
    height: 14px;
    width: 14px;
    border: 1px solid ${theme.BLACK_COLOR};
  }
`;

interface BackgroundProp {
  backgroundImg: any;
}

const PaddingDiv = styled.div`
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  padding: 12px;
  position: relative;
`;

const PaddingPoints = styled.div`
  margin: 10px;
  position: relative;
`;

const BackgroundImage = styled.div`
  background: ${`url(${newBackgroundImg})`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  padding: 40px;
  position: relative;
  /* border-radius: 23px; */
`;

const Container = styled.div`
  display: grid;
  /* grid-template-columns: 170px 1fr; */
  grid-template-columns: 100px 1fr;
  width: 100%;
  min-height: 343.5px;
  margin: 24px auto;
  margin-bottom: 100px;
  @media ${laptopSize} {
    margin-bottom: 200px;
    width: 100%;
    min-height: 45vw;
    height: 45vw;
  }
  @media ${mobileSize} {
    grid-template-columns: 60px 1fr;
    margin-bottom: 80px;
    width: 100%;
    height: 45vw;
  }
`;

const AmountOfFundingChartDiv = styled.div`
  margin: 0;
  padding: 0;
  margin-top: -30px;
  width: 500px;
  @media ${laptopSize} {
    margin-top: -40px;
    max-width: 500px;
    margin: auto;
  }
  @media ${mobileSize} {
    max-width: 90vw;
    overflow: auto;
    width: 100%;
    justify-content: center;
  }
`;

const ArrowDataBox = styled.div`
  flex: 1;
  min-width: 100px;
`;

interface LastItem {
  lastItem?: boolean;
}

const TooltipText = styled(AppText)`
  display: inline-block;
  text-align: center;
  max-width: 316px;
  padding: 20px;
`;

const ArrowBoxDataContainer = styled.div<LastItem>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 82px;
  margin-left: -10px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: -10px;
  position: relative;
  border-radius: ${props => (props.lastItem ? '4px' : '0px')};
  background-image: linear-gradient(
    to left,
    rgba(69, 174, 235, 0.2),
    rgba(69, 174, 235, 0.2) 36%,
    rgba(255, 255, 255, 0) 100%
  );
  :after,
  :before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  :after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: rgba(69, 174, 235, 0.1);
    border-width: ${props => (props.lastItem ? '40px 0 40px 0px' : '40px 0 40px 20px')};
    margin-top: -40px;
    position: absolute;
  }
  :before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: rgba(69, 174, 235, 0.1);
    border-width: ${props => (props.lastItem ? '40px 0 40px 0px' : '40px 0 40px 20px')};
    margin-top: -41px;
    position: absolute;
  }
`;

const ArrowBoxLabel = styled(AppText)`
  max-width: 70px;
  opacity: 0.6;
  font-family: ${theme.SECONDARY_FONT};
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.67px;
  text-align: center;
  vertical-align: text-bottom;
  color: ${theme.BLACK_COLOR};
`;

const ArrowBoxNumber = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.BLACK_COLOR};
`;

const ArrowBoxesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-right: 30px;
  flex-wrap: nowrap;
  @media ${laptopSize} {
    justify-content: center;
  }
  @media ${mobileSize} {
    flex-wrap: wrap;
  }
`;

const MapDiv = styled.div`
  /* height: 500px;
  width: 500px; */
  position: relative;
  max-width: 90vw;
  max-height: 350px;
  min-height: 280px;
  margin: 20px;
  z-index: 1;
  padding: 0;
  @media ${desktopSize} {
    max-width: 50vw;
  }
  @media ${laptopSize} {
    min-height: auto;
    margin: 0;
    max-width: 90vw;
  }
  @media ${mobileSize} {
    margin: 0;
    max-width: 90vw;
    margin-bottom: 20px;
  }
`;

const FilterHeadingLabel = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-right: 14px;
  margin: 0;
  padding: 0;
`;

const SpanContainer = styled.span`
  margin: 0px;
  padding: 0px;
  line-height: 1;
  position: relative;
  top: 0;
  right: 0;
  vertical-align: top;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
`;

export { OpportunityGraph };
