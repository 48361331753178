import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { laptopSize, desktopSize, mobileSize } from '../../../util/variables';
import theme from '../../../util/theme';
import { AnimatedDiv, AppText } from '../../ui';

const OurTeamSegment: React.FC = () => {
  return (
    <OurTeamContent>
      <OurTeamh2>OUR TEAM</OurTeamh2>
      <OurTeamTitle>Founders, Investors, and Researchers</OurTeamTitle>
      <FlexDiv>
        <div>
          <OurTeamSubTitle>Our Team</OurTeamSubTitle>
          <TeamText>
            We are founders who have built companies worth over $3B in combined value.&nbsp;
          </TeamText>

          <TeamText>We are investors that have realized $3B+ in returns.&nbsp;</TeamText>
          <TeamText>
            We are researchers that believe following the data leads to better decisions.
          </TeamText>
        </div>
        <ValuesContainer>
          <OurTeamSubTitle>Our Values</OurTeamSubTitle>
          <TeamText>Be humble.&nbsp;</TeamText>
          <TeamText>Work hard.&nbsp;</TeamText>
          <TeamText>Serve the entrepreneur.&nbsp;</TeamText>
          <TeamText>Serve our investors.</TeamText>
        </ValuesContainer>
      </FlexDiv>
    </OurTeamContent>
  );
};

const ValuesContainer = styled.div`
  width: 270px;
  padding-left: 20px;
  @media ${mobileSize} {
    width: 100%;
    padding-left: 0px;
    margin-top: 24px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${mobileSize} {
    flex-direction: column;
  }
`;

const TeamText = styled(AppText)`
  color: ${theme.NEW_BLACK_COLOR};
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  font-weight: 400;
  line-height: 2;
  display: block;
  @media ${mobileSize} {
    font-size: 16px;
    line-height: 23px;
    display: inline;
  }
`;

const OurTeamSubTitle = styled(AppText)`
  font-size: 28px;
  line-height: 39px;
  margin: 0px;
  margin-bottom: 16px;
  letter-spacing: 0.2px;
  color: ${theme.PRIMARY_COLOR};
  font-weight: 400;
  @media ${mobileSize} {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 30px;
  }
`;

const OurTeamTitle = styled(AppText)`
  font-size: 36px;
  line-height: 50px;
  letter-spacing: 0.2px;
  margin: 0px;
  margin-bottom: 48px;
  color: ${theme.NEW_BLACK_COLOR};
  font-weight: bold;
  @media ${mobileSize} {
    font-size: 26px;
    line-height: 32px;
  }
`;

const OurTeamContent = styled.div`
  width: 100%;
  max-width: 1125px;
  float: left;
  padding-bottom: 40px;
  @media ${desktopSize} {
    max-width: 965px;
  }
  @media ${mobileSize} {
    width: calc(100%);
    float: left;
    margin-left: 0px;
    padding-top: 0px;
    padding-bottom: 80px;
  }
`;

const OurTeamh2 = styled.h2`
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 400;
  color: ${theme.GREY_700};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  margin-bottom: 24px;
  margin-top: 53px;
  @media ${mobileSize} {
    margin-top: 18px;
    letter-spacing: 1px;
  }
`;

export { OurTeamSegment };
