import * as React from 'react';
import { FunctionComponent } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { laptopSize, desktopSize, mobileSize } from '../../../util/variables';
import { Modal, Wrapper, AppText, AnimatedSpan, AnimatedLinkAnchor } from '../../ui';
import theme from '../../../util/theme';
import Img from 'gatsby-image';
import trendMap from '../../../content/img/trend/trendMap.png';

interface TrendMapModalProps {
  showModal: boolean;
  toggleModal: () => void;
}

const TrendMapModal: FunctionComponent<TrendMapModalProps> = ({
  showModal = false,
  toggleModal,
}) => {
  return (
    <Modal
      background="transparent"
      backgroundColor="transparent"
      height="fit-content"
      width="fit-content"
      showModal={showModal}
      toggleModal={toggleModal}
      label="Trend Map"
    >
      <CloseModal onClick={toggleModal}>
        <Cross id="cross" />
      </CloseModal>
      <ImageDiv onClick={toggleModal}>
        <ImageContainer>
          <TrendImage src={trendMap} onClick={e => e.stopPropagation()} />
        </ImageContainer>
      </ImageDiv>
    </Modal>
  );
};

const TrendImage = styled.img`
  max-height: 90vh;
  max-width: 100vw;
`;

const ImageContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImageDiv = styled.div`
  display: block;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: rgba(26, 26, 26, 0.8);
`;

const CloseModal = styled.div`
  cursor: pointer;
  position: fixed;
  top: 1.875rem;
  right: 1.875rem;
  z-index: 1;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: ${theme.WHITE_COLOR};
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  border: 2px solid rgba(26, 26, 26, 0.5);
  :hover {
    #cross {
      opacity: 1;
    }
  }
  @media ${mobileSize} {
    top: 1.25rem;
    right: 1.25rem;
    height: 42px;
    width: 42px;
    border: 1px solid rgba(26, 26, 26, 0.5);
  }
`;

const Cross = styled.span`
  opacity: 0.7;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  :before {
    transform: rotate(45deg) translate(-50%, -50%);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.5px;
    width: 22px;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    background-color: ${theme.BLACK_COLOR};
  }
  :after {
    transform: rotate(-45deg) translate(-50%, -50%);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.5px;
    width: 22px;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    background-color: ${theme.BLACK_COLOR};
  }
  @media ${mobileSize} {
    opacity: 0.7;
    height: 1.25rem;
    width: 1.25rem;
    :before {
      height: 1px;
      width: 12px;
    }
    :after {
      height: 1px;
      width: 12px;
    }
  }
`;

export { TrendMapModal };
