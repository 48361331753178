import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { laptopSize, desktopSize, mobileSize } from '../../../util/variables';
import theme from '../../../util/theme';
import { valuesIn } from 'lodash';
import companiesData from './companiesData';
import { AnimatedLinkAnchor } from '../../ui/';

// TODO: Add the two links, get original pictures

const MakeCompaniesList: JSX.Element[] = () => {
  return companiesData.map(({ id, industry, image, description }) => {
    return (
      <WhiteCompanyBox key={id}>
        <WhiteBreak />
        <CompanyHeading>
          <img className="companyImage" src={image} />
          <h2>{industry}</h2>
        </CompanyHeading>
        {description.map(para => {
          return <p key={para}>{para}</p>;
        })}
      </WhiteCompanyBox>
    );
  });
};

const OurESGSegment: React.FC = () => {
  return (
    <>
      <OurESGContainer>
        <OurESGWrapper>
          <TitleWrapper>
            <OurESGh2 style={{ marginBottom: '20px' }}>Environmental, Social & Governance</OurESGh2>
            <ESGTitleHeading>
              Envir
              <CircleBot color="#3fc296" />
              nmental
            </ESGTitleHeading>
            <WrappingDiv>
              <ESGTitleHeading>
                S<CircleBot color={theme.PRIMARY_COLOR} />
                cial
              </ESGTitleHeading>
              <TitleWrappedText>
                Environmental, social, and governance principles are a key pillar of Base10’s core
                investment thesis of Automation for the Real Economy. While almost all of Base10’s
                portfolio is comprised of companies not more than five years old, a handful of our
                investments are already benefiting the world through their ESG and impact efforts.
              </TitleWrappedText>
            </WrappingDiv>
            <ESGTitleHeading>
              G<CircleBot color="#ffd300" />
              vernance
            </ESGTitleHeading>
            <TitleHiddenText>
              Environmental, social, and governance principles are a key pillar of Base10’s core
              investment thesis of Automation for the Real Economy. While almost all of Base10’s
              portfolio is comprised of companies not more than five years old, a handful of our
              investments are already benefiting the world through their ESG and impact efforts.
            </TitleHiddenText>
          </TitleWrapper>
          <OurESGh2 style={{ margin: '100px 0px 50px 0px' }}> Diversity Stats </OurESGh2>
          <DiversityStatsDiv>
            <DiversityStat>
              <Percentage>
                <h1>67</h1>
                <PercentageSign>%</PercentageSign>
              </Percentage>
              <p style={{ maxWidth: '260px' }}>of portfolio company founders are minorities.</p>
            </DiversityStat>
            <DiversityStat>
              <Percentage>
                <h1>27</h1>
                <PercentageSign>%</PercentageSign>
              </Percentage>
              <p style={{ maxWidth: '360px' }}>
                of portfolio company founders are underrepresented minorities.
              </p>
            </DiversityStat>
            <DiversityStat>
              <Percentage>
                <h1>64</h1>
                <PercentageSign>%</PercentageSign>
              </Percentage>
              <p style={{ maxWidth: '375px' }}>
                of US & Canada based portfolio company founders are first generation immigrants.
              </p>
            </DiversityStat>
          </DiversityStatsDiv>
          <Break />
          <IntroDiv>
            <IntroBlock>
              <p>
                Environmental, social, and governance principles are key pillars of Base10’s
                investment thesis of Automation for the Real Economy.
              </p>
              <p>
                However, since Base10 is an early stage investor - we typically invest in companies
                with 10-30 employees, only one office, and less than three years of operating
                history - it is difficult to systematically measure ESG of our portfolio companies
                by traditional methods.
              </p>
              <p>
                It is common that ESG measurement firms are focused on public or large private
                companies with hundreds or thousands of employees and multiple offices. These larger
                organizations have more readily available data for ESG measurement firms to analyze.
              </p>
            </IntroBlock>
            <IntroBlock>
              <p>
                While still early, we believe this approach is working. We have even incorporated an
                ESG screen into Base11, our automated sourcing tool, which flags a company that may
                be at risk for negative ESG characteristics.
              </p>
              <p>
                While still early, we believe this approach is working. We have even incorporated an
                ESG screen into Base11, our automated sourcing tool, which flags a company that may
                be at risk for negative ESG characteristics.
              </p>
            </IntroBlock>
          </IntroDiv>
          <SecondBlock>
            <OurESGh3>Base10 ESG and Impact Advisors and Partners</OurESGh3>
            <SecondBlockPara>
              We are also working with two of the most respected professionals in the field of ESG
              and Impact investing, Rebeca Minguela and Miguel Nogales, to help shape and improve
              our ESG and Impact efforts.
            </SecondBlockPara>
          </SecondBlock>
          <OurESGPeople>
            <OurESGPerson>
              <img
                src="https://giantbomb1.cbsistatic.com/uploads/square_medium/2/28122/1878180-joeroganweighins_display_image.jpg"
                alt=""
              />
              <h1>Rebeca Minguela</h1>
              <h2>Founder & CEO, Clarity.ai</h2>
              <p>
                Rebeca is the founder and CEO of Clarity.ai, which is revolutionizing the way
                investment firms evaluate ESG metrics of portfolio companies.
              </p>
              <p>
                Clarity.ai looks past traditional metrics and analyzes the relevance of the products
                and services of companies for society to clarify the real impact of the companies on
                society.
              </p>
              <p>
                Base10 is working with Rebeca and Clarity.ai to apply Clarity’s scoring framework to
                Base10 portfolio companies and incorporate its full scoring capabilities into
                Base11.
              </p>
            </OurESGPerson>
            <OurESGPerson>
              <img
                src="https://giantbomb1.cbsistatic.com/uploads/square_medium/2/28122/1878180-joeroganweighins_display_image.jpg"
                alt=""
              />
              <h1>Miguel Nogales</h1>
              <h2>Founding Partner & Co-CIO, Generation Investments</h2>
              <p>
                Miguel is a Founding Partner and Co-CIO of Generation Investments, former Vice
                President’s Al-Gore’s investment firm, which with $22B in AUM is the largest hedge
                fund in the world focused primarily on Impact investing.
              </p>
              <p>
                Miguel is an LP in Base10 and coach to our founders, particularly around the areas
                of building a culture focused on impact investing, sustainability and
                responsibility.
              </p>
            </OurESGPerson>
          </OurESGPeople>

          <LongTextBlock>
            <OurESGh3>ESG and Impact Investing Philosophy</OurESGh3>
            <p>
              We have researched how other early stage firms incorporate ESG into their investment
              process and worked with Rebeca and Miguel to strategically integrate ESG principles
              into Base10’s investment thesis. In the public launch announcement for Base10, Adeyemi
              Ajao, co-founder and Managing Partner, cited that Automation for the Real Economy only
              works when we combine it with responsible investing principles. Below are some of his
              notable points and principles about how responsible investing is core to our
              investment thesis. You can read his full note{'  '}
              <AnimatedLinkAnchor color={theme.WHITE_COLOR} fontSize="20px" to="#">
                here
              </AnimatedLinkAnchor>
              .
            </p>
            <ol>
              <li>
                We believe that humans come first. We believe that automation is here, is real and
                can be a force for good.
              </li>
              <li>We need the right kind of automation, led by the right kind of founder.</li>
              <li>
                We are not seeking founders that only see the world through a technology-first lens.
                We are looking for those that have in-depth knowledge about the problems their
                industries are facing. We are seeking founders who are authentic about serving their
                industry and helping to move it forward.
              </li>
              <li>
                Solve problems that are actually important for 99% of people: Eating, going to work,
                moving around the city, finding a place to live or getting rid of trash are really
                big deals for most of us. The next deep-learning algorithm is a really cool
                thing...but unlikely to have an immediate effect on the things that matter every
                day, such as food delivery, transportation, real estate or waste management.
              </li>
            </ol>
            <p>
              In other words, in order to help solve problems for the 99% we must focus our efforts
              on finding genuine founders whose companies operate in the Real Economy where they can
              actually impact the 99%.
            </p>
            <p>
              We also strive for both our investment team and portfolio founders to have a diversity
              of backgrounds and experience. Our founding partner Adeyemi Ajao, exemplifies this as
              Hispanic, black, and both an entrepreneur and investor. His unique background provides
              a rare perspective and has benefited him while working in Silicon Valley.
            </p>
            <p>
              We were honored to learn that Base10 was the first{' '}
              <AnimatedLinkAnchor color={theme.WHITE_COLOR} fontSize="20px" to="#">
                Black led VC fund to raise over $100 million for its debut fund
              </AnimatedLinkAnchor>
              . We take our role and influence seriously as we invest in ESG and Impact minded
              companies. Our investment processes and diversity of our team sets our portfolio
              companies on the path to be ESG and Impact leaders in the future.
            </p>
            <OurESGh3>Implementation</OurESGh3>
            <p>
              Using the philosophy outlined above to evaluate founders and companies automatically
              eliminates those who would otherwise overlook the environmental, social, or governance
              characteristics expected of their organization. If a founder’s values do not align
              with the benefit of public good in regards to ESG principles then it is not a founder
              or company we are going to partner with.
            </p>
            <p>
              Once we have vetted founders on their ideals and ensured their principles align with
              ours, we are able to dive into the details of how they are thinking about the ESG
              impact of their startups. Some of the topics we focus on include:
            </p>
            <ul>
              <li>
                How is your product beneficial to society? Do you benefit the environment or are you
                improving the lives for a certain demographic?
              </li>
              <li>
                We make sure each founder is working to build a diverse management team and we ask
                them how they plan to hire the right people to make this a reality. We then work
                with them to ensure they follow through on this by suggesting candidates we have
                already screened who would be a good fit.
              </li>
              <li>
                We ask each founder how they plan to build their organization to ensure strong HR
                controls and create an environment where every employee feels comfortable and can
                perform their best. If they need help, we work side by side to find the right HR
                person at an early stage who can implement these controls.
              </li>
              <li>
                We ask each founder how they can help positively impact the environment. Will they
                encourage employees to take public transportation or carpool? Will they be a
                paperless office? If their products or services emit waste, what are they
                considering now to ensure they minimize and recycle this waste for positive
                environmental use? How can we help them accomplish this?
              </li>
            </ul>
            <p>
              Even though we are investing at such an early stage, these are the questions that are
              vital to ask and implement. We have a unique opportunity to shape the mindset and
              values of companies as they grow and better prepare them for when they are properly
              scored by ESG firms. We are confident they will be in the 99% percentile of ESG scores
              when that time comes.
            </p>
            <p>
              A handful of our investments are already benefiting the world in quantifiable ways
              through ESG principles and we are working with the rest to ensure they are putting the
              proper tools and protocols in place from an early stage to be leaders in ESG as they
              grow into mature companies.
            </p>
          </LongTextBlock>
        </OurESGWrapper>
      </OurESGContainer>
      <MakeCompaniesList />
    </>
  );
};

const CompanyHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  img {
    margin-bottom: 35px;
    max-width: 200px;
    max-height: 100px;
  }
  h2 {
    opacity: 0.4;
    font-family: ${theme.PRIMARY_FONT}, sans-serif;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: -0.9px;
    text-align: right;
    color: ${theme.BLACK_COLOR};
  }
  @media ${mobileSize} {
    margin: 20px 0px;
    width: 100%;
    h2 {
      max-width: 150px;
      margin: auto auto;
      font-size: 25px;
    }
    img {
      margin: auto auto;
      max-width: 100px;
    }
  }
`;

const Percentage = styled.div`
  display: flex;
`;

const PercentageSign = styled.div`
  font-family: ${theme.PRIMARY_FONT}, sans-serif;
  margin: 5px;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  color: ${theme.PRIMARY_COLOR};
`;

const WhiteCompanyBox = styled.div`
  background-color: ${theme.OFF_WHITE_BACKGROUND};
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin: auto;
  padding: 25px;
  margin: 0px;
  display: flex;
  p {
    font-family: ${theme.PRIMARY_FONT}, sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${theme.BLACK_COLOR};
    max-width: 551px;
  }
`;

const TitleWrappedText = styled.div`
  margin: 0;
  padding: 0;
  font-family: ${theme.PRIMARY_FONT}, sans-serif;
  width: 629px;
  height: 192px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.WHITE_COLOR};
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const TitleHiddenText = styled.div`
  display: none;
  @media screen and (max-width: 900px) {
    display: block;
    margin-top: 50px;
    padding: 0;
    font-family: ${theme.PRIMARY_FONT}, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: ${theme.WHITE_COLOR};
  }
`;

const ESGTitleHeading = styled.h1`
  margin: 0;
  padding: 0;
  font-family: ${theme.PRIMARY_FONT}, sans-serif;
  color: ${theme.WHITE_COLOR};
  font-size: 160px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -4px;
  @media screen and (max-width: 1100px) {
    font-size: 80px;
  }
  @media screen and (max-width: 550px) {
    font-size: 40px;
    letter-spacing: 0px;
  }
`;

const CircleBot = styled.span<{ color: string }>`
  background-color: ${props => props.color};
  content: 'o';
  height: 81px;
  width: 81px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  @media screen and (max-width: 1100px) {
    height: 40px;
    width: 40px;
  }
  @media screen and (max-width: 550px) {
    height: 20px;
    width: 20px;
  }
`;

const WrappingDiv = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const TitleWrapper = styled.div`
  margin: 0;
  padding: 0;
`;

const LongTextBlock = styled.div`
  font-family: ${theme.PRIMARY_FONT}, sans-serif;
  color: ${theme.WHITE_COLOR};
  p {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    max-width: 650px;
  }
  ol {
    max-width: 770px;
    font-family: ${theme.PRIMARY_COLOR}, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: ${theme.WHITE_COLOR};
    list-style: none;
    counter-reset: li;
  }
  ol li::before {
    content: counter(li) '.';
    color: ${theme.PRIMARY_COLOR};
    font-family: ${theme.SECONDARY_FONT}, monospace;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-align: left;
    display: inline-block;
    width: 40px;
    margin-left: -40px;
  }
  ol li {
    counter-increment: li;
    padding: 10px 0px 10px 65px;
  }
  ul {
    list-style: none;
    max-width: 770px;
    font-family: ${theme.PRIMARY_COLOR}, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: ${theme.WHITE_COLOR};
  }
  ul li::before {
    content: '•';
    color: ${theme.PRIMARY_COLOR};
    font-family: ${theme.SECONDARY_FONT}, monospace;
    font-size: 12px;
    display: inline-block;
    width: 40px;
    margin-left: -40px;
  }
  ul li {
    padding: 10px 0px 10px 65px;
  }
  @media screen and (max-width: 500px) {
    ol li {
      padding: 10px 0px 10px 30px;
    }
    ul li {
      padding: 10px 0px 10px 30px;
    }
  }
`;

const OurESGPeople = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  img {
    max-width: 261px;
    margin-bottom: 52px;
    margin-top: 10px;
  }
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
  }
`;

const OurESGPerson = styled.div`
  max-width: 455px;
  font-family: ${theme.PRIMARY_FONT}, sans-serif;
  color: ${theme.WHITE_COLOR};
  h1 {
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
  }
  h2 {
    opacity: 0.4;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
  }
  p {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
  }
`;

const SecondBlock = styled.div`
  max-width: 834px;
`;

const SecondBlockPara = styled.p`
  font-family: ${theme.PRIMARY_FONT}, sans-serif;
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: -0.8px;
  color: ${theme.WHITE_COLOR};
`;

const IntroDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
  }
`;

const IntroBlock = styled.div`
  max-width: 552px;
  font-family: ${theme.PRIMARY_FONT}, sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.WHITE_COLOR};
`;

const OurESGh3 = styled.div`
  font-family: ${theme.PRIMARY_FONT}, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #45aeeb;
  padding-bottom: 10px;
  margin-top: 100px;
`;

const DiversityStat = styled.div`
  margin-right: 10px;
  padding: 0;
  h1 {
    font-family: ${theme.PRIMARY_FONT}, sans-serif;
    font-size: 160px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -4px;
    color: ${theme.WHITE_COLOR};
    margin: 0;
  }
  p {
    font-family: ${theme.PRIMARY_FONT}, sans-serif;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: ${theme.WHITE_COLOR};
  }
  @media ${mobileSize} {
    max-width: auto;
    h1 {
      font-size: 60px;
    }
    p {
      word-wrap: break-word;
    }
  }
`;

const WhiteBreak = styled.hr`
  width: 100%;
  height: 1px;
  opacity: 0.9;
  background-color: ${theme.BLACK_COLOR};
  margin-top: 50px;
  margin-bottom: 35px;
  @media ${mobileSize} {
    margin-top: 5px;
  }
`;

const Break = styled.hr`
  width: 100%;
  height: 1px;
  opacity: 0.1;
  background-color: ${theme.WHITE_COLOR};
  margin-top: 100px;
  margin-bottom: 100px;
  @media ${mobileSize} {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

const DiversityStatsDiv = styled.div`
  margin: 5px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const OurESGContainer = styled.div`
  padding-top: 120px;
  background-color: ${theme.BLACK_COLOR};
  display: block;
  width: 100%;
`;

const OurESGWrapper = styled.div`
  background-color: ${theme.BLACK_COLOR};
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin: auto;
  padding: 25px;
`;

const OurESGh2 = styled.h2`
  font-family: ${theme.SECONDARY_COLOR}, monospace;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  opacity: 0.6;
  color: ${theme.WHITE_COLOR};
`;

export { OurESGSegment };
