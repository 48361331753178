import * as React from 'react';
import styled from 'styled-components';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import theme from '../../../util/theme';
import {
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryLine,
  VictoryLabel,
  VictoryAxis,
  VictoryArea,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryScatter,
  VictoryGroup,
} from 'victory';
import { StaticCompaniesMapSegment } from './StaticCompaniesMap';
import { UnicornsToolTip } from './UnicornsToolTip';
import { AppText } from '../../ui';
import { laptopSize, mobileSize, desktopSize } from '../../../util/variables';
import { mapCompanyImages } from './insightsData';
import { FourPlacesMapComponent } from '../research3/FourPlacesMap';

interface IGraphsComponentProps {
  amountOfFunding: any[];
  cumulativeRaised: any[];
  circleNumbers: any[];
  numberOfUnicorns: any[];
  mapCompanies?: any;
  firstGraphLabel?: string;
  researchPageNumber?: number;
}

const GraphsComponent: React.FunctionComponent<IGraphsComponentProps> = React.memo(props => {
  return (
    <FlexTablesContainer>
      <GraphsDiv>
        <FloatLeftGraphs>
          <FilterHeadingLabel>
            Top Companies around the world{' '}
            <SpanContainer
              style={{ marginTop: '4px' }}
              data-tip="Logos represented are the largest or fastest growing companies by region. The number represents total companies identified by Base10 in the region."
            >
              <AiOutlineInfoCircle color="rgba(26, 26, 26, 0.4)" fontSize="16px" />{' '}
            </SpanContainer>
          </FilterHeadingLabel>
          {/* <MapDiv>{typeof window !== 'undefined' && <LeafletMap />}</MapDiv> */}
          <MapDiv>
            {!!props.mapCompanies ? (
              <FourPlacesMapComponent mapCompanyImages={props.mapCompanies} />
            ) : (
              <StaticCompaniesMapSegment mapCompanyImages={mapCompanyImages} />
            )}
          </MapDiv>
          <FilterHeadingLabel>
            Total Companies Identified{' '}
            <SpanContainer
              style={{ marginTop: '4px' }}
              data-tip="This is not a representation of all companies in the trend. It represents companies identified by Base10 during our research."
            >
              <AiOutlineInfoCircle color="rgba(26, 26, 26, 0.4)" fontSize="16px" />
            </SpanContainer>
          </FilterHeadingLabel>
          <ArrowBoxesContainer>
            {props.circleNumbers.map((item, index) => (
              <ArrowDataBox key={item.id}>
                <ArrowBoxDataContainer lastItem={props.circleNumbers.length - 1 === index}>
                  <ArrowBoxLabel>{item.title}</ArrowBoxLabel>
                  <ArrowBoxNumber>{item.number}</ArrowBoxNumber>
                </ArrowBoxDataContainer>
              </ArrowDataBox>
            ))}
          </ArrowBoxesContainer>
        </FloatLeftGraphs>
        <FloatRightGraphs>
          <FilterHeadingLabel>
            {!!props.firstGraphLabel ? props.firstGraphLabel : 'Number of Companies > $500M Value'}
          </FilterHeadingLabel>
          <AmountOfFundingChartDiv>
            <VictoryChart
              domainPadding={30}
              height={220}
              width={600}
              style={{ parent: { zIndex: 1 } }}
              containerComponent={<VictoryVoronoiContainer />}
            >
              <svg>
                <defs>
                  <linearGradient id="myGradient" x1="0%" y1="-40%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="#3fc296" />
                    <stop offset="100%" stopColor="rgba(63, 194, 150, 0)" />
                  </linearGradient>
                </defs>
              </svg>
              <VictoryGroup data={props.numberOfUnicorns} x="year" y="number">
                <VictoryArea
                  x="year"
                  y="number"
                  labels={({ datum }) => datum.label}
                  labelComponent={
                    <VictoryLabel
                      style={[
                        {
                          color: theme.BLACK_COLOR,
                          fontSize: 14,
                          fontFamily: theme.PRIMARY_FONT,
                        },
                      ]}
                    />
                  }
                  style={{
                    data: { stroke: '#3fc296', fill: 'url(#myGradient)', strokeWidth: 2 },
                  }}
                />
                <VictoryScatter
                  labelComponent={<VictoryTooltip flyoutComponent={<UnicornsToolTip />} />}
                  x="year"
                  y="number"
                  style={{
                    data: {
                      fill: '#ffffff',
                      stroke: '#3fc296',
                      fillOpacity: 1,
                      strokeWidth: 2,
                    },
                    labels: {
                      display: 'none',
                      opacity: 0,
                    },
                  }}
                  size={4}
                />
              </VictoryGroup>
              <VictoryAxis
                dependentAxis
                style={{
                  tickLabels: {
                    fontFamily: theme.SECONDARY_FONT,
                    letterSpacing: '1.67px',
                    fontSize: '10px',
                    color: 'rgb(26, 26, 26)',
                    opacity: '0.6',
                  },
                  axis: { stroke: 'transparent' },
                }}
                tickFormat={t => `${t}`}
              />
              <VictoryAxis
                style={{
                  tickLabels: {
                    fontFamily: theme.SECONDARY_FONT,
                    letterSpacing: '1.67px',
                    fontSize: '10px',
                    color: 'rgb(26, 26, 26)',
                    opacity: '0.6',
                  },
                  axis: { stroke: theme.BLACK_COLOR, opacity: '0.2' },
                }}
                tickFormat={year => `${year}`}
              />
            </VictoryChart>
          </AmountOfFundingChartDiv>
          <FilterHeadingLabel>Amount of funding ($M)</FilterHeadingLabel>
          <AmountOfFundingChartDiv>
            <VictoryChart domainPadding={30} height={220} width={600}>
              <VictoryBar
                labels={({ datum }) => `$${Math.round(datum.amount)}`}
                data={props.amountOfFunding}
                barRatio={0.8}
                style={{
                  data: {
                    fill: '#f1ca0c',
                  },
                }}
                labelComponent={
                  <VictoryLabel
                    style={[
                      {
                        color: theme.BLACK_COLOR,
                        fontSize: 14,
                        fontFamily: theme.PRIMARY_FONT,
                      },
                    ]}
                  />
                }
                alignment="middle"
                x="year"
                y="amount"
                // domain={{x: [2013, 2020], y: [0, 90]}}
              />
              <VictoryAxis
                dependentAxis
                style={{
                  tickLabels: {
                    fontFamily: theme.SECONDARY_FONT,
                    letterSpacing: '1.67px',
                    fontSize: '10px',
                    color: 'rgb(26, 26, 26, 0.6)',
                    opacity: '0.6',
                  },
                  axis: { stroke: 'transparent' },
                }}
                tickFormat={t => `${t}`}
              />
              <VictoryAxis
                tickFormat={year => year.toString()}
                style={{
                  tickLabels: {
                    fontFamily: theme.SECONDARY_FONT,
                    letterSpacing: '1.67px',
                    fontSize: '10px',
                    color: 'rgb(26, 26, 26)',
                    opacity: '0.6',
                  },
                  axis: { stroke: theme.BLACK_COLOR, opacity: '0.2' },
                }}
              />
            </VictoryChart>
          </AmountOfFundingChartDiv>
          <FilterHeadingLabel>Cumulative raised ($M)</FilterHeadingLabel>
          <AmountOfFundingChartDiv>
            <VictoryChart domainPadding={30} height={220} width={600}>
              <VictoryBar
                labels={({ datum }) => `$${Math.round(datum.amount).toLocaleString()}`}
                labelComponent={
                  <VictoryLabel
                    style={[
                      {
                        color: theme.BLACK_COLOR,
                        fontSize: 14,
                        fontFamily: theme.PRIMARY_FONT,
                      },
                    ]}
                  />
                }
                data={props.cumulativeRaised}
                barRatio={0.8}
                style={{
                  data: {
                    fill: '#9e5fd7',
                  },
                }}
                alignment="middle"
                x="year"
                y="amount"
                // domain={{x: [2013, 2020], y: [0, 90]}}
              />
              <VictoryAxis
                dependentAxis
                tickFormat={t => `${t}`}
                style={{
                  tickLabels: {
                    fontFamily: theme.SECONDARY_FONT,
                    letterSpacing: '1.67px',
                    fontSize: '10px',
                    color: 'rgb(26, 26, 26)',
                    opacity: '0.6',
                  },
                  axis: { stroke: 'transparent' },
                }}
              />
              <VictoryAxis
                tickFormat={year => year.toString()}
                style={{
                  tickLabels: {
                    fontFamily: theme.SECONDARY_FONT,
                    letterSpacing: '1.67px',
                    fontSize: '10px',
                    color: 'rgb(26, 26, 26)',
                    opacity: '0.6',
                  },
                  axis: { stroke: theme.BLACK_COLOR, opacity: '0.2' },
                }}
              />
            </VictoryChart>
          </AmountOfFundingChartDiv>
        </FloatRightGraphs>
      </GraphsDiv>
    </FlexTablesContainer>
  );
});

const FlexTablesContainer = styled.div`
  max-width: 1400px;
`;

const FloatRightGraphs = styled.div``;

const FloatLeftGraphs = styled.div``;

const ShopifyText = styled(AppText)`
  max-width: 600px;
  font-family: ${theme.PRIMARY_COLOR};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
`;

const GraphsDiv = styled.div`
  max-width: 95vw;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 480px;
  @media ${laptopSize} {
    display: flex;
    flex-direction: column;
  }
`;

const AmountOfFundingChartDiv = styled.div`
  margin: 0;
  padding: 0;
  margin-top: -30px;
  width: 500px;
  @media ${laptopSize} {
    margin-top: -40px;
    max-width: 500px;
    margin: auto;
  }
  @media ${mobileSize} {
    max-width: 90vw;
    overflow: auto;
    width: 100%;
    justify-content: center;
  }
`;

const ArrowDataBox = styled.div`
  flex: 1;
  min-width: 100px;
`;

interface LastItem {
  lastItem?: boolean;
}

const TooltipText = styled(AppText)`
  display: inline-block;
  text-align: center;
  max-width: 316px;
  padding: 20px;
`;

const ArrowBoxDataContainer = styled.div<LastItem>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 82px;
  margin-left: -10px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: -10px;
  position: relative;
  border-radius: ${props => (props.lastItem ? '4px' : '0px')};
  background-image: linear-gradient(
    to left,
    rgba(69, 174, 235, 0.2),
    rgba(69, 174, 235, 0.2) 36%,
    rgba(255, 255, 255, 0) 100%
  );
  :after,
  :before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  :after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: rgba(69, 174, 235, 0.1);
    border-width: ${props => (props.lastItem ? '40px 0 40px 0px' : '40px 0 40px 20px')};
    margin-top: -40px;
    position: absolute;
  }
  :before {
    border-color: rgba(194, 225, 245, 0);
    border-left-color: rgba(69, 174, 235, 0.1);
    border-width: ${props => (props.lastItem ? '40px 0 40px 0px' : '40px 0 40px 20px')};
    margin-top: -41px;
    position: absolute;
  }
`;

const ArrowBoxLabel = styled(AppText)`
  max-width: 70px;
  opacity: 0.6;
  font-family: ${theme.SECONDARY_FONT};
  text-transform: uppercase;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.67px;
  text-align: center;
  vertical-align: text-bottom;
  color: ${theme.BLACK_COLOR};
`;

const ArrowBoxNumber = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.BLACK_COLOR};
`;

const ArrowBoxesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-right: 30px;
  flex-wrap: nowrap;
  @media ${laptopSize} {
    justify-content: center;
  }
  @media ${mobileSize} {
    flex-wrap: wrap;
  }
`;

const MapDiv = styled.div`
  /* height: 500px;
  width: 500px; */
  position: relative;
  max-width: 90vw;
  max-height: 350px;
  min-height: 280px;
  margin: 20px;
  z-index: 1;
  padding: 0;
  @media ${desktopSize} {
    max-width: 50vw;
  }
  @media ${laptopSize} {
    min-height: auto;
    margin: 0;
    max-width: 90vw;
  }
  @media ${mobileSize} {
    margin: 0;
    max-width: 90vw;
    margin-bottom: 20px;
  }
`;

const FilterHeadingLabel = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-right: 14px;
  margin: 0;
  padding: 0;
`;

const SpanContainer = styled.span`
  margin: 0px;
  padding: 0px;
  line-height: 1;
  position: relative;
  top: 0;
  right: 0;
  vertical-align: top;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
`;

export { GraphsComponent };
