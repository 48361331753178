import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import theme from '../../../util/theme';
import { laptopSize } from '../../../util/variables';

interface IDatum {
  year: number;
  number: number;
  companies: string[];
  label?: string;
}

interface ToolTipProps {
  x: number;
  y: number;
  orientation: string;
  datum: IDatum;
}

const UnicornsToolTip: FunctionComponent = (props: ToolTipProps) => {
  return (
    <g style={{ pointerEvents: 'none' }}>
      <foreignObject x={props.x - 110} y={props.y - 40} width="100" height="250">
        <ToolTipContainer>
          <span>
            <ImageContainer>
              {props.datum.companies.map(company => (
                <ToolTipImage
                  key={company}
                  src={require(`../../../content/img/companies/${company.toLowerCase()}.png`)}
                />
              ))}
            </ImageContainer>
          </span>
        </ToolTipContainer>
      </foreignObject>
    </g>
  );
};

const ToolTipContainer = styled.div`
  min-height: 30px;
  min-width: 100px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

const ToolTipImage = styled.img`
  max-width: 85px;
  padding: 10px;
`;

export { UnicornsToolTip };
