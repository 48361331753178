import * as React from 'react';
import styled from 'styled-components';
import { laptopSize, desktopSize, mobileSize } from '../../util/variables';
import { Modal, Wrapper, AnimatedLinkAnchor, AppText } from '../ui';
import theme from '../../util/theme';

interface ITeamData {
  name: string;
  role: string;
  image: string;
  excerpt: string;
  about: string[];
  email: string;
  linkedin: string;
}

interface TeamPortfolioModalProps {
  showModal: boolean;
  toggleModal: () => void;
  data: ITeamData;
}

interface TeamPortfolioModalState {}

class TeamPortfolioModal extends React.Component<TeamPortfolioModalProps, TeamPortfolioModalState> {
  constructor(props: TeamPortfolioModalProps) {
    super(props);
  }

  render(): JSX.Element {
    return (
      <Modal
        height="fit-content"
        width="fit-content"
        showModal={this.props.showModal}
        toggleModal={this.props.toggleModal}
        label="Team Portfolio"
      >
        <CloseModal onClick={this.props.toggleModal}>
          <Cross id="cross" />
        </CloseModal>
        <PortfolioContainer>
          <Wrapper>
            <PortfolioInnerContainer>
              <PortfolioDiv>
                <PortfolioSideBar>
                  <PortfolioSideBarWrapper>
                    <PortfolioSiderbarCover>
                      <PortfolioProfileImage src={this.props.data.image} />
                    </PortfolioSiderbarCover>
                  </PortfolioSideBarWrapper>
                </PortfolioSideBar>
                <PortfolioContent>
                  <PortfolioName>{this.props.data.name}</PortfolioName>
                  <PortfolioTitle>{this.props.data.role}</PortfolioTitle>
                  <PortfolioBio>
                    {this.props.data.about ? (
                      this.props.data.about.map(para => (
                        <PortfolioBioText key={para}>{para}</PortfolioBioText>
                      ))
                    ) : (
                      <PortfolioBioText>Error loading content</PortfolioBioText>
                    )}
                  </PortfolioBio>
                  <PortfolioBio>
                    <AnimatedLinkAnchor fontSize="20px" to={`mailto:${this.props.data.email}`}>
                      Email
                    </AnimatedLinkAnchor>
                    <AnimatedLinkAnchor fontSize="20px" to={this.props.data.linkedin}>
                      LinkedIn
                    </AnimatedLinkAnchor>
                  </PortfolioBio>
                </PortfolioContent>
              </PortfolioDiv>
            </PortfolioInnerContainer>
          </Wrapper>
        </PortfolioContainer>
      </Modal>
    );
  }
}

const PortfolioContainer = styled.div`
  position: absolute;
  top: 25rem;
  left: 50%;
  width: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  @media ${mobileSize} {
    top: 0;
  }
`;

const PortfolioInnerContainer = styled.div`
  width: calc(100% - 3.75rem);
  float: left;
  margin-left: 1.875rem;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  background-color: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    width: auto;
    margin-left: 0;
    padding: 1.25rem 1.25rem 5rem;
  }
`;

const PortfolioDiv = styled.div`
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  width: calc(100% + 3.75rem);
  text-align: left;
  @media ${mobileSize} {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    width: calc(100% + 2.5rem);
  }
`;

const PortfolioSideBar = styled.div`
  position: relative;
  width: calc(33.33333% - 2.5rem);
  float: left;
  margin-left: 1.875rem;
  min-height: 0.0625rem;
  @media ${mobileSize} {
    width: calc(100% - 2.5rem);
    float: left;
    margin-left: 1.25rem;
  }
`;

const PortfolioSideBarWrapper = styled.div`
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  width: calc(100% + 3.75rem);
  @media ${mobileSize} {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    width: calc(100% + 2.5rem);
  }
`;

const PortfolioSiderbarCover = styled.div`
  position: relative;
  top: -7.5rem;
  width: calc(75% - 3.28125rem);
  float: left;
  margin-left: 1.875rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  @media ${mobileSize} {
    top: 0;
    width: calc(33.33333% - 1.66667rem);
    float: left;
    margin-left: 1.25rem;
    -webkit-transform: none;
    transform: none;
  }
`;

const PortfolioProfileImage = styled.img`
  display: block;
  width: 100%;
  @media ${mobileSize} {
    height: calc(33.33333vw - 1.66667rem);
    padding: 5px;
  }
`;

const PortfolioContent = styled.div`
  width: calc(58.33333% - 2.96875rem);
  float: left;
  margin-left: 1.875rem;
  /* height: 100vh; */
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
    margin-top: 0px;
  }
`;

const PortfolioName = styled.h1`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-weight: 300;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: -0.8px;
  margin: 0;
  margin-top: 45px;
  @media ${mobileSize} {
    font-size: 30px;
    line-height: 33.75px;
    letter-spacing: -0.75px;
  }
`;

const PortfolioTitle = styled.h2`
  font-family: ${theme.PRIMARY_FONT};
  margin-top: 0.9375rem;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  color: rgba(26, 26, 26, 0.4);
  font-weight: normal;
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0;
    margin-top: 4px;
  }
`;

const PortfolioBioText = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-size: 20px;
  line-height: 32px;
  font-weight: 300;
  padding-bottom: 20px;
  @media ${mobileSize} {
    font-family: ${theme.PRIMARY_FONT};
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    padding-bottom: 18px;
  }
`;

const PortfolioBio = styled.div`
  margin-top: 3.5625rem;
  a {
    margin-right: 60px;
    font-size: 20px;
  }
  @media ${mobileSize} {
    margin-top: 1.8125rem;
    a {
      font-size: 18px;
    }
  }
`;

const CloseModal = styled.div`
  cursor: pointer;
  position: fixed;
  top: 1.875rem;
  right: 1.875rem;
  z-index: 7;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: ${theme.WHITE_COLOR};
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  @media ${mobileSize} {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 50px;
    width: 50px;
    border: 1px solid rgba(26, 26, 26, 0.5);
  }
  :hover {
    #cross {
      opacity: 1;
    }
  }
`;

const Cross = styled.span`
  opacity: 0.2;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  :before {
    transform: rotate(45deg) translate(-50%, -50%);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.5px;
    width: 22px;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    background-color: ${theme.BLACK_COLOR};
  }
  :after {
    height: 0.0625rem;
    width: 0.75rem;
    transform: rotate(-45deg) translate(-50%, -50%);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.5px;
    width: 22px;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    background-color: ${theme.BLACK_COLOR};
  }
  @media ${mobileSize} {
    opacity: 0.6;
    height: 1.25rem;
    width: 1.25rem;
    :before {
      height: 1px;
      width: 16px;
    }
    :after {
      height: 1px;
      width: 16px;
    }
  }
`;

export { TeamPortfolioModal };
