import * as React from 'react';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { laptopSize, mobileSize } from '../../../util/variables';
import { AnimatedLink, AnimatedDiv, Wrapper, AppText } from '../../ui';
import theme from '../../../util/theme';
import { useEffect, useState } from 'react';
import { prismicClient, Prismic, RichText } from '../../../services';
import ReactTooltip from 'react-tooltip';
import { OpportunityGraph } from '../research/OpportunityGraph';
import sortArrow from '../../../content/img/research/arrow-down-sort-blue.png';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const signalColors = {
  light_green: '#CDE6DB',
  dark_green: '#036A3C',
  light_yellow: '#F0DBC3',
  dark_yellow: '#E29433',
  light_red: '#EACFCE',
  dark_red: '#C23934',
};

const ResearchPage: React.FC = () => {
  const researchDataSummary = useStaticQuery(
    graphql`
      query researchDataSummary {
        allBase11SummaryCsv {
          nodes {
            id
            metric_name
            metric_display_name
            metric_value
          }
        }
        allDiveSummaryCsv {
          nodes {
            id
            dive_slug
            dive_name
            total_companies
            total_raised
            total_exits
            portfolio_companies
            maturity_benchmark
            dive_hiring_and_funding_growth_score
            base11_opportunity_score
            base11_opportunity_score_display
          }
        }
      }
    `,
  );

  const [diveData, setDiveData] = useState<any>(null);

  const [trendSortField, setTrendSortField] = useState('dive_hiring_and_funding_growth_score');
  const [trendSortAscending, setTrendSortAscending] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await prismicClient.query(
        Prismic.Predicates.at('document.type', 'research-dive'),
        { pageSize: 9999 },
      );
      console.log('prismic response: ', response);
      if (response && response.results.length) {
        setDiveData(response.results);
      }
    };

    fetchData();
  }, []);

  const findTrendDescription = (name: string) => {
    if (!!diveData && !!diveData.length) {
      const data = diveData.slice().filter((item: any) => item.data.slug_name === name);
      return !!data.length ? `${RichText.asText(data[0].data.dive_description_short)}` : '';
    } else return '';
  };

  const opportunityChartData = () => {
    if (!!researchDataSummary.allDiveSummaryCsv?.nodes?.length) {
      const data = researchDataSummary.allDiveSummaryCsv.nodes.map((item: any) => ({
        dive_slug: item.dive_slug,
        dive_name: item.dive_name,
        maturity_benchmark: item.maturity_benchmark,
        base11_opportunity_score: item.base11_opportunity_score,
        dive_hiring_and_funding_growth_score: Number(
          item.dive_hiring_and_funding_growth_score,
        ).toFixed(2),
      }));
      return !!data.length ? data : [];
    }
    return [];
  };

  return (
    <OurTeamContainer>
      <ReactTooltip
        multiline
        // place="top"
        effect="solid"
        css={{
          maxWidth: 300,
          lineHeight: 1.4,
          textAlign: 'center',
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor="rgba(0, 0, 0, 0.9)"
      />
      <ResearchWrapper>
        <ResearchTitle>Base11 Research</ResearchTitle>
        <ResearchSubTitle>
          Base11 is the internal software Base10 built to empower data-driven research of the
          fastest growing companies and trends in the Real Economy
        </ResearchSubTitle>
        <SectionTitleDiv>
          <SectionTitle>Summary</SectionTitle>
          <SectionAfter />
        </SectionTitleDiv>
        <SummaryContainer expandLast={researchDataSummary.allBase11SummaryCsv.nodes.length % 2 === 1}>
          {researchDataSummary.allBase11SummaryCsv.nodes.map((item: any) => (
            <SummaryDiv key={item.id}>
              <SummaryNumber>{Number(item.metric_value).toLocaleString()}</SummaryNumber>
              <SummaryDescription>{item.metric_display_name}</SummaryDescription>
            </SummaryDiv>
          ))}
        </SummaryContainer>
        <GraphContainer>
          <OpportunityGraph data={opportunityChartData()} />
        </GraphContainer>
        <SectionTitleDiv style={{ marginBottom: '32px' }}>
          <SectionTitle>Featured Trends</SectionTitle>
          <SectionAfter />
        </SectionTitleDiv>
        {/* <SortDiv>
          <FilterSelect
            name="trendSortField"
            onChange={e => {
              trackCustomEvent({
                action: 'Click',
                category: 'Sort',
                label: `Trend sort field changed to ${e.target.value}`,
              });
              setTrendSortField(e.target.value);
            }}
          >
            <option value="dive_hiring_and_funding_growth_score">Hiring & Funding Speed</option>
            <option value="dive_name">Name</option>
            <option value="total_companies">Featured Companies</option>
            <option value="portfolio_companies">Portfolio Companies</option>
            <option value="total_raised">Total Raised</option>
          </FilterSelect>
          <SortOrderDiv onClick={() => setTrendSortAscending(!trendSortAscending)}>
            <img
              src={sortArrow}
              style={{
                height: '24px',
                transform: trendSortAscending ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
              alt="Change sort order"
            />
          </SortOrderDiv>
        </SortDiv> */}
        {/* {!!diveData && !!diveData.length && diveData[0].data.slug_name} */}
        {researchDataSummary.allDiveSummaryCsv.nodes
          .slice()
          // .sort((a: any, b: any) => {
          //   const valA = a[trendSortField];
          //   const valB = b[trendSortField];
          //   if (trendSortField === 'dive_name') {
          //     if (valA > valB) {
          //       return trendSortAscending ? 1 : -1;
          //     } else if (valA < valB) {
          //       return trendSortAscending ? -1 : 1;
          //     } else return 0;
          //   } else if (trendSortField !== 'dive_name') {
          //     if (Number(valA) > Number(valB)) {
          //       return trendSortAscending ? 1 : -1;
          //     } else if (Number(valA) < Number(valB)) {
          //       return trendSortAscending ? -1 : 1;
          //     } else return 0;
          //   }
          // })
          .sort((a: any, b: any) => {
            if (Number(a.portfolio_companies) < Number(b.portfolio_companies)) {
              return 1;
            } else if (Number(a.portfolio_companies) > Number(b.portfolio_companies)) {
              return -1;
            } else {
              if (
                Number(a.dive_hiring_and_funding_growth_score) <
                Number(b.dive_hiring_and_funding_growth_score)
              ) {
                return 1;
              } else if (
                Number(a.dive_hiring_and_funding_growth_score) >
                Number(b.dive_hiring_and_funding_growth_score)
              ) {
                return -1;
              } else {
                return 0;
              }
            }
          })
          .map((item: any) => (
            <div key={item.id}>
              <Link to={`/research/${item.dive_slug}`}>
                <TrendDiv>
                  <TrendInfoTextDiv>
                    <TrendNameText>{item.dive_name}</TrendNameText>
                    <TrendDescriptionText>
                      {findTrendDescription(item.dive_slug)}
                    </TrendDescriptionText>
                  </TrendInfoTextDiv>
                  <TrendInfoDiv>
                    <TrendDivNumber>{item.total_companies}</TrendDivNumber>
                    <TrendDataDescriptionText>Featured Companies</TrendDataDescriptionText>
                  </TrendInfoDiv>
                  <TrendInfoDiv>
                    <TrendDivNumber>
                      ${Math.round(item.total_raised / 1000000).toLocaleString()}M
                    </TrendDivNumber>
                    <TrendDataDescriptionText>Total Raised In Trend</TrendDataDescriptionText>
                  </TrendInfoDiv>
                  <TrendInfoDiv>
                    <TrendColorNumber
                      number={
                        item.base11_opportunity_score_display.split(' ')[0] === 'Developing'
                          ? 85
                          : 50
                      }
                    >
                      {item.base11_opportunity_score_display.split(' ')[0]}
                      {/* {item.dive_hiring_and_funding_growth_score} */}
                    </TrendColorNumber>
                    <TrendDataDescriptionText>
                      Real Economy Trend Status
                      {/* <span style={{ opacity: 0.6 }}>(0-100)</span> */}
                    </TrendDataDescriptionText>
                  </TrendInfoDiv>
                  <TrendInfoDiv>
                    <TrendDivNumber>{Number(item.portfolio_companies)}</TrendDivNumber>
                    <TrendDataDescriptionText>B10 Portfolio Companies</TrendDataDescriptionText>
                  </TrendInfoDiv>
                </TrendDiv>
              </Link>
            </div>
          ))}
      </ResearchWrapper>
    </OurTeamContainer>
  );
};

const GraphContainer = styled.div`
  max-width: 800px;
  margin: 0px auto;
  @media ${mobileSize} {
    max-width: 100%;
  }
`;

const SortOrderDiv = styled.div`
  height: 40px;
  width: 40px;
  border: 1px solid ${theme.GREY_300};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14px;
  cursor: pointer;
  transition: 200ms all ease-in-out;
  border-radius: 8px;
  :hover {
    /* background-color: ${theme.GREY_300}; */
    border: 1px solid ${theme.GREY_500};
  }
`;

const SortDiv = styled.div`
  display: flex;
  margin-top: 32px;
  margin-bottom: 32px;
  align-items: center;
`;

const FilterSelect = styled.select`
  background: transparent;
  border: none;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  /* border-bottom: 1px solid ${theme.PRIMARY_COLOR}; */
  border: 1px solid ${theme.GREY_300};
  margin: 0px;
  margin-right: 8px;
  max-width: 350px;
  width: 230px;
  text-overflow: ellipsis;
  padding-left: 12px;
  option {
    font-family: ${theme.PRIMARY_FONT};
  }
  transition: 200ms all ease-in-out;
  cursor: pointer;
  height: 40px;

  border-radius: 8px;
  :hover {
    /* background-color: ${theme.GREY_300}; */
    border: 1px solid ${theme.GREY_500};
  }
  @media ${mobileSize} {
    width: calc(100% - 40px - 16px);
    /* margin: 5px; */
  }
`;

const TrendDataDescriptionText = styled(AppText)`
  color: ${theme.GREY_700};
  /* NOTE: This color is too light */
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.2px;
  font-weight: 400;
  max-width: 120px;
  text-align: center;
  margin: 0px auto;
  @media ${laptopSize} {
    max-width: 120px;
    text-align: center;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 21px;
  }
  @media ${mobileSize} {
    width: max-content;
    max-width: max-content;
    text-align: left;
    margin-bottom: 8px;
  }
`;

const TrendDescriptionText = styled(AppText)`
  color: ${theme.GREY_700};
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  margin-top: 8px;
  font-weight: 400;
  @media ${laptopSize} {
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 16px;
  }
`;

const TrendDivNumber = styled(AppText)`
  font-size: 28px;
  line-height: 39px;
  color: ${theme.NEW_BLACK_COLOR};
  width: 100%;
  text-align: center;
  margin-top: auto;
  margin-bottom: 8px;
  font-weight: 500;
  @media ${laptopSize} {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    width: 100%;
    text-align: center;
  }
  @media ${mobileSize} {
    width: max-content;
    text-align: left;
  }
`;

interface ColorNumber {
  number: number;
}

const TrendColorNumber = styled(TrendDivNumber)<ColorNumber>`
  color: ${props =>
    props.number >= 80
      ? signalColors.dark_green
      : props.number >= 20 && props.number < 80
      ? signalColors.dark_yellow
      : signalColors.dark_red};
  background-color: ${props =>
    props.number >= 80
      ? signalColors.light_green
      : props.number >= 20 && props.number < 80
      ? signalColors.light_yellow
      : signalColors.light_red};
  padding: 2px 8px;
  min-width: fit-content;
  margin: 0px auto;
  margin-top: auto;
  margin-bottom: 4px;
  border-radius: 8px;
  @media ${laptopSize} {
    margin: auto;
    margin-bottom: 4px;
    text-align: center;
  }
  @media ${mobileSize} {
    margin: 0px;
    margin-bottom: 8px;
    text-align: left;
  }
`;

const TrendNameText = styled(AppText)`
  color: ${theme.NEW_BLACK_COLOR};
  font-size: 28px;
  line-height: 39px;
  width: 100%;
  font-weight: 600;
  letter-spacing: 0.2px;
  @media ${laptopSize} {
    width: auto;
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }
`;

const TrendInfoTextDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${laptopSize} {
    align-items: flex-start;
  }
`;

const TrendInfoDiv = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  @media ${laptopSize} {
    display: flex;
    flex-direction: column;
    grid-gap: 0px;
    width: fit-content;
    max-width: 20%;
  }
  @media ${mobileSize} {
    align-items: flex-start;
  }
`;

const TrendDiv = styled.div`
  margin-bottom: 41px;
  padding: 42px 20px;
  border: 1px solid ${theme.GREY_600};
  border-radius: 8px;
  display: grid;
  grid-template-columns: 360px 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  cursor: pointer;
  box-shadow: none;
  transition: all 300ms ease-in-out;
  :hover {
    /* box-shadow: 0px 0px 16px 6px rgba(0, 0, 0, 0.15); */
    box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  }
  @media ${laptopSize} {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0px;
    justify-content: space-between;
    /* flex-wrap: wrap; */
  }
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
  }
`;

const ResearchWrapper = styled(Wrapper)`
  max-width: 1250px;
  padding: 0px 25px;
  @media ${mobileSize} {
    padding: 0px 20px;
  }
`;

const SummaryNumber = styled(AppText)`
  color: ${theme.NEW_BLACK_COLOR};
  font-size: 36px;
  line-height: 50px;
  font-weight: 500;
  letter-spacing: 0.2px;
  width: 100%;
  text-align: center;
  margin: 0px;
  padding: 0px;
  @media ${mobileSize} {
    font-weight: 700;
    font-size: 26px;
    line-height: 32px;
  }
`;
const SummaryDescription = styled(AppText)`
  color: ${theme.GREY_700};
  font-size: 14px;
  line-height: 21px;
  height: 41px;
  letter-spacing: 0.2px;
  margin: 0px;
  padding: 0px;
  margin-top: 4px;
  width: 100%;
  text-align: center;
  font-weight: 400;
  @media ${mobileSize} {
    height: auto;
    font-size: 12px;
    line-height: 18px;
    padding: 5px;
  }
`;

const SummaryDiv = styled.div`
  max-width: 160px;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid pink; */
  @media ${mobileSize} {
    padding: 10px 0px;
    max-width: none;
    min-width: none;
    min-height: 40vw;
    height: fit-content;
    border: 1px solid ${theme.GREY_600};
    border-radius: 2px;
  }
`;

const SummaryContainer = styled.div<{ expandLast: boolean }>`
  margin-top: 32px;
  padding: 42px 20px;
  border: 1px solid ${theme.GREY_600};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  flex-wrap: wrap;
  @media ${mobileSize} {
    display: grid;
    grid-gap: 7.5px;
    grid-template-columns: 1fr 1fr;
    margin-top: 24px;
    padding: 0px;
    border: none;

    div:nth-last-of-type(1) {
      grid-column: span ${props => props.expandLast ? 2 : 1};
    }
  }
`;

const SectionTitleDiv = styled.div`
  position: relative;
  display: flex;
  margin-top: 64px;
  @media ${mobileSize} {
    margin-top: 48px;
  }
`;

const SectionAfter = styled.div`
  display: block;
  width: 100%;
  background-color: ${theme.PITCH_BLACK};
  margin: auto 0px;
  height: 1px;
  width: 100%;
`;

const SectionTitle = styled(AppText)`
  font-size: 28px;
  line-height: 39px;
  font-weight: 700;
  letter-spacing: 0.2px;
  color: ${theme.NEW_BLACK_COLOR};
  width: max-content;
  margin-right: 16px;
  white-space: nowrap;
  @media ${mobileSize} {
    font-size: 20px;
    line-height: 30px;
    margin-right: 12px;
  }
`;

const ResearchTitle = styled(AppText)`
  font-size: 36px;
  line-height: 50px;
  letter-spacing: 0.2px;
  color: ${theme.NEW_BLACK_COLOR};
  margin: 0px;
  padding: 0px;
  margin-bottom: 16px;
  font-weight: 700;
  @media ${mobileSize} {
    font-size: 26px;
    line-height: 32px;
  }
`;

const ResearchSubTitle = styled(AppText)`
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: ${theme.GREY_700};
  max-width: 995px;
  margin: 0px;
  padding: 0px;
  font-weight: 400;
  @media ${mobileSize} {
    font-size: 16px;
    line-height: 23px;
  }
`;

const OurTeamContainer = styled.div`
  width: 100%;
  margin-top: 150px;
  max-width: 1150px;
  background-color: ${theme.WHITE_COLOR};
  /* height: 600px; */
  @media ${laptopSize} {
  }
`;

export { ResearchPage };
