export const useImageList = [
  'Stripe',
  'Robinhood',
  'Nubank',
  'Coinbase',
  'N26',
  'Toss',
  'Brex',
  'Notion',
  'Figma',
  'Miro',
  'UIPath',
  'Canva',
  'Hashicorp',
  'Procore',
  'Toast',
  'Confluent',
  'Freshworks',
  'Carta',
  'Airtable',
  'Klaviyo',
  'Zapier',
  'Webflow',
  'Tanium',
  'Databricks',
  'Samsara',
  'TalkDesk',
  'MessageBird',
  'Gitlab',
  'OneTrust',
  'ServiceTitan',
  'Gong',
  'Checkr',
  'Squarespace',
  'Podium',
  'Fivetran',
  'Calendly',
  'Benchling',
  'Braze',
  'Instacart',
  'Airbnb',
  'DoorDash',
  'Rappi',
  'TikTok (ByteDance)',
  'Discord',
  'Roblox',
  'Wish',
  'Checkout',
  'Hopin',
  'Verkada',
  'Noom',
  'Lyra Health',
  'Lifestance Health',
  'xtraCHEF',
  'Wonolo',
  'Wolt',
  'Vori',
  'Virtual Kitchen Co',
  'UrbanPiper',
  'Upserve',
  'Taster',
  'TaniHub',
  'Swiggy',
  'Squadle',
  'SpotOn',
  'Slice',
  'Shiftgig',
  'SevenRooms',
  'Seated',
  'Revel Systems',
  'Resy',
  'Rekki',
  'Qu',
  'Push Technologies',
  'Punchh',
  'Postmates',
  'Pod Foods',
  'Plate IQ',
  'Ordermark',
  'OpenTable',
  'Omnivore',
  'Olo',
  'Ninjacart',
  'Nectar',
  'Mswipe',
  'Melio Payments',
  'Meicai',
  'MarketMan',
  'MarginEdge',
  'Lunchbox',
  'LevelUp',
  'Lavu iPad PoS',
  'Kisan Network',
  'Kea',
  'Jumbotail',
  'Jolt',
  'iFood',
  'Honest Food Company',
  'Homebase',
  'Harri',
  'Grubhub',
  'GoParrot',
  'Glovo',
  'Galley',
  'Full Harvest',
  'Frubana',
  'Foodpanda',
  'FoodMaven',
  'Fishbowl',
  'Favor',
  'Fantuan',
  'EZTABLE',
  'Delivery Hero',
  'Deliveroo',
  'Deliverect',
  'Cuboh',
  'Cookpy',
  'Clover',
  'Clip',
  'City Pantry',
  'ChowNow',
  'Chowly',
  'Chowbus',
  'Choco',
  'ChefHero',
  'Cheetah',
  'Checkmate',
  'Caviar',
  'Box8',
  'BentoBox',
  'Belly',
  'Bbot',
  'Afresh Technologies',
  '7shifts',
  '2ndKitchen',
  'DoorDash',
  '10% Happier',
  'Big Sky Health',
  'Calm',
  'Headspace',
  'Insight Network',
  'Simple Habit',
  'Aura Health',
  'Meditopia',
  'Cerebral',
  'Foresight',
  'Mindstrong',
  'Octave',
  'Quartet Health',
  'SonderMind',
  'Two Chairs',
  'Alma',
  'Talkspace',
  'Sanvello',
  'Mantra Health',
  'Lyra Health',
  'Modern Health',
  'Ginger',
  'Dialogue',
  'Spring',
  'Manatee',
  'The Inner Hour',
  'Brightside Health',
  'AYANA',
  'Lifestance Health',
  '7 Cups',
  'Lark Technologies',
  'Wisdo',
  'Youper',
  'The Mighty',
  'Vent',
  'Coa',
  'BuddyHelp',
  'Happy',
  'Supportiv',
  'Wysa',
  'Commune',
  'X2AI',
  'Akili Interactive Labs',
  'Oxford VR',
  'Pear Therapeutics',
  'Limbix',
  'Meru Health',
  'Quit Genius',
  'LifeDojo',
  'Unmind',
  'Sukhi',
  'BigHealth',
  'Pivot',
  'Psylaris',
  'SidekickHealth',
  'Marigold Health',
  'Owl Insights',
  'Rx.Health',
  'Twic',
  'Concert Health',
  'NeuroFlow',

  'Wren',
  'Patch',
  'Watershed Climate',
  'EcoCart',
  'Pachama',
  'ClimateSeed',
  'Offsetra',
  'Reforestum',
  'Joro',
  'EmitWise',
  'SINAI',
  'Cloverly',
  'Climeworks',
  'inhabit',
  'Atmo',
  'Persefoni',
  'ClearTrace',
  'Descartes Underwriting',
  'Our Kettle',
  'Allphins',
  'Clarity',
  'Susfinteq',
  'TruValue Labs',
  'Sustainalytics',
  'right. based on science',
  'Cerulli',
  'Owl Analytics',
  'Label R',
  // eslint-disable-next-line @typescript-eslint/quotes
  "Who's Good",
  'FAIRR',
  'Mark Labs',
  'Cloud Agronomics',
  'GHGSat',
  'right. based on science',
  'net purpose',
  'BeCause',
  'Datamaran',
  'Zei',
  'Stratyfy',
  'Tarentum',
  'Vultus',
  'The Climate Service',
  'Turnkey Group',
  'Ulula',
  'Libryo',
  'SteerImpact',
  'ClimateView',
  'Terra.do',
  'SteerImpact',
  'Turnkey Group',
  'Social Value Portal',
  'ClimateAI',
  'Minimum',
  'PlanA.Earth',
  'Pawprint',
  'Klimate',
  'YAYZY',
  'Climaider',
  'Greenly',
  'CO2mpensio',
  'Jejak.in',
  'PLAN3T',
  'Economyz',
  'Kora',
  'Green Story',
  'Ecolyze',
  'Sylvera',
  'The Demex Group',
  'Planetly',
  'Scope 5',
  'Nori',
  'Clima Earth',
  'Datia',
  'Cooler Future',
  'OpenInvest',
  'tickr',
  'Inspire Impact',
  'Ethic',

  'ASAPP',
  'Attentive',
  'Aurora Solar',
  'Carbon Health',
  'Chainalysis',
  'KeepTruckin',
  'Public.com',
  'SendBird',
  'Sourcegraph',
  'Varo Money',

  'sustainable-endowments',
  'AuditBoard',
  'AxiomSL',
  'Boardable',
  'BoardPaq',
  'Capbase',
  'CaptivateIQ',
  'carta',
  'ChartHop',
  'Cognisess',
  'Compaas',
  'Concert',
  'Dealroom',
  'Diligent',
  'Diversity Dashboard',
  'Diverst',
  'DocSend',
  'eSight Energy',
  'Forma',
  'Galvanize',
  'Governance.com',
  'Honcho',
  'IsoMetrix',
  'Ledgy',
  'LogicGate',
  'Long-term-stock-exchange',
  'MetricStream',
  'Native',
  'NAVEX Global',
  'OnBoard',
  'OrgAnalytix',
  'OurOffice',
  'Pave',
  'Payfactors',
  'PayScale',
  'planetly',
  'Pluto',
  'Pulley',
  'Qlearsite',
  'Quaestor',
  'QuotaPath',
  'Riskonnect',
  'Spiff',
  'sylvera',
  'Syndio',
  'Teleskope',
  'The Org',
  'VComply Technologies',
  'Visible',
  'Passageways',
  'ICLEI',
  'Endowments Institute',
  'Captable.IO',

  'Anitian',
  'Circit',
  'Compliance.ai',
  'Hummingbird',
  'Pridatect',
  'Jumbo',
  'OzoneAI',
  'Privacy',
  'Cookiebot',
  'CookiePro',
  'Iubenda',
  'Metomic',
  'Osano',
  'Secure Privacy',
  'Sourcepoint',
  'Syrenis',
  'TRUENDO',
  'Dawex',
  'DPOrganizer',
  'Evervault',
  'InCountry',
  'Netwrix',
  'Odaseva',
  'Okera',
  'Very Good Security',
  'ComplianceBoard',
  'DataGrail',
  'Integris Software',
  'Privacy Radius',
  'Transcend',
  'WireWheel',
  'RADAR First',
  'DataFleets',
  'Hazy',
  'SentiLink',
  'Statice',
  'Tonic.ai',
  '1touch.io',
  'BigID',
  'Canopy',
  'Clarip',
  'Dataguise',
  'Ohalo',
  'Privitar',
  'Proteus Cyber',
  // 'Qohash',
  'Securiti.ai',
  // 'Ethyca',
  'Hyperproof',
  'Immuta',
  'OneTrust',
  'Blissfully',
  'Intello',
  'SureCloud',
  'Aptible',
  'Secureframe',
  'Shujinko',
  'Tugboat Logic',
  'Vanta',
  'WorkOS',

  'ALTR',
  'apheris AI',
  'Cape Privacy',
  'Concord',
  'D-ID',
  'Dathena',
  'Drata',
  'Ethyca',
  'Integris',
  'Ketch',
  'Pimloc',
  'Prifender',
  'Pryv',
  'Qohash',
  'Rectify',
  'Sarus',
  'SECURITI',
  'Skyflow',

  'Oyster®',

  'Atomic FI',
  'BambooHR',
  'Beqom',
  'Certino',
  'CloudPay',
  'Concert Finance',
  'DailyPay',
  'Deel',
  'Earnin',
  'Everee',
  'Factorial',
  'Finch',
  'Forma Ai',
  'Ganaz',
  'GoCo.io Inc',
  'GreenSlate',
  'GreytHR',
  'Gusto',
  'Hastee',
  'Hourly',
  'Instant Financial',
  'Justworks',
  'LASSO',
  'Merge',
  'Motive, ex KeepTruckin',
  'Nowsta',
  'OnPay',
  'Papaya Global',
  'PayAnalytics',
  'PayFit',
  'Payslip',
  'Pento',
  'Personio',
  'Pinwheel',
  'Rain',
  'RemoteTeam.com',
  'Rippling',
  'Runa HR',
  'SafetyCulture',
  'Salary Finance',
  'Spktral',
  'Squire',
  'SuperAnnotate',
  'Wage',
  'Wagestream',
  'Wrapbook',
  'minu',
];
