import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { laptopSize, desktopSize, mobileSize, smallMobileSize } from '../../../util/variables';
import theme from '../../../util/theme';
import stubData from './stubData';
import { valuesIn } from 'lodash';
import { AnimatedLinkAnchor, AppText } from '../../ui/';
import { useState } from 'react';

const OurInsightsList: JSX.Element[] = filterOption => {
  const filtered = stubData.filter(item => {
    if (filterOption.filterOption === 'All') {
      return true;
    }

    return item.type === filterOption.filterOption;
  });

  return filtered.map(({ id, image, title, description, type, date, button, link }: any) => {
    return (
      <InsightItem key={id}>
        <a target="_blank" rel="noopener noreferrer" href={link}>
          <InsightImg src={image} />
          <Insighth4>
            {date} / {type}
          </Insighth4>
          <Insighth3>{title}</Insighth3>
          <Insightp>{description}</Insightp>
          <AnimatedLinkAnchor target="_blank" fontSize="20px" to={link}>
            {button}
          </AnimatedLinkAnchor>
        </a>
      </InsightItem>
    );
  });
};

const InsightImg = styled.img`
  width: 100%;
`;

const Insightp = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  padding: 0;
  font-weight: 300;
  max-width: 100%;
  min-width: 200px;
  line-height: 32px;
  font-size: 20px;
  margin-top: 13px;
  margin-bottom: 42px;
  @media ${mobileSize} {
    margin-top: 7px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px;
    max-width: 100%;
  }
`;
const Insighth3 = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  padding: 0;
  font-weight: 300;
  width: 100%;
  font-size: 28px;
  line-height: 36px;
  margin-top: 17px;
  @media ${mobileSize} {
    font-size: 24px;
    max-width: 100%;
    line-height: 34px;
    margin-top: 5px;
  }
`;

const Insighth4 = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  padding: 0;
  font-weight: 300;
  width: 100%;
  font-size: 18px;
  opacity: 0.4;
  line-height: 26px;
  margin-top: 54px;
  @media ${mobileSize} {
    font-size: 14px;
    line-height: 21px;
    margin-top: 25px;
  }
`;

const InsightItem = styled.div`
  max-width: 535px;
  min-width: 200px;
  /* margin: 40px; */
  margin-bottom: 100px;
  a {
    margin: 0;
    padding: 0;
    padding-bottom: 6px;
    font-weight: 300;
    text-decoration: none;
    color: ${theme.BLACK_COLOR};
  }
  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
  }
  @media ${mobileSize} {
    max-width: 90vw;
    margin-bottom: 20px;
    a {
      font-size: 18px;
      padding-bottom: 3px;
    }
  }
`;

export { OurInsightsList };
