import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { laptopSize, desktopSize, mobileSize } from '../../../util/variables';
import theme from '../../../util/theme';
import { AppText, AnimatedDiv } from '../../ui';

const TermsOfUse: React.FC = () => {
  return (
    <OurTermsWrapper>
      <TermsOfUseContainer>
        <AnimatedDiv delay="100">
          <Pressh2>Terms of Use</Pressh2>
        </AnimatedDiv>
        <AnimatedDiv delay="300">
          <Pressh1>User Agreement</Pressh1>

          <Pressp>
            Welcome to the Base10 Partners Capital Management, LLC (“Base10 Partners”, “we”, “us” or
            “our”) website (“Website”). By accessing the Website, you agree to comply with this
            binding user agreement (“User Agreement”) between you and Base10 Partners, which governs
            your access and use of the Website and is a legally binding agreement between you and
            Base10 Partners. If you do not accept the terms and conditions of this User Agreement,
            do not use the Website. Your use of the Website indicates your full acceptance of this
            User Agreement in its then-current form each time you use the Website. Base10 Partners
            reserves the right to change the terms and conditions at any time. If we change this
            User Agreement, we will give you notice by posting the revised User Agreement on the
            Website. We may also post a notice on the Website and/or inform you via e-mail. The
            changes will go into effect on the effective date shown in the revised User Agreement.
            If you do not agree with the changes in the User Agreement, your sole remedy is to
            discontinue the use of the Website. By continuing to use the Website after the new
            effective date, you agree to be bound by such changes.
          </Pressp>

          <Pressp>
            The information that we provide is for your information only. Some information provided
            may not be current, or may have changed since the last time you viewed or downloaded it.
            All information is subject to change without notice. We do not in any way represent or
            warrant the accuracy or reliability of any of the information provided, and all
            information is provided subject to the disclaimers and limitations of liability set
            forth below.
          </Pressp>

          <Pressh1>Security Interests</Pressh1>

          <Pressp>
            The material contained in the Website is intended only for financially sophisticated
            investors and is for their private use. Base10 Partners private investment funds rely on
            private placement exemptions to registration under the Securities Act of 1933 (or the
            securities laws of any states) and have not been registered as investment companies
            under the Investment Company Act of 1940. In addition, neither Base10 Partners nor any
            affiliate thereof is registered with the United States Securities and Exchange
            Commission as an investment adviser under the Investment Advisers Act of 1940.
          </Pressp>

          <Pressh1>Informational Purposes Only</Pressh1>

          <Pressp>
            Nothing on the Website is an offer or solicitation to buy or sell any security, and
            Base10 Partners is not soliciting any action based on the Website. Nothing on the
            Website is a recommendation that you purchase, sell or hold any security, or that you
            pursue any investment style or strategy. Nothing on the Website is intended to be, and
            you should not consider anything on the Website to be, investment, accounting, tax or
            legal advice. The past performance of any investment, investment strategy or investment
            style is not necessarily indicative of future performance.
          </Pressp>

          <Pressh1>Ownership and Proprietary Rights to Content</Pressh1>

          <Pressp>
            The Website is owned and operated by Base10 Partners. Any claims or concerns regarding
            the Website should be addressed to: Base10 Partners Management, LLC, 727 Sansome st, Ste
            300, San Francisco, CA 94111
          </Pressp>

          <Pressp>
            You acknowledge and agree that the Website contains information, data, software,
            photographs, graphics, text, images, logos, icons, typefaces, audio and video material,
            and/or other material protected by copyrights, trademarks, or other proprietary rights,
            and that these rights are valid and protected in all forms, media, and technologies
            existing now or hereinafter developed. The content of the Website is the property of
            Base10 Partners or that of our suppliers or licensors and is protected by U.S. and
            international copyright laws. You may not modify, remove, delete, augment, add to,
            publish, transmit, participate in the transfer of, license, sell, create derivative
            works from, or in any way exploit any of the content, in whole or in part. You may not
            upload, post, reproduce, perform, or distribute in any way any content without obtaining
            permission of the owner of the copyright, trademark or other proprietary right.
          </Pressp>

          <Pressh1>Trademarks</Pressh1>

          <Pressp>
            Base10 Partners, and its logos are trademarks of Base10 Partners. They may not be used
            or displayed without Base10 Partners’ prior written consent. All other trademarks,
            product names and company names and logos appearing on the Website are the property of
            their respective owners, and may be used only with the permission of the particular
            owner.
          </Pressp>

          <Pressh1>Online Privacy</Pressh1>

          <Pressp>
            Base10 Partners does not collect any personally identifiable information about visitors
            to the publicly available areas of the Website, including names, addresses, phone
            numbers or social security numbers. Base10 Partners may collect and store any personal
            information that you knowingly provide to us. We do not disseminate the non-public
            personal information of our investors to any third parties unless in the furtherance of
            servicing their accounts or as required by law or regulation or as described in our
            privacy policy.
          </Pressp>

          <Pressh1>Disclaimers</Pressh1>

          <Pressp>You expressly understand and agree that:</Pressp>
          <Pressol>
            <li>
              YOUR USE OF THE WEBSITE IS AT YOUR SOLE RISK. THE WEBSITE IS PROVIDED ON AN “AS IS”
              AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE
              LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
              INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
              PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
            </li>
            <li>
              We do not warrant or represent that (i) the Website will meet your requirements, (ii)
              the Website will be uninterrupted, timely, secure, or error-free, (iii) the results
              that may be obtained from the use of the Website will be accurate or reliable, (iv)
              the quality of any services, or information, or other material obtained by you through
              the Website will meet your expectations, (v) the server that makes the Website
              available is free of viruses or other harmful components; or (vi) any errors in any
              software or in the Website will be corrected.
            </li>
            <li>
              Any material downloaded or otherwise obtained through the use of the Website is done
              at your own discretion and risk and you will be solely responsible for any loss or
              damage incurred, including, without limitation, damage to your computer system or loss
              of data that results from the download of any such material. You (and not us or any of
              our affiliated entities or any of our or their respective licensors or suppliers)
              assume the entire cost of all necessary servicing, repair or correction to restore
              your system. No advice or information, whether oral or written, obtained by you from
              us or through or from the Website shall create any warranty not expressly stated in
              this User Agreement. Applicable law may not allow the exclusion of implied warranties,
              so the above exclusion may not apply to you.
            </li>
          </Pressol>
          <Pressh1>Limitation of Liability</Pressh1>

          <Pressp>
            You agree that we shall not be liable for any direct, indirect, incidental, special or
            consequential damages, resulting from the use of or the inability to use the Website,
            including but not limited to, damages for loss of profits, use, data or other
            intangible, even if we have been advised of the possibility of such damages.
          </Pressp>

          <Pressp>
            Because some states do not allow the exclusion or limitation of liability for
            consequential or incidental damages, in such states liability is limited to the fullest
            extent permitted by law.
          </Pressp>

          <Pressp>
            If you are dissatisfied with any portion of the Website or service you receive through
            it, or with any of portion of this User Agreement, your sole and exclusive remedy is to
            discontinue using the Website.
          </Pressp>

          <Pressh1>Applicable Law</Pressh1>

          <Pressp>
            This User Agreement will be governed by and construed in accordance with the laws of the
            State of California, without regard to any principles of conflicts of law. You agree
            that any action at law or in equity that arises out of or relates to your use of the
            Website will be filed only in the state or federal courts located in Los Angeles,
            California, and you hereby consent and submit to the personal jurisdiction and venue of
            such courts for the purposes of litigating any such action.
          </Pressp>

          <Pressh1>International Use</Pressh1>

          <Pressp>
            We make no representation that materials on the Website are appropriate or available for
            use in locations outside the United States, and accessing them from territories where
            their contents are illegal is prohibited. Those who choose to access the Website from
            other locations do so on their own initiative and are responsible for compliance with
            local laws.
          </Pressp>

          <Pressh1>Other Third-Party Websites</Pressh1>

          <Pressp>
            The Website may contain links to websites controlled and maintained by third parties. We
            do not have any control over the content of these websites, and we assume no liability
            or responsibility for such sites, the content contained in them, or the manner in which
            any information collected on such sites is used.
          </Pressp>

          <Pressh1>Termination</Pressh1>

          <Pressp>
            We may terminate your access, or suspend any user’s access to all or part of the
            Website, without notice, for any conduct that we, in our sole discretion, believe is in
            violation of any applicable law or is harmful to the interests of another user, a
            third-party provider, a service provider, or us.
          </Pressp>

          <Pressh1>Miscellaneous</Pressh1>

          <Pressp>
            If any provision of this User Agreement should be held to be unenforceable or invalid
            for any reason, then such provision or portion thereof shall be modified or deleted in
            such manner as to render this User Agreement as modified legal and enforceable to the
            maximum extent permitted under applicable laws. This User Agreement, and any rights and
            licenses granted hereunder, may not be transferred or assigned by you, but may be
            assigned by Base10 Partners without restriction.
          </Pressp>
          <br />
          <Pressp>
            <i>Effective Date: August 28, 2018</i>
          </Pressp>
        </AnimatedDiv>
      </TermsOfUseContainer>
    </OurTermsWrapper>
  );
};

const Pressh2 = styled(AppText)`
  color: rgba(26, 26, 26, 0.6);
  font-family: ${theme.SECONDARY_FONT};
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 14px;
  margin: 0px;
  opacity: 1;
  text-transform: uppercase;
`;

const Pressh1 = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-size: 36px;
  font-weight: 300;
  letter-spacing: -0.8px;
  line-height: 50px;
  margin-bottom: 0px;
  margin-top: 39px;
  -moz-osx-font-smoothing: grayscale;
  @media ${mobileSize} {
    margin-top: 34px;
    font-size: 30px;
    line-height: 34px;
  }
`;

const Pressol = styled.ol`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 32px;
  letter-spacing: normal;
  color: rgba(26, 26, 26, 0.6);
  list-style: none;
  counter-reset: li;
  li {
    counter-increment: li;
    padding: 5px 0px 5px 20px;
  }
  li::before {
    content: counter(li) '.';
    color: ${theme.PRIMARY_COLOR};
    font-family: ${theme.SECONDARY_FONT};
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 2px;
    text-align: left;
    display: inline-block;
    width: 30px;
    margin-left: -30px;
  }
  @media ${mobileSize} {
    font-size: 18px;
    line-height: 24px;
    padding-right: 0;
    li {
      font-size: 18px;
      line-height: 24px;
      padding: 0px 0px 0px 0px;
    }
    li::before {
      width: 20px;
      margin-left: -20px;
    }
  }
`;

const Pressp = styled(AppText)`
  color: rgba(26, 26, 26, 0.8);
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: 300;
  letter-spacing: normal;
  margin-top: 20px;
  line-height: 32px;
  @media ${mobileSize} {
    font-size: 18px;
    line-height: 24px;
  }
`;

const TermsOfUseContainer = styled.div`
  max-width: 915px;
`;

const OurTermsWrapper = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: 250px 100px 100px 100px;
  @media ${mobileSize} {
    padding: 200px 25px 60px 25px;
  }
`;

export { TermsOfUse };
