import * as React from 'react';
import styled from 'styled-components';
import theme from '../../../util/theme';
import { laptopSize, mobileSize } from '../../../util/variables';
import { AppText, AnimatedDiv } from '../../ui/';

const OurCompaniesHeading: React.FC = React.memo(() => {
  return (
    <OurCompaniesContainer>
      <OurCompaniesWrapper>
        <OurCompaniesContent>
          <AnimatedDiv delay="300">
            <OurCompaniesh2>OUR COMPANIES</OurCompaniesh2>
          </AnimatedDiv>
          <AnimatedDiv delay="500">
            <OurCompaniesh3>
              We invest in companies that are creating a positive impact for the largest sectors of
              the real economy.
            </OurCompaniesh3>
          </AnimatedDiv>
        </OurCompaniesContent>
      </OurCompaniesWrapper>
    </OurCompaniesContainer>
  );
});

// const OurCompaniesList = styled.ul`
//   padding: 0px;
//   list-style: none;
//   padding-left: 30px;
//   @media ${mobileSize} {
//     padding-left: 20px;
//   }
// `;

// const OurCompaniesListItem = styled.li`
//   padding: 0;
//   margin: 0;
//   color: rgba(255, 255, 255, 0.6);
//   font-family: ${theme.PRIMARY_FONT};
//   position: relative;
//   display: list-item;
//   text-align: -webkit-match-parent;
//   font-size: 20px;
//   font-weight: 300;
//   line-height: 32px;
//   ::before {
//     content: '·';
//     right: auto;
//     left: 0;
//     -webkit-transform: translateX(-30px);
//     transform: translateX(-30px);
//     font-size: 32px;
//     line-height: 32px;
//     color: ${theme.PRIMARY_COLOR};
//     position: absolute;
//   }
//   @media ${mobileSize} {
//     font-size: 18px;
//     line-height: 24px;
//     ::before {
//       -webkit-transform: translateX(-20px);
//       transform: translateX(-20px);
//       font-size: 32px;
//       line-height: 24px;
//     }
//   }
// `;

const OurCompaniesh3 = styled(AppText)`
  padding: 0px;
  margin: 0px;
  font-family: ${theme.PRIMARY_FONT};
  font-weight: bold;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: 0.2px;
  margin-top: 24px;
  margin-bottom: 45px;
  color: ${theme.NEW_BLACK_COLOR};
  @media ${mobileSize} {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.2px;
    margin-top: 16px;
    margin-bottom: 0px;
  }
`;

// const OurCompaniesh1 = styled.h1<{ makeBlue: boolean }>`
//   color: ${props => (props.makeBlue ? theme.PRIMARY_COLOR : 'white')};
//   display: inline;
//   font-family: ${theme.PRIMARY_FONT};
//   font-size: 80px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1;
//   letter-spacing: -2px;
//   @media ${laptopSize} {
//     font-size: 60px;
//   }
//   @media ${mobileSize} {
//     font-size: 45px;
//   }
// `;

const OurCompaniesContainer = styled.div`
  padding-top: 120px;
  background-color: ${theme.WHITE_COLOR};
  display: block;
  width: 100%;
`;

const OurCompaniesWrapper = styled.div`
  margin-right: auto;
  margin-left: auto;
  background-color: ${theme.WHITE_COLOR};
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  ::after {
    clear: both;
    content: '';
    display: block;
  }
  @media ${mobileSize} {
    margin: 0px;
  }
`;

const OurCompaniesContent = styled.div`
  width: 1400px;
  float: left;
  padding: 108px 30px 48px 30px;
  margin: 0px 8.5%;
  @media ${mobileSize} {
    margin: 0px;
    padding: 32px 24px;
    padding-top: 5px;
    float: left;
  }
`;

const OurCompaniesh2 = styled(AppText)`
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 400;
  color: ${theme.GREY_700};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  @media ${mobileSize} {
    letter-spacing: 1px;
  }
`;

export { OurCompaniesHeading };
