import * as React from 'react';
import styled from 'styled-components';
import { mobileSize } from '../../../util/variables';
import { Wrapper, AppTitle, AppText, AnimatedDiv } from '../../ui';
import theme from '../../../util/theme';

const IndexMissionSection: React.FC = () => {
  return (
    <MissionContainer id="our-mission">
      <Wrapper>
        <ContentContainer>
          <AnimatedDiv delay="100">
            <OurMission>OUR MISSION</OurMission>
          </AnimatedDiv>
          <AnimatedDiv delay="300">
            <MissionDescription>
              In the path to automation, humans take center stage.
              <br />
              We help transform the economy by helping every sector adapt to change.
            </MissionDescription>
          </AnimatedDiv>
        </ContentContainer>
      </Wrapper>
    </MissionContainer>
  );
};

const MissionContainer = styled.div`
  width: 100%;
  display: flex;
  background: white;
`;

const ContentContainer = styled.div`
  padding-top: 175px;
  padding-bottom: 175px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: calc(8.33333% - 2.03125rem + 3.75rem);
  margin-right: calc(8.33333% - 2.03125rem + 3.75rem);
  @media ${mobileSize} {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const OurMission = styled(AppText)`
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 400;
  color: rgba(26, 26, 26, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  @media ${mobileSize} {
    letter-spacing: 1px;
  }
`;

const MissionDescription = styled(AppTitle)`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-weight: 300;
  font-size: 36px;
  line-height: 1.38889;
  letter-spacing: -0.75px;
  margin: 0;
  padding: 0;
  @media ${mobileSize} {
    font-size: 30px;
    line-height: 33.75px;
    font-weight: 300;
  }
`;

export { IndexMissionSection };
