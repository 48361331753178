import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { TextCycler, AnimatedDiv } from '../../ui';
import { laptopSize, desktopSize, mobileSize } from '../../../util/variables';
import Slider from 'react-slick';
import { Scrambler } from 'react-text-scrambler';
import theme from '../../../util/theme';
import img1 from '../../../content/img/homepage/logo-ribbit-capital.png';
import img2 from '../../../content/img/homepage/logo-bessemer.png';
import img3 from '../../../content/img/homepage/logo-shasta-ventures.png';
import img4 from '../../../content/img/homepage/logo-bain-capital-ventures.png';
import img5 from '../../../content/img/homepage/logo-fifth-wall.png';
import img6 from '../../../content/img/homepage/logo-owl-ventures.png';
import partnersImage from '../../../content/img/homepage/our-partners-crop.jpg';

const strings = [
  'Fintech',
  'SaaS',
  'Consumer',
  'Shipping and logistics',
  'Real Estate',
  'Education',
];

const localImages = [img1, img2, img3, img4, img5, img6];

const images = [
  'https://archive.base10.vc/wp-content/uploads/2018/08/logo-ribbit-capital.png',
  'https://archive.base10.vc/wp-content/uploads/2018/08/logo-bessemer.png',
  'https://archive.base10.vc/wp-content/uploads/2019/02/logo-shasta-ventures.png',
  'https://archive.base10.vc/wp-content/uploads/2019/02/logo-bain-capital-ventures.png',
  'https://archive.base10.vc/wp-content/uploads/2018/08/logo-fifth-wall.png',
  'https://archive.base10.vc/wp-content/uploads/2018/08/logo-owl-ventures.png',
];

const settings = {
  dots: false,
  fade: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 4000,
  arrows: false,
};

const IndexOurPartnersSegment: React.FC = () => {
  const [arrayIndex, setArrayIndex] = React.useState(0);

  React.useEffect(() => {
    let nextIndex = arrayIndex;
    if (nextIndex >= 5) {
      nextIndex = 0;
    } else {
      nextIndex++;
    }

    const interval = setInterval(() => setArrayIndex(nextIndex), 3000);
    return () => {
      clearInterval(interval);
    };
  }, [arrayIndex]);

  return (
    <OurPartnersContainer>
      <OurPartnersImageContainer>
        <AnimatedDiv sal="slide-up">
          <OurPartnersImage />
        </AnimatedDiv>
      </OurPartnersImageContainer>
      <OurPartnersInfoContainer>
        <AnimatedDiv delay="100">
          <OurPartnersh2>OUR PARTNERS</OurPartnersh2>
        </AnimatedDiv>
        <AnimatedDiv delay="300">
          <OurPartnersh3>
            We understand Automation, and we work with others that understand{' '}
            <OurPartnersh3blue>
              <Scrambler
                renderIn={1000}
                changeFrom={arrayIndex === 0 ? strings[5] : strings[arrayIndex - 1]}
                text={strings[arrayIndex]}
              />
            </OurPartnersh3blue>
            .
          </OurPartnersh3>
        </AnimatedDiv>
        <AnimatedDiv delay="500">
          <SliderContainer>
            {/* <img
              style={{ transition: 'all 500ms fade-in;' }}
              src={images[arrayIndex]}
              alt=""
            /> */}
            <PartnersImagesDiv>
              <PartnerImgContainer number={0} arrayIndex={arrayIndex}>
                <CurrentPartnerImage src={img1} />
              </PartnerImgContainer>
              <PartnerImgContainer number={1} arrayIndex={arrayIndex}>
                <CurrentPartnerImage src={img2} />
              </PartnerImgContainer>
              <PartnerImgContainer number={2} arrayIndex={arrayIndex}>
                <CurrentPartnerImage src={img3} />
              </PartnerImgContainer>
              <PartnerImgContainer number={3} arrayIndex={arrayIndex}>
                <CurrentPartnerImage src={img4} />
              </PartnerImgContainer>
              <PartnerImgContainer number={4} arrayIndex={arrayIndex}>
                <CurrentPartnerImage src={img5} />
              </PartnerImgContainer>
              <PartnerImgContainer number={5} arrayIndex={arrayIndex}>
                <CurrentPartnerImage src={img6} />
              </PartnerImgContainer>
            </PartnersImagesDiv>
            {/* <Slider {...settings}>
            <div>
              Fintech{' '}
              <img
                src="https://archive.base10.vc/wp-content/uploads/2018/08/logo-ribbit-capital.png"
                alt=""
              />{' '}
            </div>
            <div>
              SaaS{' '}
              <img src="https://archive.base10.vc/wp-content/uploads/2018/08/logo-bessemer.png" alt="" />{' '}
            </div>
            <div>
              Consumer{' '}
              <img
                src="https://archive.base10.vc/wp-content/uploads/2019/02/logo-shasta-ventures.png"
                alt=""
              />{' '}
            </div>
            <div>
              Shipping and logistics{' '}
              <img
                src="https://archive.base10.vc/wp-content/uploads/2019/02/logo-bain-capital-ventures.png"
                alt=""
              />{' '}
            </div>
            <div>
              Real Estate{' '}
              <img src="https://archive.base10.vc/wp-content/uploads/2018/08/logo-fifth-wall.png" alt="" />{' '}
            </div>
            <div>
              Education{' '}
              <img
                src="https://archive.base10.vc/wp-content/uploads/2018/08/logo-owl-ventures.png"
                alt=""
              />{' '}
            </div>
          </Slider> */}
          </SliderContainer>
        </AnimatedDiv>
      </OurPartnersInfoContainer>
    </OurPartnersContainer>
  );
};

interface PartnersProps {
  arrayIndex: number;
  number: number;
}

const PartnersImagesDiv = styled.div`
  position: relative;
  padding-bottom: 100px;
  @media ${laptopSize} {
    padding-bottom: 220px;
  }
  @media ${mobileSize} {
    padding-bottom: 100px;
  }
`;

const PartnerImgContainer = styled.div<PartnersProps>`
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${props => (props.arrayIndex === props.number ? 1 : 0)};
  padding: 0;
  margin: 0;
  -webkit-transition: all 1500ms ease;
  -moz-transition: all 1500ms ease;
  -o-transition: all 1500ms ease;
  -ms-transition: all 1500ms ease;
  transition: all 1500ms ease;
`;

const CurrentPartnerImage = styled.img<PartnersProps>`
  display: ${props => (props.arrayIndex === props.number ? 'block' : 'none')};
  max-width: 350px;
  max-height: 150px;
  @media ${laptopSize} {
    max-width: 600px;
    max-height: 180px;
  }
  @media ${mobileSize} {
    max-width: 60vw;
    max-height: 100px;
  }
`;

const OurPartnersContainer = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  background: ${theme.BLACK_COLOR};

  /* height: 600px; */
  @media ${laptopSize} {
    display: block;
    width: 100%;
  }
`;

//http://34.75.205.77/wp-content/uploads/2018/07/our-partners.jpg

const OurPartnersImageContainer = styled.div`
  width: 50vw;
  height: 50vw;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: black;
  @media ${mobileSize} {
    float: left;
    display: block;
    width: 100%;
    transform: none;
    max-height: 30vh;
    min-height: 15rem;
  }
  @media ${laptopSize} {
    display: block;
    transform: none;
    float: left;
    display: block;
    width: 100%;
    max-height: 30vh;
    min-height: 15rem;
  }
  @media ${desktopSize} {
    display: block;
    width: 100%;
  }
`;

const OurPartnersImage = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
  height: auto;
  background-color: transparent;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${partnersImage});
  @media ${laptopSize} {
    background-position: center 25%;
  }
  @media ${mobileSize} {
    max-height: 30vh;
    min-height: 25rem;
  }
`;

const OurPartnersInfoContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding: 12.5rem;
  background: ${theme.BLACK_COLOR};
  width: 50vw;
  height: 50vw;
  margin: 0 auto;
  @media ${desktopSize} {
    padding: 8.325rem;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media ${laptopSize} {
    width: calc(83.33333% - 3.4375rem);
    float: left;
    margin-left: 1.875rem;
    margin-left: calc(8.33333% - 2.03125rem + 3.75rem);
    padding-top: 100px;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 100px;
    transform: none;
    /* min-height: 50vh; */
    min-height: 450px;
  }
  @media ${mobileSize} {
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
    padding: 80px 0px;
    min-height: auto;
    height: auto;
  }
`;

const OurPartnersh2 = styled.h2`
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  margin-top: 10px;
  @media ${mobileSize} {
    letter-spacing: 1px;
  }
`;

const OurPartnersh3blue = styled.h3`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 300;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: -0.8px;
  display: inline;
  color: ${theme.PRIMARY_COLOR};
  font-weight: 700;
  @media ${mobileSize} {
    font-size: 30px;
    line-height: 33.75px;
    letter-spacing: -0.75px;
  }
`;

const OurPartnersh3 = styled.h3`
  font-family: ${theme.PRIMARY_FONT};
  font-weight: 300;
  font-size: 36px;
  line-height: 50px;
  letter-spacing: -0.8px;
  margin-top: 0px;
  color: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    font-size: 30px;
    line-height: 33.75px;
    letter-spacing: -0.75px;
  }
`;

const SliderContainer = styled.div`
  -webkit-transition: all 1500ms ease-in-out;
  -moz-transition: all 1500ms ease-in-out;
  -o-transition: all 1500ms ease-in-out;
  -ms-transition: all 1500ms ease-in-out;
  transition: all 1500ms ease-in-out;
  img {
    margin-top: 20px;
    max-width: 170px;
    max-height: 75px;
  }
  @media ${mobileSize} {
    img {
      max-height: 100px;
      max-width: 40hw;
    }
  }
`;

export { IndexOurPartnersSegment };
