import React, { FunctionComponent, useState, useEffect, useMemo, useReducer } from 'react';
import styled from 'styled-components';
import theme from '../../../util/theme';
import { mobileSize, laptopSize, desktopSize } from '../../../util/variables';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { AnimatedLinkAnchor, AppText, AnimatedSpan } from '../../ui';
import ReactTooltip from 'react-tooltip';
import { useStaticQuery, graphql } from 'gatsby';
import { gql, useMutation } from '@apollo/client';
import { TrendMapModal } from './TrendMapModal';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { GraphsComponent } from './GraphsComponent';

interface Props {
  data: any;
}

const listColors = ['#45aeeb', '#3586b5', '#8bd5ff'];

function filterReducer(state: any, action: any) {
  switch (action.type) {
    case 'SEGMENT_FILTER':
      return { ...state, segmentFilter: action.filter };
    case 'LOCATION_FILTER':
      return { ...state, locationFilter: action.filter };
    case 'STAGE_FILTER':
      return { ...state, stageFilter: action.filter };
    case 'AMOUNT_FILTER':
      return {
        ...state,
        amountFilterLowerBound: action.filter.lowerBound,
        amountFilterUpperBound: action.filter.upperBound,
      };
    case 'PEOPLE_FILTER':
      return {
        ...state,
        peopleFilterLowerBound: action.filter.lowerBound,
        peopleFilterUpperBound: action.filter.upperBound,
      };
    case 'INVESTOR_FILTER':
      return { ...state, investorFilter: action.filter };
    default:
      return { ...state };
  }
}

const initialState = {
  segmentFilter: 'All',
  locationFilter: 'All',
  stageFilter: 'All',
  amountFilterLowerBound: 0,
  amountFilterUpperBound: 0,
  peopleFilterLowerBound: 0,
  peopleFilterUpperBound: 0,
  investorFilter: 'All',
};

const filterCompanies = (companies: any, filters: any) => {
  return companies
    .filter((c: any) => {
      if (filters.segmentFilter === 'All') {
        return true;
      }
      return c.section_title === filters.segmentFilter;
    })
    .filter((c: any) => {
      if (filters.locationFilter === 'All') {
        return true;
      }
      return c.hq_location.includes(filters.locationFilter);
    })
    .filter((c: any) => {
      if (filters.stageFilter === 'All') {
        return true;
      }
      return c.stage.includes(filters.stageFilter);
    })
    .filter((c: any) => {
      if (filters.amountFilterUpperBound === 0) {
        return true;
      }
      const amount_raised = Number(c.amount_raised.slice(1, -1));
      return (
        amount_raised >= filters.amountFilterLowerBound &&
        amount_raised < filters.amountFilterUpperBound
      );
    })
    .filter((c: any) => {
      if (filters.peopleFilterUpperBound === 0) {
        return true;
      }
      return (
        c.people >= filters.peopleFilterLowerBound && c.people < filters.peopleFilterUpperBound
      );
    })
    .filter((c: any) => {
      if (filters.investorFilter === 'All') {
        return true;
      }
      return c.key_investors.includes(filters.investorFilter);
    });
};

const OurShopifyInsightsSegment: FunctionComponent<Props> = () => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        allAmountOfFundingJson {
          nodes {
            amount
            id
            year
          }
        }
        allCompaniesMapJson {
          nodes {
            id
            lat
            logo_url
            long
            name
          }
        }
        allCircleNumbersJson {
          nodes {
            id
            number
            title
          }
        }
        allCumulativeRaisedJson {
          nodes {
            amount
            id
            year
          }
        }
        allKeyInsightsListJson {
          nodes {
            id
            head
            ordinal
            text
          }
        }
        allMostActiveInvestorsJson {
          nodes {
            id
            avg_check
            investments
            investor_image
            investor_name
            key_investors
          }
        }
        allNumberOfUnicornsJson {
          nodes {
            companies
            id
            label
            number
            year
          }
        }
        allResearchCompaniesJson {
          nodes {
            id
            section_title
            tooltip
            rows {
              amount_raised
              description
              founded
              founders {
                name
                link
              }
              hq_location
              key_investors
              name
              segment
              stage
              website
              people
            }
          }
        }
        allTopCompaniesInvestorsJson {
          nodes {
            avg_check
            id
            investments
            investor_image
            investor_name
            key_investors
          }
        }
        allShopifyDataJson {
          nodes {
            america
            canada
            europe
            id
          }
        }
      }
    `,
  );

  const POST_MUTATION = gql`
    mutation creatingMarketingEmail($email: String!, $source: String!) {
      createOneMarketingEmail(data: { email: $email, source: $source }) {
        id
        email
      }
    }
  `;

  const [addEmailData, { emailData }] = useMutation(POST_MUTATION);

  const researchCompanies = useMemo(
    () =>
      data.allResearchCompaniesJson.nodes.reduce(
        (tot, x) => [...tot, ...x.rows.map(r => ({ ...r, section_title: x.section_title }))],
        [],
      ),
    [],
  );

  const section_titles = useMemo(
    () =>
      data.allResearchCompaniesJson.nodes.map((st, i) => ({
        section_title: st.section_title,
        tooltip: st.tooltip,
        id: i,
      })),
    [],
  );

  const uniqueSegments = useMemo(
    () =>
      researchCompanies
        .map(c => c.section_title)
        .reduce((tot, x) => (tot.includes(x) ? tot : [...tot, x]), [])
        .sort(),
    [],
  );

  const uniqueLocations = useMemo(
    () =>
      researchCompanies
        .reduce((tot, x) => [...tot, x.hq_location.split(', ').pop()], [])
        .reduce((tot, x) => (tot.includes(x) ? tot : [...tot, x]), [])
        .filter(i => i !== 'N/A')
        .sort(),
    [],
  );

  const uniqueInvestors = useMemo(
    () =>
      researchCompanies
        .reduce((tot, x) => [...tot, ...x.key_investors], [])
        .reduce((tot, x) => (tot.includes(x) ? tot : [...tot, x]), [])
        .filter(i => i !== 'N/A')
        .sort(),
    [],
  );

  const uniqueStages = useMemo(
    () =>
      researchCompanies
        .reduce((tot, x) => [...tot, x.stage], [])
        .reduce((tot, x) => (tot.includes(x) ? tot : [...tot, x]), [])
        .sort(),
    [],
  );

  const amountsKey = [
    { lowerBound: 0, upperBound: 0 },
    { lowerBound: 0, upperBound: 5 },
    { lowerBound: 6, upperBound: 50 },
    { lowerBound: 51, upperBound: 100 },
    { lowerBound: 101, upperBound: 10000000 },
  ];

  const uniqueAmounts = [
    { label: 'Any Amount Raised', value: 0 },
    { label: '$0 to 5M', value: 1 },
    { label: '$6M to 50M', value: 2 },
    { label: '$51M to 100M', value: 3 },
    { label: '$100M+', value: 4 },
  ];

  const peopleKey = [
    { lowerBound: 0, upperBound: 0 },
    { lowerBound: 1, upperBound: 100 },
    { lowerBound: 101, upperBound: 200 },
    { lowerBound: 201, upperBound: 300 },
    { lowerBound: 301, upperBound: 400 },
    { lowerBound: 401, upperBound: 500 },
  ];

  const uniquePeople = [
    { label: 'People', value: 0 },
    { label: '0 to 100', value: 1 },
    { label: '101 to 200', value: 2 },
    { label: '201 to 300', value: 3 },
    { label: '301 to 400', value: 4 },
    { label: '401 to 500', value: 5 },
  ];

  const [showMore, setShowMore] = useState([]);
  const [guestEmail, setGuestEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [filters, dispatch] = useReducer(filterReducer, initialState);

  const toggleModal = () => {
    trackCustomEvent({
      action: 'Click',
      category: `Footnote`,
      label: `Companies map modal ${showModal ? 'close' : 'open'}`,
    });
    setShowModal(!showModal);
  };

  const filteredCompanies = useMemo(() => filterCompanies(researchCompanies, filters), [filters]);

  const MoreButton: JSX.Element = (props: any) => {
    return (
      <MoreButtonStyle
        onClick={() => {
          trackCustomEvent({
            action: 'Click',
            category: `Table: ${props.table}`,
            label: `${showMore.includes(props.id) ? 'less' : 'more'} click on ${props.column}`,
          });
          showMore.includes(props.id)
            ? setShowMore(showMore.filter(i => i !== props.id))
            : setShowMore(showMore.concat(props.id));
        }}
      >
        {showMore.includes(props.id) ? 'less' : 'more'}
      </MoreButtonStyle>
    );
  };

  const handleEmailSubmit = async (e: any): Promise<any> => {
    e.preventDefault();
    trackCustomEvent({ action: 'Click', category: 'Email', label: 'Email button click' });
    if (guestEmail.includes('@') && guestEmail.trim().length > 6 && guestEmail.includes('.')) {
      await addEmailData({ variables: { email: guestEmail, source: 'shopifyInsights' } });
      setGuestEmail('Email address submitted!');
      trackCustomEvent({
        action: 'Save',
        category: 'Email',
        label: 'Email submissions successful',
      });
    } else {
      setGuestEmail('Invalid email address!');
      trackCustomEvent({
        action: 'Save',
        category: 'Email',
        label: 'Email submission validation failure',
      });
    }
  };

  return (
    <ShopifyInsightsContainer>
      <ReactTooltip
        multiline={true}
        place="top"
        effect="solid"
        css={{
          maxWidth: 300,
          lineHeight: 1.4,
          textAlign: 'center',
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor="rgba(0,0,0,0.9)"
      />
      <TrendMapModal toggleModal={toggleModal} showModal={showModal} />
      <EmailForm onSubmit={handleEmailSubmit}>
        <EmailSubmitButton type="submit">Sign up</EmailSubmitButton>
        <EmailTextInput
          type="email"
          name="email"
          value={guestEmail}
          placeholder="Enter email here..."
          data-tip="If you’re interested in updates to our research or new research we publish, please enter your email address here"
          data-place="bottom"
          onChange={e => setGuestEmail(e.target.value)}
          onFocus={() =>
            trackCustomEvent({ action: 'Click', category: 'Email', label: 'Email input click' })
          }
        />
      </EmailForm>
      <SummaryTitle>
        <SummaryTitleText>Summary of</SummaryTitleText>
        &nbsp;
        <SummaryTitleText bold>
          Base10 Trend: Commerce Enablement Tools for the Retail Industry
        </SummaryTitleText>
      </SummaryTitle>
      <KeyInsightsListTitle>Base10 key insights*</KeyInsightsListTitle>
      <KeyInsightsListItems>
        <ol>
          {data.allKeyInsightsListJson.nodes.map(item => (
            <li key={item.id} data-ordinal={item.ordinal}>
              <b style={{ fontSize: '16px' }}>{item.head}:</b> {item.text}
            </li>
          ))}
        </ol>
      </KeyInsightsListItems>
      <KeyInsightsFootnote>
        * &nbsp;
        <AnimatedLinkAnchor
          to="https://base10.substack.com/p/faster-than-fast-smb-retailers-move"
          target="_blank"
          fontSize="12px"
          paddingBottom="2px"
          underlineHeight="1px"
        >
          Click here
        </AnimatedLinkAnchor>{' '}
        to read more about Base10's insights into commerce enablement tools for the retail industry.
      </KeyInsightsFootnote>
      <GraphsComponent
        amountOfFunding={data.allAmountOfFundingJson.nodes}
        cumulativeRaised={data.allCumulativeRaisedJson.nodes}
        circleNumbers={data.allCircleNumbersJson.nodes}
        numberOfUnicorns={data.allNumberOfUnicornsJson.nodes}
      />
      <FiltersList>
        <FilterHeadingLabel>
          {data.allResearchCompaniesJson.nodes.reduce((tot, x) => tot + x.rows.length, 0)}{' '}
          Companies*
        </FilterHeadingLabel>
        <FilterByLabel>Filter by</FilterByLabel>
        <FitlerSelect
          name="segments"
          id="segments"
          onChange={e => {
            dispatch({ type: 'SEGMENT_FILTER', filter: e.target.value });
            trackCustomEvent({
              action: 'Click',
              category: `Filter`,
              label: `Segment filter change to ${e.target.value}`,
            });
          }}
        >
          <option value="All">All segments</option>
          {uniqueSegments.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </FitlerSelect>
        <FitlerSelect
          id="locations"
          onChange={e => {
            dispatch({ type: 'LOCATION_FILTER', filter: e.target.value });
            trackCustomEvent({
              action: 'Click',
              category: `Filter`,
              label: `Location filter change to ${e.target.value}`,
            });
          }}
        >
          <option value="All">All locations</option>
          {uniqueLocations.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </FitlerSelect>
        <FitlerSelect
          id="stages"
          onChange={e => {
            dispatch({ type: 'STAGE_FILTER', filter: e.target.value });
            trackCustomEvent({
              action: 'Click',
              category: `Filter`,
              label: `Stage filter change to ${e.target.value}`,
            });
          }}
        >
          <option value="All">All stages</option>
          {uniqueStages.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </FitlerSelect>
        <FitlerSelect
          id="amounts"
          onChange={e => {
            dispatch({ type: 'AMOUNT_FILTER', filter: amountsKey[parseInt(e.target.value)] });
            trackCustomEvent({
              action: 'Click',
              category: `Filter`,
              label: `Amount filter change to ${amountsKey[parseInt(e.target.value)]}`,
            });
          }}
        >
          {uniqueAmounts.map(item => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </FitlerSelect>
        <FitlerSelect
          id="people"
          onChange={e => {
            dispatch({ type: 'PEOPLE_FILTER', filter: peopleKey[parseInt(e.target.value)] });
            trackCustomEvent({
              action: 'Click',
              category: `Filter`,
              label: `People filter change to ${peopleKey[parseInt(e.target.value)]}`,
            });
          }}
        >
          {uniquePeople.map(item => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </FitlerSelect>
        <FitlerSelect
          id="investors"
          onChange={e => {
            dispatch({ type: 'INVESTOR_FILTER', filter: e.target.value });
            trackCustomEvent({
              action: 'Click',
              category: `Filter`,
              label: `Investor filter change to ${e.target.value}`,
            });
          }}
        >
          <option value="All">All investors</option>
          {uniqueInvestors.map(item => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </FitlerSelect>
      </FiltersList>
      <ResearchInvestorsFullDiv>
        <ResearchCompaniesDiv>
          <table>
            <thead>
              <tr>
                <th style={{ position: 'sticky', left: 0, zIndex: 4 }}>Company</th>
                <th id="secondColHead">Segment</th>
                <th>Founders</th>
                <th>Stage</th>
                <th>Founded</th>
                <th>HQ. Location</th>
                <th>Amt. Raised</th>
                <th>Employees</th>
                <th>Key Investors</th>
                <th>Base10 Description</th>
              </tr>
            </thead>
            {filteredCompanies.length > 0 ? (
              section_titles.map(item => (
                <tbody key={item.id}>
                  {filteredCompanies.filter(c => c.section_title === item.section_title).length >
                    0 && (
                    <tr>
                      <td colSpan={3} style={{ position: 'sticky', left: '0' }}>
                        <TableSubTitle>
                          {item.section_title}
                          <SpanContainer data-tip={item.tooltip}>
                            <div
                              style={{
                                position: 'absolute',
                                top: '-3px',
                                marginTop: '0px',
                                paddingTop: '0px',
                              }}
                            >
                              <AiOutlineInfoCircle color="rgba(26, 26, 26, 0.4)" fontSize="16px" />
                            </div>
                          </SpanContainer>
                        </TableSubTitle>
                      </td>
                      <td colSpan={7} />
                    </tr>
                  )}
                  {filteredCompanies
                    .filter(c => c.section_title === item.section_title)
                    .map(row => (
                      <tr key={row.name}>
                        <td style={{ position: 'sticky', left: 0, zIndex: 3 }}>
                          <ImageContainer>
                            <a href={row.website}>
                              <img
                                src={require(`../../../content/img/companies/${row.name.toLowerCase()}.png`)}
                                alt={row.name}
                              />
                            </a>
                          </ImageContainer>
                        </td>
                        <td id="secondColumn">{row.segment}</td>
                        <td>
                          <WrappedText showMore={showMore} id="Companies Founders">
                            {row.founders.map(item => (
                              <FoundersBlock key={item.name}>
                                {item.link ? (
                                  <TdAnchor href={item.link}>{item.name}</TdAnchor>
                                ) : (
                                  <TdText>{item.name}</TdText>
                                )}
                                {item === row.founders[row.founders.length - 1] ? '' : ',  '}
                              </FoundersBlock>
                            ))}
                          </WrappedText>
                          {(row.founders.length > 1 || row.founders[0].name.length > 14) && (
                            <MoreButton showMore={showMore} id="Companies Founders" />
                          )}
                        </td>
                        <td>{row.stage}</td>
                        <td>{row.founded}</td>
                        <td>{row.hq_location}</td>
                        <td>{row.amount_raised}</td>
                        <td>{!!row.people ? row.people : 'N/A'}</td>
                        <td>
                          <WrappedText showMore={showMore} id="Companies Key Investors">
                            {row.key_investors.map(item => (
                              <FoundersBlock key={item}>
                                {item}
                                {item === row.key_investors[row.key_investors.length - 1]
                                  ? ''
                                  : ', '}
                              </FoundersBlock>
                            ))}
                          </WrappedText>
                          {(row.key_investors.length > 1 || row.key_investors[0].length > 15) && (
                            <MoreButton showMore={showMore} id="Companies Key Investors" />
                          )}
                        </td>
                        <td>
                          <WrappedText showMore={showMore} id="Companies Description" maxW="250px">
                            {row.description}
                          </WrappedText>{' '}
                          {row.description.length > 35 && (
                            <MoreButton showMore={showMore} id="Companies Description" />
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              ))
            ) : (
              <tr>
                <td colSpan={3} style={{ position: 'sticky', left: '0' }}>
                  <TableSubTitle>No result</TableSubTitle>
                </td>
                <td colSpan={7} />
              </tr>
            )}
          </table>
        </ResearchCompaniesDiv>
      </ResearchInvestorsFullDiv>
      <CompaniesTableFootnote>
        * &nbsp;
        <AnimatedSpan fontSize="12px" paddingBottom="2px" underlineHeight="1px">
          <span onClick={toggleModal}>Click here</span>
        </AnimatedSpan>{' '}
        to view a selection of these companies in market map format.
      </CompaniesTableFootnote>
      <FlexTablesContainer>
        <FlexTableDiv>
          <ResearchInvestorsHalfDiv>
            <ResearchInvestorsTitle>
              Most Active Investors{' '}
              <SpanContainer
                style={{ marginTop: '4px' }}
                data-tip="Represents investors identified by Base10 who have made numerous and/or notable investments in the trend"
              >
                <AiOutlineInfoCircle color="rgba(26, 26, 26, 0.4)" fontSize="16px" />
              </SpanContainer>
            </ResearchInvestorsTitle>
            <ResearchInvestorsDiv>
              <table>
                <thead>
                  <tr>
                    <th>Firm or Investor</th>
                    <th>Investments</th>
                    <th>Check Range ($M)</th>
                    <th>Key People</th>
                  </tr>
                </thead>
                <tbody>
                  {data.allMostActiveInvestorsJson.nodes.map(item => (
                    <tr key={item.id}>
                      <td style={{ paddingLeft: '0px' }}>{item.investor_name}</td>
                      <td>
                        <FullWidthItem>
                          <WrappedText showMore={showMore} id="Most Active Investors Investments">
                            {item.investments.map(row => (
                              <FoundersBlock key={row}>
                                {row}
                                {row === item.investments[item.investments.length - 1] ? '' : ', '}
                              </FoundersBlock>
                            ))}
                          </WrappedText>
                          {item.investments.length > 1 && (
                            <MoreButton
                              showMore={showMore}
                              id="Most Active Investors Investments"
                              column="investments"
                              table="Most-Active-Investors"
                            />
                          )}
                        </FullWidthItem>
                      </td>
                      <td>{item.avg_check}</td>
                      <td>
                        <FullWidthItem>
                          <WrappedText showMore={showMore} id="Most Active Investors Key People">
                            {item.key_investors.map(row => (
                              <FoundersBlock key={row}>
                                {row}
                                {row === item.key_investors[item.key_investors.length - 1]
                                  ? ''
                                  : ', '}
                              </FoundersBlock>
                            ))}
                          </WrappedText>
                          {(item.key_investors.length > 1 || item.key_investors[0].length > 15) && (
                            <MoreButton
                              showMore={showMore}
                              id="Most Active Investors Key People"
                              column="key_investors"
                              table="Most-Active-Investors"
                            />
                          )}
                        </FullWidthItem>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ResearchInvestorsDiv>
          </ResearchInvestorsHalfDiv>
          <ResearchInvestorsHalfDiv>
            <ResearchInvestorsTitle>
              Investors in Top Companies{' '}
              <SpanContainer
                data-tip="Represents investors identified by Base10 who have made large investments into the top companies (by growth and/or size) in the trend"
                style={{ marginTop: '4px' }}
              >
                <AiOutlineInfoCircle color="rgba(26, 26, 26, 0.4)" fontSize="16px" />
              </SpanContainer>
            </ResearchInvestorsTitle>
            <ResearchInvestorsDiv>
              <table>
                <thead>
                  <tr>
                    <th>Firm or Investor</th>
                    <th>Investments</th>
                    <th>Check Range ($M)</th>
                    <th>Key People</th>
                  </tr>
                </thead>
                <tbody>
                  {data.allTopCompaniesInvestorsJson.nodes.map(item => (
                    <tr key={item.id}>
                      <td style={{ paddingLeft: '0px' }}>{item.investor_name}</td>
                      <td>
                        <FullWidthItem>
                          {item.investments.map(row => (
                            <FoundersBlock key={row}>
                              {row}
                              {row === item.investments[item.investments.length - 1] ? '' : ', '}
                            </FoundersBlock>
                          ))}
                        </FullWidthItem>
                      </td>
                      <td>{item.avg_check}</td>
                      <td>
                        <FullWidthItem>
                          {item.key_investors.map(row => (
                            <FoundersBlock key={row}>
                              {row}
                              {row === item.key_investors[item.key_investors.length - 1]
                                ? ''
                                : ', '}
                            </FoundersBlock>
                          ))}
                        </FullWidthItem>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ResearchInvestorsDiv>
          </ResearchInvestorsHalfDiv>
        </FlexTableDiv>
      </FlexTablesContainer>
      <DisclaimerDiv>
        <DisclaimerText>
          Disclaimers: This is not meant to be comprehensive, just our view of how this trend is
          evolving and the interesting approaches to solving these problems in the trend. If you
          would like to be included and we missed you, please shoot us a note at{' '}
          <AnimatedLinkAnchor
            to="mailto:companies@base10.vc"
            color={theme.PRIMARY_COLOR}
            fontSize="14px"
            paddingBottom="1px"
          >
            companies@base10.vc
          </AnimatedLinkAnchor>
          .
        </DisclaimerText>
      </DisclaimerDiv>
    </ShopifyInsightsContainer>
  );
};

const EmailSubmitButton = styled.button`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.WHITE_COLOR};
  background-color: ${theme.BLACK_COLOR};
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  border-radius: 4px;
  min-width: 80px;
  height: 26px;
`;

const EmailTextInput = styled.input`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  line-height: 14px;
  color: rgba(26, 26, 26, 0.8);
  border: none;
  background-color: transparent;
  border-radius: 4px;
  padding: 5px 15px 5px 20px;
  height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const EmailForm = styled.form`
  display: grid;
  grid-template-columns: 150px 200px;
  max-width: fit-content;
  margin-bottom: 28px;
  background-color: rgba(26, 26, 26, 0.2);
  padding: 5px;
  border-radius: 4px;
  @media ${mobileSize} {
    max-width: 100%;
    margin-top: 10px;
    grid-template-columns: 100px 1fr;
  }
`;

const CompaniesTableFootnote = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-size: 12px;
  font-style: italic;
  margin-bottom: 21px;
  line-height: 1.57;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin-top: 10px;
  margin-bottom: 0;
`;

const KeyInsightsFootnote = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-size: 12px;
  font-style: italic;
  margin-bottom: 21px;
  line-height: 1.57;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
`;

const TdAnchor = styled.a`
  display: inline;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  border-bottom: 1px solid ${theme.PRIMARY_COLOR};
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const TdText = styled(AppText)`
  display: inline;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FlexTablesContainer = styled.div`
  max-width: 1400px;
`;

interface WrappedTextProps {
  showMore: string[];
  maxW?: string;
  id: string;
}

const WrappedText = styled.div<WrappedTextProps>`
  border: 0;
  font-family: ${theme.PRIMARY_FONT};
  display: inline-block;
  font-size: 14px;
  line-height: 1.57;
  height: 100%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  min-width: ${props => props.maxW || '50px'};
  max-width: ${props => (props.showMore.includes(props.id) ? '1000px' : '100px')};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0;
  margin: 0;
  vertical-align: bottom;
`;

const ResearchInvestorsFullDiv = styled.div`
  max-width: 1400px;
`;

const FlexTableDiv = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
  }
`;

const ResearchInvestorsHalfDiv = styled.div`
  max-width: 650px;
  width: 45vw;
  justify-self: center;
  @media ${mobileSize} {
    width: 90vw;
  }
`;

const SpanContainer = styled.span`
  margin: 0px;
  padding: 0px;
  line-height: 1;
  position: relative;
  top: 0;
  right: 0;
  vertical-align: top;
  display: inline-block;
  :hover {
    cursor: pointer;
  }
`;

const MoreButtonStyle = styled.button`
  padding: 0;
  margin: 0;
  padding-left: 5px;
  border: none;
  background: transparent;
  color: ${theme.PRIMARY_COLOR};
`;

const FullWidthItem = styled.div`
  width: max-content;
  display: block;
  white-space: nowrap;
`;

const FoundersBlock = styled.div`
  display: inline;
  white-space: nowrap;
`;

const FilterHeadingLabel = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  margin-right: 14px;
  margin: 0;
  padding: 0;
`;

const FilterByLabel = styled.div`
  opacity: 0.6;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  margin-right: 8px;
  margin-left: 14px;
`;

const FitlerSelect = styled.select`
  background: transparent;
  border: none;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  border-bottom: 1px solid ${theme.PRIMARY_COLOR};
  margin: 0px;
  margin-right: 8px;
  max-width: 100px;
  text-overflow: ellipsis;
  option {
    font-family: ${theme.PRIMARY_FONT};
  }
  @media ${mobileSize} {
    margin: 5px;
  }
`;

const FiltersList = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 8px;
  flex-wrap: wrap;
`;

const DisclaimerDiv = styled.div`
  margin-top: 23px;
  background-color: ${theme.WHITE_COLOR};
  padding: 13px;
`;

const DisclaimerText = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: rgba(26, 26, 26, 0.4);
  max-width: 1000px;
  margin: auto auto;
`;

const TableSubTitle = styled.div`
  font-family: ${theme.SECONDARY_FONT};
  margin: 27px 0px 8px 0px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.33px;
  color: ${theme.BLACK_COLOR};
  text-transform: uppercase;
  acronym {
    background: black;
    color: white;
  }
`;

const ResearchCompaniesDiv = styled.div`
  max-width: 95vw;
  overflow: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  height: 500px;
  th {
    font-family: ${theme.SECONDARY_FONT};
    text-transform: uppercase;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.67px;
    color: rgba(26, 26, 26, 0.6);
    text-align: left;
    /* border-bottom: 1px solid ${theme.BLACK_COLOR}; */
    /* border-right: 8px solid ${theme.OFF_WHITE_BACKGROUND}; */
    padding: 8px 12px 8px 0px;
    vertical-align: bottom;
    background-clip: padding-box;
    border-collapse: collapse;
    position: sticky;
    top: 0;
    background-color: ${theme.OFF_WHITE_BACKGROUND};
    z-index: 3;
  }
  th:after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    height: 1px;
    width: calc(100% - 8px);
    margin-top: 0px;
    background-color: ${theme.BLACK_COLOR};
  }
  table {
    width: 100%;
    position: relative;
    border-collapse: separate;
  }
  td {
    font-family: ${theme.PRIMARY_FONT};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${theme.BLACK_COLOR};
    border-bottom: 1px solid rgb(203, 204, 205);
    padding-right: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-clip: padding-box;
    background-color: ${theme.OFF_WHITE_BACKGROUND};
    @media ${mobileSize} {
      line-height: 1.1;
    }
  }
  img {
    max-height: 18px;
  }
  #secondColumn {
    position: sticky;
    left: 100px;
    z-index: 3; 
    padding-right: 12px;
  }
  #secondColHead {
    position: sticky; 
    left: 100px; 
    z-index: 4;
  }
  @media ${laptopSize} {
    max-width: 90vw;
    overflow: auto;
    #secondColumn {
      position: relative;
      left: auto;
      z-index: 1; 
      padding-right: 12px;
    }
    #secondColHead {
      position: sticky;
      top: 0;
      left: auto; 
      z-index: 3;
    }
  }
  @media ${mobileSize} {
    max-width: 90vw;
    overflow: auto;
  }
`;

const ResearchInvestorsDiv = styled.div`
  overflow: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  th {
    opacity: 0.6;
    font-family: ${theme.SECONDARY_FONT};
    text-transform: uppercase;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.67px;
    color: ${theme.BLACK_COLOR};
    text-align: left;
    border-bottom: 1px solid ${theme.BLACK_COLOR};
    border-right: 8px solid ${theme.OFF_WHITE_BACKGROUND};
    border-left: 0px;
    border-collapse: collapse;
    padding: 8px 4px 8px 0px;
    vertical-align: bottom;
  }
  table {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
  }
  td {
    font-family: ${theme.PRIMARY_FONT};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${theme.BLACK_COLOR};
    border-bottom: 1px solid rgba(26, 26, 26, 0.2);
    border-collapse: collapse;
    padding-left: 4px;
    padding-right: 12px;
    @media ${mobileSize} {
      line-height: 1.1;
    }
  }
  img {
    max-width: 50px;
    max-height: 24px;
    margin: 5px;
  }
  @media ${laptopSize} {
    max-width: 90vw;
    overflow: auto;
  }
  @media ${mobileSize} {
    max-width: 90vw;
    overflow: auto;
  }
`;

const ResearchInvestorsTitle = styled.div`
  margin-top: 31px;
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    margin-top: 10px;
  }
`;

const KeyInsightsListItems = styled.div`
  ol {
    font-family: ${theme.PRIMARY_FONT};
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    color: ${theme.BLACK_COLOR};
    list-style: none;
    counter-reset: li;
    margin: 0;
  }
  ol li::before {
    content: counter(li);
    color: ${theme.WHITE_COLOR};
    font-family: ${theme.PRIMARY_FONT};
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    text-align: left;
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: text-top;
    border-radius: 50%;
    text-align: center;
    margin-right: 15px;
    margin-top: 2px;
    margin-left: -31px;
  }
  ol li:nth-child(3n + 1):before {
    background-color: ${listColors[0]};
  }
  ol li:nth-child(3n + 2):before {
    background-color: ${listColors[1]};
  }
  ol li:nth-child(3n + 3):before {
    background-color: ${listColors[2]};
  }
  ol li {
    counter-increment: li;
    padding: 0;
    margin: 0;
    margin-left: 15px;
    line-height: 20px;
    margin-bottom: 8px;
    /* padding-left: 5px; */
    list-style-position: outside;
  }
`;

const KeyInsightsListTitle = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  margin-bottom: 8px;
`;

interface SummaryTitleProps {
  bold?: boolean;
}

const SummaryTitleText = styled(AppText)<SummaryTitleProps>`
  font-family: ${theme.PRIMARY_FONT};
  font-size: ${props => (props.bold ? '16px' : '13px')};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-transform: uppercase;
  color: ${theme.BLACK_COLOR};
  display: inline;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  opacity: ${props => (props.bold ? '1' : '0.6')};
`;

const SummaryTitle = styled.div`
  padding-bottom: 10px;
`;

const ShopifyInsightsContainer = styled.div`
  padding: 130px 25px 100px 25px;
  max-width: 1400px;
  margin: auto auto;
  background-color: ${theme.OFF_WHITE_BACKGROUND};
  font-family: ${theme.PRIMARY_FONT};
  @media ${mobileSize} {
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const ImageContainer = styled.div`
  height: 24px;
  width: 90px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
`;

export { OurShopifyInsightsSegment };
