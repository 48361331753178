import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { laptopSize, desktopSize, mobileSize } from '../../../util/variables';
import { AnimatedSpan, AppText, AnimatedDiv } from '../../ui';
import { TeamPortfolioModal } from '../../Modal';
import teamData from './teamData';
import theme from '../../../util/theme';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';

// stubbed data, will need queries

interface TeamPortfolioProps {}

interface TeamPortfolioState {
  showModal: boolean;
  data: ITeamData;
}

interface ITeamData {
  name: string;
  role: string;
  image: string;
  excerpt: string;
  about: string[];
  email: string;
  linkedin: string;
}

const OurTeamPortfolioSegment: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentData, setCurrentData] = useState<ITeamData>({});
  const [member, _] = useQueryParam('member', StringParam);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleClick = (teamData: ITeamData) => {
    setCurrentData(teamData);
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (!!member) {
      const focusMemberData = teamData.filter(
        item => item.name.toLowerCase().replace(/ /g, '_') === member,
      );
      if (!!focusMemberData.length && !!focusMemberData[0]) {
        setCurrentData(focusMemberData[0]);
        setShowModal(!showModal);
      }
    }
  }, []);

  return (
    <OurTeamsContainer>
      {teamData.map(teamMember => (
        <PortfolioDiv key={teamMember.name}>
          <TeamPortfolioModal showModal={showModal} toggleModal={toggleModal} data={currentData} />
          <PortfolioCollapseWrapper>
            <AnimatedDiv>
              <PortfolioImageDiv>
                <PortfolioImage src={teamMember.image} />
              </PortfolioImageDiv>
            </AnimatedDiv>
            <PortfolioContentDiv>
              <AnimatedDiv delay="100">
                <PortfolioNameTitle>{teamMember.name}</PortfolioNameTitle>
              </AnimatedDiv>
              <AnimatedDiv delay="300">
                <PortfolioRoleTitle>{teamMember.role}</PortfolioRoleTitle>
              </AnimatedDiv>
              <AnimatedDiv delay="500">
                <PortfolioExcerpt>
                  {teamMember.excerpt}
                  <PortfolioSpanContainer onClick={() => handleClick(teamMember)}>
                    {'   '}
                    <AnimatedSpan>Read More</AnimatedSpan>
                  </PortfolioSpanContainer>
                </PortfolioExcerpt>
              </AnimatedDiv>
            </PortfolioContentDiv>
          </PortfolioCollapseWrapper>
        </PortfolioDiv>
      ))}
    </OurTeamsContainer>
  );
};

const OurTeamsContainer = styled.div`
  padding-bottom: 180px;
  display: block;
  width: 100%;
  background: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    padding-bottom: 57px;
  }
`;

const PortfolioDiv = styled.div`
  margin-bottom: 50px;
  width: 100%;
  float: left;
  display: block;
  margin-left: calc((100vw - 900px) / 2);
  @media ${laptopSize} {
    width: calc(100% - 60px);
    float: left;
    margin-left: 30px;
  }
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
`;

const PortfolioCollapseWrapper = styled.div`
  margin-left: -30px;
  margin-right: -30px;
  width: calc(100% + 60px);
  @media ${mobileSize} {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  }
`;

const PortfolioImageDiv = styled.div`
  position: relative;
  z-index: 1;
  width: calc(30% - 39px);
  max-width: 254px;
  float: left;
  margin-left: 30px;
  @media ${laptopSize} {
    width: calc(33.33333% - 40px);
    float: left;
    margin-left: 30px;
  }
  @media ${mobileSize} {
    max-width: 476px;
    width: calc(66.66667% - 33px);
    float: left;
    margin-left: 20px;
  }
`;

const PortfolioImage = styled.img`
  display: block;
  width: 100%;
  z-index: 1;
`;

const PortfolioContentDiv = styled.div`
  width: calc(60% - 36px);
  float: left;
  margin-left: 100px;
  @media ${laptopSize} {
    width: calc(66.66667% - 50px);
    float: left;
    margin-left: 30px;
  }
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    margin-left: 20px;
  }
`;

const PortfolioNameTitle = styled.h3`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-weight: 300;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0;
  margin: 0;
  @media ${mobileSize} {
    font-size: 24px;
    line-height: 34px;
    letter-spacing: 0;
    margin-top: 31px;
    margin-bottom: 0;
  }
`;

const PortfolioRoleTitle = styled.h4`
  font-family: ${theme.PRIMARY_FONT};
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  font-weight: 300;
  color: rgba(26, 26, 26, 0.4);
  @media ${mobileSize} {
    font-size: 14px;
    margin-top: 7px;
    line-height: 21px;
    letter-spacing: 0;
  }
`;

const PortfolioExcerpt = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  color: ${theme.BLACK_COLOR};
  font-size: 20px;
  max-width: 537px;
  font-weight: 300;
  line-height: 32px;
  margin-top: 16px;
  letter-spacing: 0;
  @media ${mobileSize} {
    max-width: 100%;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0;
    margin-top: 29px;
  }
`;

const PortfolioSpanContainer = styled(AppText)`
  display: inline;
  letter-spacing: 0;
  span {
    font-size: 20px;
  }
  @media ${mobileSize} {
    display: block;
    margin-top: 30px;
    line-height: 1.33333;
    letter-spacing: 0;
    span {
      font-size: 18px;
    }
  }
`;

export default OurTeamPortfolioSegment;
