import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { laptopSize, desktopSize, mobileSize } from '../../../util/variables';
import theme from '../../../util/theme';
import { AnimatedDiv } from '../../ui';

const OurValuesSegment: React.FC = () => {
  return (
    <OurValuesContainer>
      <OurValuesWrapper>
        <OurValuesContent>
          <AnimatedDiv delay="100">
            <OurValuesh2>Our Values</OurValuesh2>
          </AnimatedDiv>
          <AnimatedDiv delay="300">
            <OurValuesh3>
              <OurValuesList>
                <OurValuesListItem>Be humble.</OurValuesListItem>
                <OurValuesListItem>Work hard.</OurValuesListItem>
                <OurValuesListItem>Serve the entrepreneur.</OurValuesListItem>
                <OurValuesListItem>Serve our investors.</OurValuesListItem>
              </OurValuesList>
            </OurValuesh3>
          </AnimatedDiv>
        </OurValuesContent>
      </OurValuesWrapper>
    </OurValuesContainer>
  );
};

const OurValuesContainer = styled.div`
  padding: 0px;
  display: block;
  width: 100%;
  background-color: ${theme.WHITE_COLOR};
`;

const OurValuesWrapper = styled.div`
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

const OurValuesContent = styled.div`
  margin: 0;
  width: 100%;
  float: left;
  margin-left: calc((100vw - 900px) / 2);
  padding-top: 100px;
  padding-bottom: 150px;
  @media ${laptopSize} {
    margin-left: 100px;
  }
  @media ${mobileSize} {
    width: calc(100% - 40px);
    float: left;
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 75px;
    padding-bottom: 60px;
  }
`;

const OurValuesh2 = styled.h2`
  padding: 0;
  margin: 0;
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 400;
  color: rgba(26, 26, 26, 0.6);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  margin-bottom: 15px;
  @media ${mobileSize} {
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
`;

const OurValuesh3 = styled.h3`
  margin: 0;
  padding: 0;
`;

const OurValuesListContainer = styled.div`
  margin-top: 2.5625rem;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0;
  color: #fff;
  @media ${mobileSize} {
    margin-top: 1.8125rem;
    font-size: 1.125rem;
    line-height: 1.33333;
    letter-spacing: 0;
  }
`;

const OurValuesList = styled.ul`
  list-style: none;
  padding: 0px;
  @media ${mobileSize} {
    padding: 0px;
  }
`;

const OurValuesListItem = styled.li`
  font-size: 36px;
  font-weight: 300;
  letter-spacing: -0.8px;
  line-height: 50px;
  color: ${theme.BLACK_COLOR};
  position: relative;
  display: list-item;
  text-align: -webkit-match-parent;
  font-family: ${theme.PRIMARY_FONT};
  margin: 0;
  padding-left: 0px;
  ::before {
    content: '·';
    right: auto;
    left: 0;
    -webkit-transform: translateX(-25px);
    transform: translateX(-25px);
    font-size: 36px;
    line-height: 50px;
    color: ${theme.PRIMARY_COLOR};
    position: absolute;
    width: 40px;
    margin-left: -15px;
  }
  @media ${mobileSize} {
    font-size: 30px;
    letter-spacing: -0.75px;
    line-height: 33.75px;
    margin: 0;
    padding: 0;
    ::before {
      content: none;
    }
  }
`;

export { OurValuesSegment };
