import * as React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import { desktopSize, laptopSize, mobileSize } from '../../../util/variables';
import theme from '../../../util/theme';
import { AppText, AppTitle } from '../../ui/';
import { prismicClient, Prismic, RichText } from '../../../services';
import moment from 'moment';
import { OurTeamSegment } from './';
// import { NewTeamPortfolioModal } from '../../Modal/NewTeamPortfolioModal';
import { FaTwitter, FaLinkedinIn } from 'react-icons/fa';
import { GoMail } from 'react-icons/go';

const NewTeamMembersSegment: React.FC<{ location: any }> = ({ location }) => {
  const [teamData, setTeamData] = useState<any>(null);
  const [showOneMember, setShowOneMember] = useState(false);
  const [currentData, setCurrentData] = useState<ITeamData>({});
  // const [member, _] = useQueryParam('member', StringParam);
  const [articleIDs, setArticleIDs] = useState([] as any);

  const [articles, setArticles] = useState({} as any);
  const [loadingData, setLoadingData] = useState(true);

  // Takes in member in the format '/team/member/whatever..' and returns member
  const urlMember = location.pathname.split('/')[2];

  const isBrowser = typeof window !== 'undefined';

  const html = isBrowser ? document?.querySelector('html') : '';

  useEffect(() => {
    if (isBrowser) {
      showOneMember ? (html.style.overflow = 'hidden') : (html.style.overflow = 'visible');
    }
  }, [showOneMember]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await prismicClient.query(
        Prismic.Predicates.at('document.type', 'teammembers'),
        { pageSize: 9999 },
      );
      console.log('prismic team memebers response: ', response);
      console.log('team memebers: ', response.results);
      if (response?.results.length) {
        setTeamData(
          response.results.sort((a: any, b: any) => a.data.member_number - b.data.member_number),
        );
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (Boolean(urlMember) && Boolean(teamData)) {
      const focusMemberData = teamData.filter(item => item.uid === urlMember);
      if (Boolean(focusMemberData.length) && Boolean(focusMemberData[0])) {
        setCurrentData(focusMemberData[0]);
        setShowOneMember(true);
      }
    } else {
      setCurrentData({});
      setShowOneMember(false);
    }
  }, [Boolean(teamData), urlMember]);

  useEffect(() => {
    if (currentData) {
      const ids = currentData.data?.articles?.map((item: any) => item.article.id);

      if (ids?.length) {
        setArticleIDs(ids);
      } else {
        setArticleIDs([]);
      }
    }
  }, [urlMember, currentData, teamData]);

  useEffect(() => {
    const fetchData = async () => {
      if (articleIDs[0]) {
        const response = await prismicClient.getByIDs(articleIDs, {});
        console.log('ARTICLE res: ', response);
        if (response?.results.length) {
          setArticles(
            response.results
              .map((item: any) => item)
              .sort((a, b) => {
                const aDate = new Date(a.data.date);
                const bDate = new Date(b.data.date);
                return bDate.getTime() - aDate.getTime();
              }),
            // .filter((_, i: number) => i < 3),
          );
          console.log('ARTICLE: ', articles);
          setLoadingData(false);
        }
      }
    };

    fetchData();
  }, [articleIDs]);

  const teamDataLength = Boolean(teamData?.length) ? teamData.length : 10;

  return (
    <div style={{ background: 'blue', width: '100vw' }}>
      {/* <TeamPortfolioModal showOneMember={showOneMember} toggleModal={toggleModal} data={currentData} /> */}
      <OurPressContainer>
        <OurPressWrapper>
          <MainTextContainer style={{ minHeight: '100vh' }}>
            <FlexDiv>
              {showOneMember && Boolean(teamData) && (
                <ModalDiv onClick={() => navigate('/team/')}>
                  <Link to="/team/">
                    <CloseModal>
                      <Cross id="cross" />
                    </CloseModal>
                  </Link>
                  <ModalContentWrapper onClick={(e: any) => e.stopPropagation()}>
                    <PersonDetailsDiv>
                      <OurPressh2 style={{ color: theme.BLACK_COLOR, opacity: 0.6 }}>
                        OUR TEAM
                      </OurPressh2>
                      <MemberName>{RichText.asText(currentData.data.name)}</MemberName>
                      <MemberName style={{ color: theme.PRIMARY_COLOR }}>
                        {RichText.asText(currentData.data.title)}
                      </MemberName>
                      <MemberInfoGrid>
                        <div>
                          <MemberImg src={currentData.data.image.url} alt={currentData.data.name} />
                        </div>
                        <div>
                          <SummaryText>{RichText.asText(currentData.data.summary)}</SummaryText>
                          <DescriptionText>
                            {RichText.render(currentData.data.description)}
                          </DescriptionText>
                          <AppText>{currentData.data.title.text}</AppText>
                          <IconsGrid>
                            {Boolean(currentData.data.email) && (
                              <a href={`mailto:${currentData.data.email}`}>
                                <IconContainer>
                                  <GoMail size={30} color={theme.BLACK_COLOR} />
                                </IconContainer>
                              </a>
                            )}
                            {Boolean(currentData.data.linkedin.url) && (
                              <a href={currentData.data.linkedin.url}>
                                <IconContainer>
                                  <FaLinkedinIn size={25} color={theme.BLACK_COLOR} />
                                </IconContainer>
                              </a>
                            )}

                            {Boolean(currentData.data.twitter.url) && (
                              <a href={currentData.data.twitter.url}>
                                <IconContainer>
                                  <FaTwitter size={25} color={theme.BLACK_COLOR} />
                                </IconContainer>
                              </a>
                            )}
                          </IconsGrid>
                        </div>
                      </MemberInfoGrid>
                      {Boolean(articleIDs[0]) && (
                        <>
                          <CenterDiv>
                            <ColorRemover>
                              <SecondaryText>
                                Read More From{' '}
                                {RichText.asText(currentData.data.name).split(' ')[0]}
                              </SecondaryText>
                            </ColorRemover>
                            <HorizontalLine />
                          </CenterDiv>
                          {/* <FindArticles ids={articleIDs} /> */}
                          <ArticlesDiv>
                            {!loadingData &&
                              articles.map((article: any) => (
                                <OneArticleDiv key={article.data.id}>
                                  <a href={article.data.url_link.url}>
                                    <img
                                      src={article.data.article_thumbnail.url}
                                      alt={article.data.article_thumbnail.alt}
                                    />
                                    <ArticleTitleText>
                                      {RichText.asText(article.data.title)}
                                    </ArticleTitleText>
                                    <SecondaryText>
                                      {moment(article.data.date).format('MMMM YYYY')}
                                    </SecondaryText>
                                    <SecondaryText>{article.data.source}</SecondaryText>
                                  </a>
                                </OneArticleDiv>
                              ))}
                          </ArticlesDiv>
                        </>
                      )}
                    </PersonDetailsDiv>
                  </ModalContentWrapper>
                </ModalDiv>
              )}
              <>
                <OurTeamSegment />
                <MembersDiv teamDataLength={teamDataLength}>
                  {Boolean(teamData) &&
                    teamData.map((member: any, index: number) => (
                      <TeamMemberContainer
                        key={member.id}
                        approachingEndLeft={teamDataLength === 13 && index === 8}
                        approachingEndRight={teamDataLength === 13 && index === 10}
                      >
                        <TeamMember key={member.id} img={member.data.image.url}>
                          <Link to={`/team/${member.uid}/`}>
                            {/* <TeamImg src={member.data.image.url} alt={member.data.name} /> */}
                            <MemberInfo>
                              <SummaryName hideOnMobile>
                                {RichText.asText(member.data.name)}
                              </SummaryName>
                              <SummaryPosition hideOnMobile>
                                {RichText.asText(member.data.title)}
                              </SummaryPosition>
                            </MemberInfo>
                          </Link>
                        </TeamMember>
                        <PersonDetailsMobile>
                          <SummaryName>{RichText.asText(member.data.name)}</SummaryName>
                          <SummaryPosition>{RichText.asText(member.data.title)}</SummaryPosition>
                        </PersonDetailsMobile>
                      </TeamMemberContainer>
                    ))}
                </MembersDiv>
                {/* <NewValuesSegment /> */}
              </>
            </FlexDiv>
          </MainTextContainer>
        </OurPressWrapper>
      </OurPressContainer>
    </div>
  );
};

const ModalContentWrapper = styled.div`
  background: ${theme.WHITE_COLOR};
  margin: 184px auto;
  margin-bottom: 0px;
  max-width: 95vw;
  width: 800px;
  max-height: calc(100% - 184px);
  height: auto;
  overflow: auto;
  padding: 57px 79px;
  overscroll-behavior: contain;
  @media ${desktopSize} {
    max-width: 800px;
  }
  @media ${mobileSize} {
    margin: 0px;
    max-height: calc(100%);
    padding: 150px 40px;
    /* width: 100vw; */
  }
`;

const ModalDiv = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.7);
  margin: auto;
  z-index: 9999;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /* @media ${mobileSize} {
    left: -50px;
    right: -50px;
    width: calc(100vw + 100px);
  } */
`;

interface SummaryMemberProps {
  hideOnMobile?: boolean;
}

const SummaryName = styled(AppText)<SummaryMemberProps>`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  @media ${mobileSize} {
    color: ${theme.NEW_BLACK_COLOR};
    display: ${props => (props.hideOnMobile ? 'none' : 'auto')};
    max-width: 35vw;
    margin-bottom: 5px;
  }
`;
const SummaryPosition = styled(AppText)<SummaryMemberProps>`
  font-size: 15px;
  line-height: 1.3;
  margin-top: 5px;
  font-weight: 500;
  color: ${theme.PRIMARY_COLOR};
  text-align: center;
  @media ${mobileSize} {
    display: ${props => (props.hideOnMobile ? 'none' : 'auto')};
    color: ${theme.NEW_BLACK_COLOR};
    text-align: left;
    min-height: 100%;
    max-width: 35vw;
    line-height: 1.5;
    opacity: 0.4;
  }
`;

interface EndOfGridProps {
  approachingEndLeft?: boolean;
  approachingEndRight?: boolean;
}

const TeamMemberContainer = styled.div<EndOfGridProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  margin-left: ${props => (props.approachingEndLeft ? '100px' : '0px')};
  margin-right: ${props => (props.approachingEndRight ? '100px' : '0px')};
  @media ${desktopSize} {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const PersonDetailsMobile = styled.div`
  display: none;
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    margin-bottom: 29px;
  }
`;

const ColorRemover = styled.div`
  padding: 0px 30px;
  background: ${theme.WHITE_COLOR};
  z-index: 2;
`;

const HorizontalLine = styled.div`
  display: block;
  position: absolute;
  left: 0px;
  right: 0px;
  background: ${theme.BLACK_COLOR};
  opacity: 0.4;
  height: 2px;
  width: 100%;
  z-index: 1;
`;

const CenterDiv = styled.div`
  position: relative;
  margin: 70px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  float: center;
`;

const ArticleTitleText = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  font-size: 17px;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const SecondaryText = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  font-family: ${theme.SECONDARY_FONT};
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.2;
  margin: 0;
  opacity: 0.6;
`;

const OneArticleDiv = styled.div`
  max-width: 200px;
  padding: 10px;
  :nth-child(4) {
    display: none;
  }
  @media ${desktopSize} {
  }
  @media ${mobileSize} {
    :nth-child(4) {
      display: block;
    }
    max-width: 38vw;
  }
`;

const ArticlesDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
  justify-content: space-around;
`;

const IconsGrid = styled.div`
  margin-top: 50px;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 50px 50px 50px;
`;

const IconContainer = styled.div`
  /* margin-right: 20px; */
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid ${theme.PRIMARY_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.9;
`;

const MemberImg = styled.img`
  width: 100%;
`;

const SummaryText = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  margin-bottom: 50px;
`;

const DescriptionText = styled(AppText)`
  color: ${theme.BLACK_COLOR};
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  /* opacity: 0.9; */
`;

const MemberInfoGrid = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 261fr 455fr;
  grid-gap: 60px;
  @media ${mobileSize} {
    grid-gap: 68px;
    display: flex;
    flex-direction: column;
  }
`;

const MemberName = styled(AppTitle)`
  color: ${theme.BLACK_COLOR};
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: -0.8px;
  margin: 0px;
  @media ${mobileSize} {
    font-size: 40px;
    line-height: 47px;
  }
`;

const PersonDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
`;

interface LengthProp {
  teamDataLength: number;
}

const MembersDiv = styled.div<LengthProp>`
  /* display: grid;
  max-width: 100vw;
  grid-template-columns: 282px 282px 282px 282px;
  grid-template-rows: repeat(${props => Math.ceil(props.teamDataLength / 4)}, 282px);
  grid-gap: 5px;
  @media ${desktopSize} {
    grid-template-columns: 235px 235px 235px 235px;
    grid-template-rows: repeat(${props => Math.ceil(props.teamDataLength / 4)}, 235px);
  }
  @media ${laptopSize} {
    grid-template-columns: 250px 250px 250px;
    grid-template-rows: repeat(${props => Math.ceil(props.teamDataLength / 3)}, 250px);
  } */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 100vw;
  /* grid-template-columns: 282px 282px 282px 282px;
  grid-template-rows: repeat(${props => Math.ceil(props.teamDataLength / 4)}, 282px);
  grid-gap: 5px; */
  /* @media ${desktopSize} {
    grid-template-columns: 235px 235px 235px 235px;
    grid-template-rows: repeat(${props => Math.ceil(props.teamDataLength / 4)}, 235px);
  }
  @media ${laptopSize} {
    grid-template-columns: 250px 250px 250px;
    grid-template-rows: repeat(${props => Math.ceil(props.teamDataLength / 3)}, 250px);
  } */
  @media ${mobileSize} {
    /* grid-template-columns: 1fr;
    grid-template-rows: repeat(9, 320px); */
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-wrap: wrap;
  }
`;

interface ImgProp {
  img: string;
}

const TeamMember = styled.div<ImgProp>`
  background-image: ${props => `url(${props.img})`};
  background-size: 100% 100%;
  display: flex;
  position: relative;
  /* z-index: 20; */
  width: 258px;
  height: 258px;
  margin: 14px;
  /* z-index: 2; */
  filter: grayscale(100%);
  transition: 200ms linear;
  cursor: pointer;
  :hover {
    width: 278px;
    margin: 5px;
    /* margin: 0px; */
    height: 278px;
    filter: none;
    /* scale: 1.; */
  }
  @media ${desktopSize} {
    width: 270px;
    height: 270px;
    :hover {
      width: 290px;
      height: 290px;
    }
  }
  @media ${mobileSize} {
    width: 40vw;
    height: 40vw;
    margin: 0px;
    :hover {
      width: 40vw;
      height: 40vw;
      margin: auto auto;
      /* margin: 0px; */
      filter: none;
      /* scale: 1.; */
    }
  }
`;

const MemberInfo = styled.div`
  display: flex;
  /* visibility: hidden; */
  flex-direction: column;
  position: absolute;
  min-height: 100%;
  min-width: 100%;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 200ms linear;
  padding: 10px;
  :hover {
    background: ${theme.BLACK_COLOR};
    opacity: 0.8;
  }
  @media ${mobileSize} {
    :hover {
      opacity: 1;
      background: transparent;
      justify-content: start;
      align-items: flex-start;
    }
  }
`;

const TeamImg = styled.img`
  /* width: 150px; */
  /* position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px; */
  width: 200px;
  height: 200px;
  z-index: 2;
  filter: grayscale(100%);
  transition: 200ms linear;
  :hover {
    filter: none;
    scale: 1.05;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 150px;
  @media ${mobileSize} {
    margin-top: 88px;
  }
  /* margin-bottom: 150px; */
`;

const Pressp = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  padding: 0;
  margin: 0;
  font-weight: 300;
  max-width: 358px;
  margin-top: 13px;
  min-width: 200px;
  line-height: 26px;
  font-size: 18px;
  a {
    text-decoration: none;
    color: ${theme.BLACK_COLOR};
    display: inline-block;
    font-weight: 300;
    line-height: 26px;
    font-size: 18px;
  }
  a:hover {
    text-decoration: none;
  }
  @media ${mobileSize} {
    max-width: 100%;
    min-width: 100%;
    font-size: 14px;
    line-height: 21px;
    margin-top: 3px;
    a {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

const Pressh3 = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  padding: 0;
  margin: 0;
  font-weight: 300;
  max-width: 426px;
  min-width: 200px;
  font-size: 28px;
  line-height: 36px;
  margin-top: 17px;
  @media ${mobileSize} {
    max-width: 100%;
    min-width: 100%;
    font-size: 24px;
    line-height: 34px;
    margin-top: 7px;
  }
`;

const Pressh4 = styled(AppText)`
  font-family: ${theme.PRIMARY_FONT};
  letter-spacing: normal;
  color: ${theme.BLACK_COLOR};
  padding: 0;
  margin: 0;
  font-weight: 300;
  min-width: 200px;
  font-size: 18px;
  opacity: 0.4;
  line-height: 26px;
  margin-top: 24px;
  @media ${mobileSize} {
    max-width: 100%;
    min-width: 100%;
    font-size: 14px;
    line-height: 21px;
    margin-top: 14px;
  }
`;

const OurPressContainer = styled.div`
  /* padding-top: 120px; */
  background-color: ${theme.WHITE_COLOR};
  display: block;
  padding-bottom: 50px;
  width: 100%;
  ::after {
    content: '';
    position: absolute;
    top: 550px;
    height: 135px;
    width: 100%;
    background-color: ${theme.BLACK_COLOR};
    z-index: -1;
  }
  @media ${mobileSize} {
    min-height: auto;
    ::after {
      display: none;
    }
  }
`;

const OurPressWrapper = styled.div`
  background-color: ${theme.WHITE_COLOR};
  max-width: 1600px;
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 1200px;
  margin: auto;
`;

const MainTextContainer = styled.div`
  width: 100hw;
  margin: auto;
  padding: 25px;
  /* padding-top: 120px; */
  @media ${mobileSize} {
    padding-top: 20px;
  }
`;

const OurPressh1 = styled.h1`
  color: white;
  display: inline-block;
  font-family: ${theme.PRIMARY_FONT};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 36px;
  line-height: 1.39;
  letter-spacing: -0.8px;
  margin-top: 10px;
  margin-bottom: 10px;
  @media ${mobileSize} {
    font-size: 30px;
    line-height: 1.125;
    letter-spacing: -0.75px;
  }
`;

const OurPressh2 = styled.h2`
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  line-height: 1.16667;
  letter-spacing: 0.125rem;
  margin-top: 0.9375rem;

  width: 114px;
  height: 14px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
`;

const Cross = styled.span`
  opacity: 0.2;
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  :before {
    transform: rotate(45deg) translate(-50%, -50%);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.5px;
    width: 22px;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    background-color: ${theme.BLACK_COLOR};
  }
  :after {
    height: 0.0625rem;
    width: 0.75rem;
    transform: rotate(-45deg) translate(-50%, -50%);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1.5px;
    width: 22px;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    background-color: ${theme.BLACK_COLOR};
  }
  @media ${mobileSize} {
    opacity: 0.6;
    height: 1.25rem;
    width: 1.25rem;
    :before {
      height: 1px;
      width: 16px;
    }
    :after {
      height: 1px;
      width: 16px;
    }
  }
`;

const CloseModal = styled.div`
  cursor: pointer;
  position: absolute;
  top: 1.875rem;
  /* top: 200px; */
  right: 1.875rem;
  z-index: 9999;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-color: ${theme.WHITE_COLOR};
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
  @media ${mobileSize} {
    position: absolute;
    top: 20px;
    right: 40px;
    height: 50px;
    width: 50px;
    border: 1px solid rgba(26, 26, 26, 0.5);
  }
  :hover {
    #cross {
      opacity: 1;
    }
  }
`;

export { NewTeamMembersSegment };
